import axios from "axios";
//axios.defaults.baseURL = "http://127.0.0.1:8000";
// axios.defaults.baseURL = "http://edynamicsadmin.in";
// const StorageObjUserRef = localStorage.user_reference;
// const StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : '';
// const client = StorageObjClient.maa1 ? StorageObjClient.maa1 : '';
// const user = localStorage.user;


export default class ApiService {

    StorageObjUserRef = localStorage.user_reference;
    StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : '';
    client = this.StorageObjClient.maa1 ? this.StorageObjClient.maa1 : '';
    user = localStorage.user;
    isLoginFromAdmin = localStorage.LoginAsClientInClient ? localStorage.LoginAsClientInClient : '';
    loginUserId = localStorage.loginUserId ? localStorage.loginUserId : '';


    HeaderParam = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "content-type": "application/json",
        "Accept": "application/json"
    };

    AuthenticatedUserHeader = {
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'content-type': 'application/json',
        'Accept': 'application/json',
        'Authorization': "Bearer " + this.StorageObjUserRef,
        'client': this.client,
        'user': this.user,
        'isLoginFromAdmin': this.isLoginFromAdmin,
        'loginUSerId': this.loginUserId
    };

    commonapiall = (url, params, method, type, item) => {
        return axios(url, {
            method: method,
            headers: params,
            data: item
        })
            .then(response => {
                if (response.data.status === 403) {

                    // this.logout();
                }
                // else if (response.data.status === 401 ) {
                //  this.logoutonsessiontimeout({ logout_reason: response.data.logout_reason });

                // localStorage['logout_reason'] = response.data.message;
                // localStorage['error_toaster_display'] = 1;
                //location.reload();
                // this.logoutsession();
                // }
                else {
                    return response.data;
                }
            })
            .catch(error => {
                throw error;
            });
    };

    getalltitle(item) {
        let URL = "/api/getalltitle";
        return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
    }
    getCountryCode(item) {
        let URL = "/api/getCountryCode";
        return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
    }

    getuserdesignations(item) {
        let url = '/api/getUserDesignations';
        return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    }
    getcompanytypes(item) {
        let URL = "/api/getcompanytypes";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    }
    getstates(item) {
        let URL = "/api/getstates";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getcities(item) {
        let URL = "/api/getcities";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getareas(item) {
        let URL = "/api/getareas";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    generateHostOtp(item) {
        let URL = "/api/generateHostOtp";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    verifyHostOtp(item) {
        let URL = "/api/verifyHostOtp";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    updateCompanyInfo(item) {
        let URL = "/api/updateCompanyInfo";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    updateBranchInfo(item) {
        let URL = "/api/updateBranchInfo";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    addHostUser(item) {
        let URL = "/api/addHostUser";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getCategories(item) {
        let URL = "/api/getCategories";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getHostBranches(item) {
        let URL = "/api/getHostBranches";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    checkUserExistsUsingMobileNumber(item) {
        let URL = "/api/checkUserExistsUsingMobileNumber";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getRoles(item) {
        let URL = "/api/getRoles";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    resendHostOtp(item) {
        let URL = "/api/resendHostOtp";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }


    // logoutsession(item) {

    //     let URL = "/api/u/logoutsession";
    //     return axios(URL, {
    //         method: 'POST',
    //         headers: this.AuthenticatedUserHeader,
    //         data: item,
    //     })
    //         .then(response => {
    //             localStorage.clear();
    //             window.location.href = "/";
    //             return response.data;
    //         })
    //         .catch(error => {
    //             throw error;
    //         });
    // }
    // logout(item) {

    //     localStorage.clear();
    //     let URL = "/api/v1/logout";
    //     return axios(URL, {
    //         method: 'POST',
    //         headers: this.AuthenticatedUserHeader,
    //         data: item,
    //     })
    //         .then(response => {

    //             localStorage.clear();
    //             window.location.href = "/";
    //             return response.data;
    //         })
    //         .catch(error => {
    //             throw error;
    //         });
    // }

    // checkuseraccesscode(item) {
    //     let URL = "/api/u/checkuseraccesscode";
    //     return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
    // }
    // login(item) {
    //     let URL = "/api/u/login";
    //     this.HeaderParam['client'] = this.client;
    //     return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
    // }



    // getmeranetawhatsappmsgs(item) {
    //     let URL = "/api/v1/getmeranetawhatsappmsgs";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getlicensedetails(item) {
    //     let URL = "/api/v1/getlicensedetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getvoterdetails(item) {
    //     let URL = "/api/v1/getvoterdetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getaadhardetails(item) {
    //     let URL = "/api/v1/getaadhardetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getpandetails(item) {
    //     let URL = "/api/v1/getpandetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getmeranetagenderdetails(item) {
    //     let URL = "/api/v1/getmeranetagenderdetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getmeranetaprofessiondetails(item) {
    //     let URL = "/api/v1/getmeranetaprofessiondetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getmeranetabloodgroupdetails(item) {
    //     let URL = "/api/v1/getmeranetabloodgroupdetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // generateaadharotp(item) {
    //     let URL = "/api/v1/generateaadharotp";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getaadharcarddetails(item) {
    //     let URL = "/api/v1/getaadharcarddetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getkaryakartasreport(item) {
    //     let URL = "/api/v1/getkaryakartasreport";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getvoteriddetails(item) {
    //     let URL = "/api/v1/getvoteriddetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // addvoter(item) {
    //     let URL = "/api/v1/addvoter";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // triggercalltomeraneta(item) {
    //     let URL = "/api/v1/triggercalltomeraneta";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // //campaign apis start

    // getcampaign(item) {
    //     let URL = "/api/v1/getcampaign";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getcampaignlogs(item) {
    //     let URL = "/api/v1/getcampaignlogs";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // campaignreport(item) {
    //     let URL = "/api/v1/campaignreport";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // savecampaign(item) {
    //     let URL = "/api/v1/savecampaign";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // bulkcall(item) {
    //     let URL = "/api/v1/bulkcall";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    // }
    // getcampaigntemplatesList(item) {
    //     let URL = "/api/v1/getcampaigntemplatesList";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // createWhatsappBulkCampaign(item){
    //     let URL = "/api/v1/createWhatsappBulkCampaign";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // createCallsBulkCampaign(item){
    //     let URL = "/api/v1/createCallsBulkCampaign";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // //campaign apis End

    // importvoters(item) {
    //     let URL = "/api/v1/importvoters";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "post", 0, item);
    // }

    // getinstitutetypes(item) {
    //     let URL = "/api/common/getinstitutetypes";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // getinstitutes(item) {
    //     let URL = "/api/u/getinstitutes";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }

    // addinstitution(item) {
    //     let URL = "/api/u/addinstitution";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }

    // updateinstitution(item) {
    //     let URL = "/api/u/updateinstitution";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }

    // getinstitutesdetails(item) {
    //     let URL = "/api/u/getinstitutesdetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // forgotpassword(item) {
    //     let URL = "/api/u/forgotpassword";
    //     return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
    // }

    // getdesignationbyinstitutesdetails(item) {
    //     let URL = "/api/v1/getdesignationbyinstitutesdetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // logoutonsessiontimeout(item) {
    //     let URL = "/api/v1/logoutonsessiontimeout";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getlanguages(item) {
    //     let URL = "/api/v1/common/getlanguages";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // addfacebooktemplate(item) {
    //     let URL = "/api/v1/addfacebooktemplate";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }


    // gettemplatelist(item) {
    //     let URL = "/api/v1/gettemplatelist";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // gettemplatedetails(item) {
    //     let URL = "/api/v1/gettemplatedetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }

    // getvoterlist(item) {
    //     let URL = "/api/v1/getvoterlist";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }

    // getcampaigntemplates(item) {
    //     let URL = "/api/v1/getcampaigntemplates";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }

    // getkaryakartalist(item) {
    //     let url = '/api/v1/getkaryakartalist';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getuserlist(item) {
    //     let url = '/api/v1/getuserlist';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getuserdesignations(item) {
    //     let url = '/api/v1/common/getuserdesignations';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updatevotertype(item) {
    //     let url = '/api/v1/updatevotertype';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getrolelist(item) {
    //     let url = '/api/v1/getrolelist';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // addnewuserrole(item) {
    //     let url = '/api/v1/addnewuserrole';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updateuserrole(item) {
    //     let url = '/api/v1/updateuserrole';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updaterolestatus(item) {
    //     let url = '/api/v1/updaterolestatus';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getclientroles(item) {
    //     let url = '/api/v1/getclientroles';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updateclientuserpermissions(item) {
    //     let url = '/api/v1/updateclientuserpermissions';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getcompanytypes(item) {
    //     let URL = "/api/v1/common/getcompanytypes";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // addfirmcompany(item) {
    //     let url = '/api/v1/addFirmCompany';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updatefirmcompany(item) {
    //     let url = '/api/v1/updateFirmCompany';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getfirmcompanylist(item) {
    //     let url = '/api/v1/getfirmcompanylist';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // gettaglist(item) {
    //     let url = '/api/v1/gettaglist';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // createtag(item) {
    //     let url = '/api/v1/createtag';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getbillingdashboarddata(item) {
    //     let url = '/api/v1/getbillingdashboarddata';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getmainoradvanceaccountstatement(item) {
    //     let url = '/api/v1/getmainoradvanceaccountstatement';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
    // }
    // getcurrentplandetails(item) {
    //     let url = '/api/v1/getcurrentplandetails';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
    // }
    // getcountrycode(item) {
    //     let URL = "/api/v1/common/getcountrycode";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }

    // getloksabha(item) {
    //     let url = '/api/v1/common/getloksabha';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getvidhansabha(item) {
    //     let url = '/api/v1/common/getvidhansabha';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // createNewVoter(item) {
    //     let url = '/api/v1/createNewVoter';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getvirtualnumberslist(item) {
    //     let url = '/api/v1/getvirtualnumberslist';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // savemobilenumber(item) {
    //     let url = '/api/v1/savemobilenumber';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getctsettingconfig(item) {
    //     let url = '/api/v1/getctsettingconfig';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getWeekDays(item) {
    //     let URL = "/api/v1/common/getweekdays";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "GET", 0, item);
    // }
    // checkweekdayes(item) {
    //     let url = '/api/v1/checkweekdayes';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
    // }

    // getcommonctandctholdtunes(item) {
    //     let URL = "/api/v1/getcommonctandctholdtunes";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // getdefaultctandctholdtunes(item) {
    //     let URL = "/api/v1/getdefaultctandctholdtunes";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // addupdatesetting(item) {
    //     let URL = "/api/v1/addupdatesetting";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // getcttunes(item) {
    //     let URL = "/api/v1/getcttunes";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // getclientuser(item) {
    //     let URL = "/api/v1/getclientuser";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // getctholdtunes(item) {
    //     let URL = "/api/v1/getctholdtunes";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // savetunesconfig(item) {
    //     let URL = "/api/v1/savetunesconfig";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // updatetunesconfig(item) {
    //     let URL = "/api/v1/updatetunesconfig";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // otherdays(item) {
    //     let URL = "/api/v1/otherdays";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // copysettonewday(item) {
    //     let URL = "/api/v1/copysettonewday";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // deletesettingset(item) {
    //     let URL = "/api/v1/deletesettingset";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    // }
    // getwhatsapplogs(item) {
    //     let URL = "/api/v1/getwhatsapplogs";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }

    // getwhatsappchatlogs(item) {
    //     let URL = "/api/v1/getwhatsappchatlogs";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // storecampaigntemplate(item) {
    //     let URL = "/api/v1/storecampaigntemplate";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // campaigntemplates(item) {
    //     let URL = "/api/v1/campaigntemplates";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "get", 0, item);
    // }
    // getFirmCompany(item) {
    //     let URL = "/api/v1/getFirmCompany";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "GET", 0, item);
    // }
    // getClientPaymentReceipt(item) {
    //     let URL = "/api/v1/getClientPaymentReceipt";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // updateBillingCompany(item) {
    //     let URL = "/api/v1/updateBillingCompany";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getTaxInvoiceComponent(item) {
    //     let URL = "/api/v1/getTaxInvoiceComponent";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getClientTaxInvoices(item) {
    //     let URL = "/api/v1/getClientTaxInvoices";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // updatevotersdetails(item) {
    //     let URL = "/api/v1/updatevotersdetails";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // submitvoterdata(item) {
    //     let URL = "/api/v1/submitvoterdata";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getstates(item) {
    //     let URL = "/api/v1/getstates";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getdistricts(item) {
    //     let URL = "/api/v1/getdistricts";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getcities(item) {
    //     let URL = "/api/v1/getcities";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getareas(item) {
    //     let URL = "/api/v1/getareas";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getUserUsingMobileNumber(item) {
    //     let URL = "/api/v1/getUserUsingMobileNumber";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // updateprofile(item) {
    //     let URL = "/api/v1/updateprofile";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // changepassword(item) {
    //     let URL = "/api/v1/changepassword";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getModules(item) {
    //     let URL = "/api/v1/getModules";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // adddesignation(item) {
    //     let URL = "/api/v1/adddesignation";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // userDesignationsList(item) {
    //     let URL = "/api/v1/userDesignationsList";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getvoterskaryakartas(item) {
    //     let URL = "/api/v1/common/getVotersKaryakartas";
    //     return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    // }
    // getclientdesignations(item) {
    //     let url = '/api/v1/common/getClientDesginations';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getactiveusers(item) {
    //     let url = '/api/v1/common/getActiveUsers';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getunassignedvoters(item) {
    //     let url = '/api/v1/getUnassignedVoters';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // assignVoterToUser(item) {
    //     let url = '/api/v1/assignVoterToUser';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getIncompleteVoters(item) {
    //     let url = '/api/v1/getIncompleteVoters';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updateuserallowpermissions(item) {
    //     let url = '/api/v1/updateuserallowpermissions';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // changelockstatus(item) {
    //     let url = '/api/v1/changelockstatus';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // gettransactionalsmslogs(item) {
    //     let url = '/api/v1/getTransactionalSmsLogs';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // gettransactionalemaillogs(item) {
    //     let url = '/api/v1/getTransactionalEmailLogs';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getincomingcalllogs(item) {
    //     let url = '/api/v1/getIncomingCallLogs';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getoutgoingcalllogs(item) {
    //     let url = '/api/v1/getOutgoingCallLogs';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getAadharApiLogs(item) {
    //     let url = '/api/v1/getAadharApiLogs';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getVoterCardApiLogs(item) {
    //     let url = '/api/v1/getVoterCardApiLogs';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getPanCardApiLogs(item) {
    //     let url = '/api/v1/getPanCardApiLogs';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getDrivingLicenseApiLogs(item) {
    //     let url = '/api/v1/getDrivingLicenseApiLogs';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getPassportApiLogs(item) {
    //     let url = '/api/v1/getPassportApiLogs';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // addvoterdetails(item) {
    //     let url = '/api/v1/addvoterdetails';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getmismatchedvoters(item) {
    //     let url = '/api/v1/getMismatchedVoters';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getboothnumberlist(item) {
    //     let url = '/api/v1/getBoothNumberList';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // geteventlist(item) {
    //     let url = '/api/v1/getEventList';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getusersexcludingloginuser(item) {
    //     let url = '/api/v1/getUsersExcludingLoginUser';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updatprimarymobile(item) {
    //     let url = '/api/v1/updatePrimaryMobile';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updateeventstatus(item) {
    //     let url = '/api/v1/updateEventStatus';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getticketsbystatus(item) {
    //     let url = '/api/v1/getTicketsByStatus';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // createticketapi(item) {
    //     let url = '/api/v1/createTicket';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // canceltickettapi(item) {
    //     let url = '/api/v1/cancelTicket';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // ticketcommunication(item) {
    //     let url = '/api/v1/ticketCommunication';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getAssignActiveUsers(item) {
    //     let url = '/api/v1/common/getAssignActiveUsers';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getticketmessages(item) {
    //     let url = '/api/v1/getTicketMessages';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getbloodgroups(item) {
    //     let url = '/api/v1/common/getbloodgroups';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // addFunds(item) {
    //     let url = '/api/v1/addFunds';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getcttuneslist(item) {
    //     let url = '/api/v1/getcttuneslist';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // addcustomtunes(item) {
    //     let url = '/api/v1/addcustomtunes';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updatecustomtunes(item) {
    //     let url = '/api/v1/updatecustomtunes';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // cttunesStatusUpdate(item) {
    //     let url = '/api/v1/cttunesStatusUpdate';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getcallbacknumbers(item) {
    //     let url = '/api/v1/getcallbacknumbers';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'GET', 0, item);
    // }
    // updateDesignationStatus(item) {
    //     let url = '/api/v1/updateDesignationStatus';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // gettags(item) {
    //     let url = '/api/v1/gettags';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getProfessions(item) {
    //     let url = '/api/v1/common/getProfessionList';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getpancarddetails(item) {
    //     let url = '/api/v1/getpancarddetails';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getdrivinglicensedetails(item) {
    //     let url = '/api/v1/getdrivinglicensedetails';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getOrignalUserDesignations(item) {
    //     let url = '/api/v1/common/getOrignalUserDesignations';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // voterCardSetAsPrimary(item) {
    //     let url = '/api/v1/voterCardSetAsPrimary';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // checkMobileExists(item) {
    //     let url = '/api/v1/checkMobileExists';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // voterFamilyRelationList(item) {
    //     let url = '/api/v1/voterFamilyRelationList';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getAccessCodeBannerImage(item) {
    //     let url = '/api/u/getAccessCodeBannerImage';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getLoginUserByMobileNumber(item) {
    //     let url = '/api/u/getLoginUserByMobileNumber';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // addUserUsingAadhar(item) {
    //     let url = '/api/v1/addUserUsingAadhar';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getuseraadharcarddetails(item) {
    //     let url = '/api/v1/getuseraadharcarddetails';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updateuservoterdetails(item) {
    //     let url = '/api/v1/updateuservoterdetails';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // updatevoteruserdetails(item) {
    //     let url = '/api/v1/updatevoteruserdetails';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // sendwhatsappmessage(item) {
    //     let url = '/api/v1/sendwhatsappmessage';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getbanklist(item) {
    //     let url = '/api/v1/getbanklist';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getcompanylist(item) {
    //     let url = '/api/v1/getcompanylist';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getareaList(item) {
    //     let url = '/api/v1/getareaList';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // addWhatsappCampaignTemplate(item) {
    //     let url = '/api/v1/addWhatsappCampaignTemplate';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getwhatsappcampaigntemplates(item) {
    //     let url = '/api/v1/getwhatsappcampaigntemplates';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getCampaignVoterList(item) {
    //     let url = '/api/v1/getCampaignVoterList';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // addScheduleCampaign(item) {
    //     let url = '/api/v1/addScheduleCampaign';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // getwhatsappcampaigntemplateList(item) {
    //     let url = '/api/v1/getwhatsappcampaigntemplateList';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getcallcampaigntemplateList(item) {
    //     let url = '/api/v1/getcallcampaigntemplateList';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

    // getcallcampaigntemplates(item) {
    //     let url = '/api/v1/getcallcampaigntemplates';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }
    // addCallCampaignTemplate(item) {
    //     let url = '/api/v1/addCallCampaignTemplate';
    //     return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    // }

}

