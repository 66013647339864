<template>
    <Toast />
    <div class="notfound-page">
        <div class="flex flex-wrap align-content-center justify-content-center" :style="{ height: '100vh' }">
            <Card :style="{ width: '90%' }">
                <template #title>
                    <div class="p-d-flex p-jc-between p-flex-row ">
                        <div class="p-pl-5 p-d-flex p-ai-center justify-content-center" style="min-width: 250px;">
                            <img alt="Goa Electronics" src="/assets/images/goa-electronics-logo.jpg"
                                class="image-header-left" width="250px" />
                        </div>
                        <div class="justify-content-end p-ai-end p-d-flex p-ai-bottom header-label">Host Registration Form
                        </div>
                        <div class="p-pr-5 text-center" style="min-width: 250px;">
                            <img alt="Goa Police" src="/assets//images/police-logo.png" class="image-header-right"
                                width="80px" />
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="card" v-if="!guildelineactive">
                        <Card role="region">
                            <template #content>
                                <div class="p-fluid p-formgrid comman-overflow-scroll-outer"
                                    style="height: calc(100vh - 230px)">
                                    <div class="p-px-3">
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class="p-col-12 p-md-12">
                                                <div class="guideline-label">Welcome, This is the host registration form.</div>
                                                <Divider class="p-mt-1" />
                                            </div>
                                            <div class="p-col-12 p-md-12 guideline-list-header p-mt-3">
                                                It is expected by owner / senior member at the hotel/homestay/host to register yourself for Online Guest Check in process as a part of Goa Government's Online Check in - Check out process of the Guests visiting the Goa State.
                                            </div>
                                            <div class="p-col-12 p-md-12">
                                                <ol class="guideline-list">
                                                    <li>To validate your mobile number, kindly keep your phone available for an OTP.</li>
                                                    <!-- <li>Keep It Company Registed Information
                                                        <ul>
                                                            <li>PAN Number</li>
                                                            <li>GST Number</li>
                                                        </ul>
                                                    </li> -->
                                                    <li>If you have a GST Number, please keep it handy (If available).</li>
                                                    <li>Do not refresh the page until form is completely submitted.</li>
                                                    <li>There are 3 sections in this form.</li>
                                                    <li>In the subsequent step, branch details must be added if your business operates in multiple places.</li>
                                                </ol>
                                            </div>
                                            <div class="p-col-12 p-md-12 guideline-list-header p-mt-3">
                                                In case of any query you can write an email to us on support@abc.com
                                            </div>
                                        </div>
                                        <div class="p-fluid p-formgrid p-text-right">
                                            <Button size="small" label="I, Understood" icon="pi pi-arrow-circle-right"
                                                iconPos="right" @click="agreeWizardStepBtn" style="width: 130px" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Card>
                    </div>
                    <div class="card" v-if="guildelineactive">
                        <Steps v-model:activeStep="active" :model="items" />
                        <Card>
                            <template #content>
                                <div class="p-fluid p-formgrid comman-overflow-scroll-outer" v-if="active == 0"
                                    style="height: calc(100vh - 290px)">
                                    <div class="p-px-3">
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class="p-col-12 p-md-6">
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-12">
                                                        <label for="fullname">
                                                            Your Name <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <div class="p-inputgroup p-field mb-0">
                                                            <span class="p-inputgroup-addon"
                                                                style="padding: 0; min-width: 100px;">
                                                                <Dropdown id="title" :disabled="nameinputdisabledflag"
                                                                    v-model="stepopen.title" :options="titleList"
                                                                    optionLabel="label" placeholder="Select..." autofocus
                                                                    :class="{ 'p-invalid': submitted && !stepopen.title }"
                                                                    style="border: 0;" />
                                                            </span>
                                                            <span class="p-float-label">
                                                                <InputText id="fullname"
                                                                    @keypress="isLetterWithSpace($event)" maxlength="30"
                                                                    class="p-text-capitalize" placeholder="Enter Your Full Name" v-model="stepopen.fullname"
                                                                    :disabled="nameinputdisabledflag" autofocus
                                                                    :class="{ 'p-invalid': submitted && !stepopen.fullname }" />
                                                            </span>
                                                        </div>
                                                        <small class="p-invalid p-error" v-if="v$.stepopen.title.$error">{{
                                                            v$.stepopen.title.$errors[0].$message }}</small>
                                                        <small class="p-invalid p-error"
                                                            v-if="stepopen.title != '' && v$.stepopen.fullname.$error">{{
                                                                v$.stepopen.fullname.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-6">
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-6">
                                                        <label for="mobileno" class="p-d-flex p-jc-between p-flex-row">
                                                            <span>Mobile Number <span
                                                                    class="p-invalid p-error">*</span></span>
                                                            <Button @click="changeMobileBtn()"
                                                                v-tooltip.top="'Change Mobile No.'" v-if="otpsendinputshow"
                                                                icon="pi pi-pencil" style="height: 16px;"
                                                                class="p-button-text resendotpclass" link></Button>
                                                        </label>
                                                        <div class="p-inputgroup p-field mb-0">
                                                            <span class="p-inputgroup-addon"
                                                                style="padding: 0; min-width: 100px;">
                                                                <Dropdown id="country" v-model="countrycode"
                                                                    :options="countryCodeList" optionLabel="label"
                                                                    placeholder="Select..." :disabled="true" appendTo="body"
                                                                    style="border: 0;">
                                                                </Dropdown>
                                                            </span>
                                                            <span class="p-float-label">
                                                                <InputText maxlength="10"
                                                                    @keypress="onlyNumberMobileFirstStep" id="mobilenoowner"
                                                                    :disabled="mobdisabledflag" placeholder="Enter Mobile Number" v-model="stepopen.mobileno"
                                                                    autofocus
                                                                    :class="{ 'p-invalid': submitted && !stepopen.mobileno }" />
                                                            </span>
                                                        </div>
                                                        <small class="p-invalid p-error"
                                                            v-if="v$.stepopen.mobileno.$error">{{
                                                                v$.stepopen.mobileno.$errors[0].$message }}</small>
                                                        <small class="p-invalid p-error" v-if="mobileExistsErrorMsg">{{
                                                            this.mobileExistsErrorMsg }}</small>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-3" v-if="otpsendbtnhide">
                                                        <Button v-if="!showotpsendLoader" label="Verify No."
                                                            :disabled="stepopen.mobileno.length != 10 || showotpsendLoader"
                                                            class="p-mt-4" @click="sendOTPBtn()" style="width: 107px"
                                                            rounded />
                                                        <Button v-if="showotpsendLoader"
                                                            icon="pi pi-spin pi-spinner indigo-color"
                                                            class="p-mt-4 p-button-text" style="width: 107px"></Button>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-3" v-if="otpsendinputshow">
                                                        <label for="mobileotp">
                                                            Enter Code
                                                            <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <InputText minlength="4" :disabled="hideotpinput" maxlength="6" @keypress="onlyNumber"
                                                            id="campanymob" placeholder="Enter Code" v-model="mobileotp" autofocus
                                                            :class="{ 'p-invalid': submitted && !mobileotp }" />
                                                        <small v-if="timerMobileStatus" class="p-invalid p-error">{{
                                                            timerMobileInterval
                                                        }}</small>
                                                        <div v-if="resendOtpBtnStatus" class="p-text-right">
                                                        <Button v-if="!showotpresendLoader" @click="reSendOTPBtn()"
                                                            label="Resend Code" class="p-button-text resendotpclass"
                                                            link></Button>
                                                            <Button v-if="showotpresendLoader"
                                                            icon="pi pi-spin pi-spinner indigo-color"
                                                            class="p-button-text resendotpclass"></Button>
                                                        </div>
                                                        <small class="p-invalid p-error" v-if="v$.mobileotp.$error">{{
                                                            v$.mobileotp.$errors[0].$message }}</small>
                                                            <small v-if="mobileotperr" class="p-invalid p-error">{{
                                                            mobileotperr
                                                        }}</small>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-3" v-if="otpsendinputshow">
                                                        <label for="mobileotp" class="indigo-color" style="height: 16px;">{{this.mobileotpvalue}}</label>
                                                        <Button v-if="!showotpverifyLoader" @click="verifyOTPBtn()"
                                                            label="Verify" style="width: 107px" severity="success"
                                                            :disabled="mobileotp.length <= 3 || showotpverifyLoader"
                                                             rounded />
                                                        <Button v-if="showotpverifyLoader"
                                                            icon="pi pi-spin pi-spinner indigo-color"
                                                            class="p-button-text" style="width: 107px"></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  -->
                                        <div class="p-fluid p-formgrid p-grid" v-if="verifiedsuccessotp">
                                            <div class="p-col-12 p-md-12">
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-4" v-show="!isDesignationExist">
                                                        <label for="designation"> Designation
                                                        </label>
                                                        <Dropdown id="designation" v-model="stepf.designation"
                                                            :options="designationList" optionLabel="label" :filter="true"
                                                            placeholder="Select..." appendTo="body" />
                                                        <label class="p-mt-1">
                                                            Not found,
                                                            <span>
                                                                <a class="p-ai-center p-text-right cursor-pointer"
                                                                    style="color: #3f51b5" @click="toggleDesignation">Click
                                                                    here for create</a>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-4" v-show="isDesignationExist">
                                                        <label for="designation">
                                                            Create Designation
                                                        </label>
                                                        <InputText class="p-text-capitalize"
                                                            @input="createOtherDesignationName"
                                                            placeholder="Enter Designation" />
                                                        <label class="p-mt-1">
                                                            For search,
                                                            <span>
                                                                <a class="p-ai-center p-text-right cursor-pointer"
                                                                    style="color: #3f51b5" @click="toggleDesignation">Click
                                                                    here</a>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="brandname">
                                                            Brand Name <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <InputText id="brandname" class="p-text-capitalize" placeholder="Enter Brand Name"
                                                            v-model.trim="stepf.brandname" maxlength="50" autofocus
                                                            :class="{ 'p-invalid': submitted && !stepf.brandname }" />
                                                        <span class="p-d-flex p-jc-between p-flex-row text-end">
                                                            <span><small class="p-invalid p-error"
                                                                    v-if="v$.stepf.brandname.$error">{{
                                                                        v$.stepf.brandname.$errors[0].$message }}</small></span>
                                                            <small id="username-help" class="text-end helptext">e.g. Taj Fort Aguada Resort and Spa</small></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-12">
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="isgstnumberflag">
                                                            Do you have a GST Number? <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <div class="p-field-radiobutton">
                                                            <RadioButton id="havegst" name="option" v-bind:value="1" v-model="stepf.isgstnumberflag" />
                                                            <label for="havegst" class="p-mr-2">Yes, We have GST Number</label>

                                                            <RadioButton id="doesnotgst" name="option" :disabled="hidegstsubmitbtn" v-bind:value="2" v-model="stepf.isgstnumberflag" />
                                                            <label for="doesnotgst" class="p-mr-2">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-fluid p-formgrid p-grid" >
                                            <div class="p-col-12 p-md-12" v-if="stepf.isgstnumberflag == 2">
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="legalname">
                                                            Legal Name <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <InputText id="legalname" class="p-text-capitalize" placeholder="Enter Legal Name"
                                                            v-model.trim="stepf.legalname" maxlength="50" autofocus
                                                            :class="{ 'p-invalid': submitted && !stepf.legalname }" />
                                                        <span class="p-d-flex p-jc-between p-flex-row text-end">
                                                            <span><small class="p-invalid p-error"
                                                                    v-if="v$.stepf.legalname.$error">{{
                                                                        v$.stepf.legalname.$errors[0].$message }}</small></span>
                                                            <small id="username-help" class="text-end helptext">e.g. The Indian Hotels Company Limited</small></span>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="entitytype">
                                                            Entity Type <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <Dropdown id="entitytype" v-model="stepf.entitytype"
                                                            :options="entitytypeList" optionLabel="label"
                                                            placeholder="Select..." autofocus
                                                            :class="{ 'p-invalid': submitted && !stepf.entitytype }" />
                                                        <small class="p-invalid p-error"
                                                            v-if="v$.stepf.entitytype.$error">{{
                                                                v$.stepf.entitytype.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="state">State <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <Dropdown id="state" v-model="stepf.state" :options="stateList"
                                                            optionLabel="label" autofocus :filter="true"
                                                            placeholder="Select..." appendTo="body"
                                                            @change="getCityByStateId($event)"
                                                            :class="{ 'p-invalid': submitted && !stepf.state }" />
                                                        <small class="p-invalid p-error" v-if="v$.stepf.state.$error">{{
                                                            v$.stepf.state.$errors[0].$message }}</small>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="city">City <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <Dropdown v-show="!cityloaderflag" id="city" v-model="stepf.city"
                                                            :options="cityList" optionLabel="label" autofocus :filter="true"
                                                            placeholder="Select..." appendTo="body"
                                                            @change="getAreaByCityId($event)"
                                                            :class="{ 'p-invalid': submitted && !stepf.city }" />
                                                        <Dropdown v-show="cityloaderflag" :searchable="false"
                                                            placeholder="Loading..." loading />
                                                        <small class="p-invalid p-error" v-if="v$.stepf.city.$error">{{
                                                            v$.stepf.city.$errors[0].$message }}</small>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="area">Area <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <Dropdown v-show="!arealoaderflag" id="area" v-model="stepf.area"
                                                            :options="areaList" optionLabel="label" autofocus :filter="true"
                                                            placeholder="Select..." appendTo="body"
                                                            :class="{ 'p-invalid': submitted && !stepf.area }" />
                                                        <Dropdown v-show="arealoaderflag" :searchable="false"
                                                            placeholder="Loading..." loading />
                                                        <small class="p-invalid p-error" v-if="v$.stepf.area.$error">{{
                                                            v$.stepf.area.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-8">
                                                        <label for="address">Address <span
                                                                class="p-invalid p-error">*</span></label>
                                                        <Textarea id="address" rows="2" maxlength="250"
                                                            v-model="stepf.address" autofocus placeholder="Enter Registered Address"
                                                            :class="{ 'p-invalid': submitted && !stepf.address }" />
                                                        <small class="p-invalid p-error" v-if="v$.stepf.address.$error">{{
                                                            v$.stepf.address.$errors[0].$message }}</small>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="stepfpincode">
                                                            Pincode <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <InputText minlength="6" maxlength="6"
                                                            @keypress="pincodeFormatFirstStep" id="stepfpincode"
                                                            v-model="stepf.pincode" autofocus placeholder="Enter Pincode"
                                                            :class="{ 'p-invalid': submitted && !stepf.pincode }" />
                                                        <small class="p-invalid p-error" v-if="v$.stepf.pincode.$error">{{
                                                            v$.stepf.pincode.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="pannumber">
                                                            PAN Number
                                                        </label>
                                                        <InputText maxlength="10" class="p-text-uppercase" id="pannumber"
                                                            v-model="stepf.pannumber"
                                                            @input="handlePanNumber($event)" autofocus />
                                                        <small class="p-invalid p-error" v-if="errmsg.pannumber">{{
                                                            errmsg.pannumber
                                                        }}</small>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-8">
                                                        <label for="maplink">
                                                            Google Map Link <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <InputText maxlength="50" id="maplink" v-model="stepf.maplink"
                                                            autofocus placeholder="Enter Google Map Link"
                                                            :class="{ 'p-invalid': submitted && !stepf.maplink }" />
                                                        <small class="p-invalid p-error" v-if="v$.stepf.maplink.$error">{{
                                                            v$.stepf.maplink.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                                <div class="p-fluid p-formgrid p-text-right">
                                                    <Button v-if="!showfirststepLoader" size="small" label="Save & Next"
                                                        icon="pi pi-arrow-circle-right" iconPos="right"
                                                        @click="firstWizardStepBtn" style="width: 130px" />
                                                    <Button v-if="showfirststepLoader" size="small"
                                                        icon="pi pi-spin pi-spinner indigo-color" class="p-button-text"
                                                        style="width: 130px"></Button>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-12" v-if="stepf.isgstnumberflag == 1">
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-4">
                                                        <label for="gstnumber">
                                                            GST Number <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <InputText maxlength="15" class="p-text-uppercase" id="gstnumber" 
                                                            v-model="stepf.gstnumber" autofocus @input="handleGSTNumber($event)"
                                                            :class="{ 'p-invalid': submitted && !stepf.gstnumber }" :disabled="hidegstsubmitbtn" />
                                                        <small class="p-invalid p-error" v-if="v$.stepf.gstnumber.$error">{{
                                                            v$.stepf.gstnumber.$errors[0].$message }}</small>
                                                        <small class="p-invalid p-error" v-if="errmsg.gstnumber">{{
                                                            errmsg.gstnumber
                                                        }}</small>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-3" v-if="!hidegstsubmitbtn">
                                                        <Button v-if="!showgstdataLoader" label="Verify No."
                                                            :disabled="stepf.gstnumber.length != 15 || this.errmsg.gstnumber || showgstdataLoader"
                                                            class="p-mt-4" @click="getGstDetails()" style="width: 107px"
                                                            rounded />
                                                        <Button v-if="showgstdataLoader"
                                                            icon="pi pi-spin pi-spinner indigo-color"
                                                            class="p-mt-4 p-button-text" style="width: 107px"></Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-12" v-if="this.gstcompanydetails != ''">
                                                <div class="gst-detail-box-outer mb-3">
                                                    <div class="p-fluid p-formgrid p-grid">
                                                        <div class="p-field p-col-12 p-md-6">
                                                            <label class="form-gst-label">Legal Name</label>
                                                            <div class="from-gst-value p-text-capitalize">
                                                            {{
                                                                gstcompanydetails.ac28
                                                                ? gstcompanydetails.ac28
                                                                : "N/A"
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="p-field p-col-12 p-md-6">
                                                            <label class="form-gst-label">Entity Type</label>
                                                            <div class="from-gst-value p-text-capitalize">
                                                            {{
                                                                gstcompanydetails.ac23
                                                                ? gstcompanydetails.ac23
                                                                : "N/A"
                                                            }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-fluid p-formgrid p-grid">
                                                        <div class="p-field p-col-12 p-md-4">
                                                            <label class="form-gst-label">GST Number</label>
                                                            <div class="from-gst-value p-text-uppercase">
                                                            {{
                                                                gstcompanydetails.ac4
                                                                ? gstcompanydetails.ac4
                                                                : "N/A"
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="p-field p-col-12 p-md-4">
                                                            <label class="form-gst-label">PAN Number</label>
                                                            <div class="from-gst-value p-text-uppercase">
                                                            {{
                                                                gstcompanydetails.ac5
                                                                ? gstcompanydetails.ac5
                                                                : "N/A"
                                                            }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-fluid p-formgrid p-grid">
                                                        <div class="p-field p-col-12 p-md-4">
                                                            <label class="form-gst-label">State</label>
                                                            <div class="from-gst-value p-text-capitalize">
                                                            {{
                                                                gstcompanydetails.ac11
                                                                ? gstcompanydetails.ac11
                                                                : "N/A"
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="p-field p-col-12 p-md-4" v-if="gstcompanydetails.ac13">
                                                            <label class="form-gst-label">City</label>
                                                            <div class="from-gst-value p-text-capitalize">
                                                            {{
                                                                gstcompanydetails.ac13
                                                                ? gstcompanydetails.ac13
                                                                : "N/A"
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="p-field p-col-12 p-md-4" v-if="gstcompanydetails.ac15">
                                                            <label class="form-gst-label">Area</label>
                                                            <div class="from-gst-value p-text-capitalize">
                                                            {{
                                                                gstcompanydetails.ac15
                                                                ? gstcompanydetails.ac15
                                                                : "N/A"
                                                            }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-fluid p-formgrid p-grid">
                                                        <div class="p-field p-col-12 p-md-8">
                                                            <label class="form-gst-label">Address</label>
                                                            <div class="from-gst-value p-text-capitalize">
                                                            {{
                                                                gstcompanydetails.ac21
                                                                ? gstcompanydetails.ac21
                                                                : "N/A"
                                                            }}
                                                            </div>
                                                        </div>
                                                        <div class="p-field p-col-12 p-md-4" v-if="gstcompanydetails.ac16">
                                                            <label class="form-gst-label">Pincode</label>
                                                            <div class="from-gst-value p-text-capitalize">
                                                            {{
                                                                gstcompanydetails.ac16
                                                                ? gstcompanydetails.ac16
                                                                : "N/A"
                                                            }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                            </div>
                                            <div class="p-col-12 p-md-12" v-if="this.gstcompanydetails != ''">
                                                <div class="p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-12">
                                                        <label for="maplink">
                                                            Google Map Link <span class="p-invalid p-error">*</span>
                                                        </label>
                                                        <InputText maxlength="50" id="maplink" v-model="stepf.maplink"
                                                            autofocus placeholder="Enter Google Map Link"
                                                            :class="{ 'p-invalid': submitted && !stepf.maplink }" />
                                                        <small class="p-invalid p-error" v-if="v$.stepf.maplink.$error">{{
                                                            v$.stepf.maplink.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                                <div class="p-fluid p-formgrid p-text-right">
                                                    <Button v-if="!showfirststepLoader" size="small" label="Save & Next"
                                                        icon="pi pi-arrow-circle-right" iconPos="right"
                                                        @click="firstWizardStepBtn" style="width: 130px" />
                                                    <Button v-if="showfirststepLoader" size="small"
                                                        icon="pi pi-spin pi-spinner indigo-color" class="p-button-text"
                                                        style="width: 130px"></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-fluid p-formgrid comman-overflow-scroll-outer" v-if="active == 1"
                                    style="height: calc(100vh - 290px)">
                                    <div class="p-px-3">
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class="p-field p-col-12 p-md-4">
                                                <label for="branchescount">How Many Branches | Establishments | Properties?
                                                    <span class="p-invalid p-error">*</span>
                                                </label>
                                                <Dropdown id="branchescount" v-model="stepse.branchescount"
                                                    :options="branchCountList" optionLabel="label" autofocus :filter="false"
                                                    placeholder="Select..." appendTo="body"
                                                    :class="{ 'p-invalid': submitted && !stepse.branchescount }" />
                                                <small class="p-invalid p-error" v-if="v$.stepse.branchescount.$error">{{
                                                    v$.stepse.branchescount.$errors[0].$message }}</small>
                                            </div>
                                        </div>
                                        <!-- Single branch Div -->
                                        <div class="p-fluid p-formgrid p-grid" v-if="stepse.branchescount.value == 1">
                                            <div class="p-field p-col-12 p-md-4">
                                                <label for="branchcatagory">Select Category <span
                                                        class="p-invalid p-error">*</span>
                                                </label>
                                                <Dropdown id="branchcatagory" v-model="stepse.branchcatagory"
                                                    :options="hotelcategoryList" optionLabel="label" autofocus
                                                    :filter="false" placeholder="Select..." appendTo="body"
                                                    :class="{ 'p-invalid': submitted && !stepse.branchcatagory }" />
                                                <small class="p-invalid p-error" v-if="v$.stepse.branchcatagory.$error">{{
                                                    v$.stepse.branchcatagory.$errors[0].$message }}</small>
                                            </div>
                                        </div>
                                        <div class="p-fluid p-formgrid p-text-right p-pt-3"
                                            v-if="stepse.branchescount.value == 1">
                                            <Button v-if="!showsecstepLoader" size="small" label="Save & Next"
                                                icon="pi pi-arrow-circle-right" iconPos="right"
                                                @click="secondWizardStepSingleBtn" style="width: 130px" />
                                            <Button v-if="showsecstepLoader" size="small"
                                                icon="pi pi-spin pi-spinner indigo-color" class="p-button-text"
                                                style="width: 130px"></Button>
                                        </div>
                                        <!-- Multiple Branches Div -->
                                        <div class="p-fluid p-formgrid p-grid" v-if="stepse.branchescount.value > 1">
                                            <div class="p-col-12 p-md-12 ">
                                                <div class="flex ">
                                                    <div class="gap-2 flex flex-column justify-content-start p-pt-3">
                                                        <Button @click="activebranch = 0"
                                                            v-if="stepse.branchescount.value > 1" rounded label="Branch 1"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 0" />
                                                        <Button @click="activebranch = 1"
                                                            v-if="stepse.branchescount.value > 1" rounded label="Branch 2"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 1" />
                                                        <Button @click="activebranch = 2"
                                                            v-if="stepse.branchescount.value > 2" rounded label="Branch 3"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 2" />
                                                        <Button @click="activebranch = 3"
                                                            v-if="stepse.branchescount.value > 3" rounded label="Branch 4"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 3" />
                                                        <Button @click="activebranch = 4"
                                                            v-if="stepse.branchescount.value > 4" rounded label="Branch 5"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 4" />
                                                        <Button @click="activebranch = 5"
                                                            v-if="stepse.branchescount.value > 5" rounded label="Branch 6"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 5" />
                                                        <Button @click="activebranch = 6"
                                                            v-if="stepse.branchescount.value > 6" rounded label="Branch 7"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 6" />
                                                        <Button @click="activebranch = 7"
                                                            v-if="stepse.branchescount.value > 7" rounded label="Branch 8"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 7" />
                                                        <Button @click="activebranch = 8"
                                                            v-if="stepse.branchescount.value > 8" rounded label="Branch 9"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 8" />
                                                        <Button @click="activebranch = 9"
                                                            v-if="stepse.branchescount.value > 9" rounded label="Branch 10"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 9" />
                                                        <Button @click="activebranch = 10"
                                                            v-if="stepse.branchescount.value > 10" rounded label="Branch 11"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 10" />
                                                        <Button @click="activebranch = 11"
                                                            v-if="stepse.branchescount.value > 11" rounded label="Branch 12"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 11" />
                                                        <Button @click="activebranch = 12"
                                                            v-if="stepse.branchescount.value > 12" rounded label="Branch 13"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 12" />
                                                        <Button @click="activebranch = 13"
                                                            v-if="stepse.branchescount.value > 13" rounded label="Branch 14"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 13" />
                                                        <Button @click="activebranch = 14"
                                                            v-if="stepse.branchescount.value > 14" rounded label="Branch 15"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 14" />
                                                        <Button @click="activebranch = 15"
                                                            v-if="stepse.branchescount.value > 15" rounded label="Branch 16"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 15" />
                                                        <Button @click="activebranch = 16"
                                                            v-if="stepse.branchescount.value > 16" rounded label="Branch 17"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 16" />
                                                        <Button @click="activebranch = 17"
                                                            v-if="stepse.branchescount.value > 17" rounded label="Branch 18"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 17" />
                                                        <Button @click="activebranch = 18"
                                                            v-if="stepse.branchescount.value > 18" rounded label="Branch 19"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 18" />
                                                        <Button @click="activebranch = 19"
                                                            v-if="stepse.branchescount.value > 19" rounded label="Branch 20"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 19" />
                                                        <Button @click="activebranch = 20"
                                                            v-if="stepse.branchescount.value > 20" rounded label="Branch 21"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 20" />
                                                        <Button @click="activebranch = 21"
                                                            v-if="stepse.branchescount.value > 21" rounded label="Branch 22"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 21" />
                                                        <Button @click="activebranch = 22"
                                                            v-if="stepse.branchescount.value > 22" rounded label="Branch 23"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 22" />
                                                        <Button @click="activebranch = 23"
                                                            v-if="stepse.branchescount.value > 23" rounded label="Branch 24"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 23" />
                                                        <Button @click="activebranch = 24"
                                                            v-if="stepse.branchescount.value > 24" rounded label="Branch 25"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activebranch !== 24" />
                                                    </div>
                                                    <Card class="p-ml-3 p-mb-1" style="width:100%">
                                                        <template #content>
                                                            <div v-if="activebranch == 0">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="onebranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30" id="onebranchname"
                                                                                    class="p-text-capitalize"
                                                                                    v-model="stepseone.onebranchname"
                                                                                    autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepseone.onebranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseone.onebranchname.$error">{{
                                                                                        v$.stepseone.onebranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="onebranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="onebranchcatagory"
                                                                                    v-model="stepseone.onebranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseone.onebranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseone.onebranchcatagory.$error">{{
                                                                                        v$.stepseone.onebranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="onesameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepseone.onesameaddressstatus" />
                                                                                    <label for="onesameaddressstatus">Same
                                                                                        As Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Host Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepseone.onesameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepseoneonestate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="stepseoneonestate"
                                                                                    v-model="stepseone.onestate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateOneId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseone.onestate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseone.onestate.$error">{{
                                                                                        v$.stepseone.onestate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepseoneonecity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="stepseoneonecity"
                                                                                    v-model="stepseone.onecity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityOneId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseone.onecity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseone.onecity.$error">{{
                                                                                        v$.stepseone.onecity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepseoneonearea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="stepseoneonearea"
                                                                                    v-model="stepseone.onearea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseone.onearea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseone.onearea.$error">{{
                                                                                        v$.stepseone.onearea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="stepseoneoneaddress">Address
                                                                                    <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="stepseoneoneaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepseone.oneaddress" autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepseone.oneaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseone.oneaddress.$error">{{
                                                                                        v$.stepseone.oneaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepseoneonepincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepOne"
                                                                                    id="stepseoneonepincode" placeholder="Enter Pincode"
                                                                                    v-model="stepseone.onepincode" autofocus
                                                                                    :class="{ 'p-invalid': submitted && !stepseone.onepincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseone.onepincode.$error">{{
                                                                                        v$.stepseone.onepincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="stepseoneonemaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="stepseoneonemaplink" placeholder="Enter Google Map Link"
                                                                                    v-model="stepseone.onemaplink" autofocus
                                                                                    :class="{ 'p-invalid': submitted && !stepseone.onemaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseone.onemaplink.$error">{{
                                                                                        v$.stepseone.onemaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepOnebranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 1">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsetwotwobranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="stepsetwotwobranchname"
                                                                                    v-model="stepsetwo.twobranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwo.twobranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwo.twobranchname.$error">{{
                                                                                        v$.stepsetwo.twobranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label
                                                                                    for="stepsetwotwobranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="stepsetwotwobranchcatagory"
                                                                                    v-model="stepsetwo.twobranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwo.twobranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwo.twobranchcatagory.$error">{{
                                                                                        v$.stepsetwo.twobranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="twosameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsetwo.twosameaddressstatus" />
                                                                                    <label for="twosameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsetwo.twosameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twostate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twostate"
                                                                                    v-model="stepsetwo.twostate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateTwoId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwo.twostate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwo.twostate.$error">{{
                                                                                        v$.stepsetwo.twostate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twocity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="twocity" v-model="stepsetwo.twocity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityTwoId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwo.twocity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwo.twocity.$error">{{
                                                                                        v$.stepsetwo.twocity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twoarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="twoarea" v-model="stepsetwo.twoarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwo.twoarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwo.twoarea.$error">{{
                                                                                        v$.stepsetwo.twoarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="twoaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="twoaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsetwo.twoaddress" autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwo.twoaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwo.twoaddress.$error">{{
                                                                                        v$.stepsetwo.twoaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsetwotwopincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepTwo"
                                                                                    id="stepsetwotwopincode" placeholder="Enter Pincode"
                                                                                    v-model="stepsetwo.twopincode" autofocus
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwo.twopincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwo.twopincode.$error">{{
                                                                                        v$.stepsetwo.twopincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="twomaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="twomaplink"
                                                                                    v-model="stepsetwo.twomaplink" autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwo.twomaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwo.twomaplink.$error">{{
                                                                                        v$.stepsetwo.twomaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepTwobranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 2">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="threebranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="threebranchname"
                                                                                    v-model="stepsethree.threebranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethree.threebranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethree.threebranchname.$error">{{
                                                                                        v$.stepsethree.threebranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="threebranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="threebranchcatagory"
                                                                                    v-model="stepsethree.threebranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethree.threebranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethree.threebranchcatagory.$error">{{
                                                                                        v$.stepsethree.threebranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="threesameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsethree.threesameaddressstatus" />
                                                                                    <label for="threesameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsethree.threesameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="threestate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="threestate"
                                                                                    v-model="stepsethree.threestate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateThreeId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethree.threestate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethree.threestate.$error">{{
                                                                                        v$.stepsethree.threestate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="threecity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="threecity"
                                                                                    v-model="stepsethree.threecity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityThreeId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethree.threecity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethree.threecity.$error">{{
                                                                                        v$.stepsethree.threecity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="threearea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="threearea"
                                                                                    v-model="stepsethree.threearea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethree.threearea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethree.threearea.$error">{{
                                                                                        v$.stepsethree.threearea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="threeaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="threeaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsethree.threeaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethree.threeaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethree.threeaddress.$error">{{
                                                                                        v$.stepsethree.threeaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsethreethreepincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepThree"
                                                                                    id="stepsethreethreepincode"
                                                                                    v-model="stepsethree.threepincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethree.threepincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethree.threepincode.$error">{{
                                                                                        v$.stepsethree.threepincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="threemaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="threemaplink"
                                                                                    v-model="stepsethree.threemaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethree.threemaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethree.threemaplink.$error">{{
                                                                                        v$.stepsethree.threemaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepThreebranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 3">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="fourbranchname"
                                                                                    v-model="stepsefour.fourbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefour.fourbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefour.fourbranchname.$error">{{
                                                                                        v$.stepsefour.fourbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fourbranchcatagory"
                                                                                    v-model="stepsefour.fourbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefour.fourbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefour.fourbranchcatagory.$error">{{
                                                                                        v$.stepsefour.fourbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="foursameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsefour.foursameaddressstatus" />
                                                                                    <label for="foursameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsefour.foursameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fourstate"
                                                                                    v-model="stepsefour.fourstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateFourId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefour.fourstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefour.fourstate.$error">{{
                                                                                        v$.stepsefour.fourstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourcity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="fourcity"
                                                                                    v-model="stepsefour.fourcity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityFourId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefour.fourcity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefour.fourcity.$error">{{
                                                                                        v$.stepsefour.fourcity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="fourarea"
                                                                                    v-model="stepsefour.fourarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefour.fourarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefour.fourarea.$error">{{
                                                                                        v$.stepsefour.fourarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="fouraddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="fouraddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsefour.fouraddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefour.fouraddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefour.fouraddress.$error">{{
                                                                                        v$.stepsefour.fouraddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsefourfourpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepFour"
                                                                                    id="stepsefourfourpincode"
                                                                                    v-model="stepsefour.fourpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefour.fourpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefour.fourpincode.$error">{{
                                                                                        v$.stepsefour.fourpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="fourmaplink">
                                                                                    Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="fourmaplink"
                                                                                    v-model="stepsefour.fourmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefour.fourmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefour.fourmaplink.$error">{{
                                                                                        v$.stepsefour.fourmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepFourbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 4">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fivebranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="fivebranchname"
                                                                                    v-model="stepsefive.fivebranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefive.fivebranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefive.fivebranchname.$error">{{
                                                                                        v$.stepsefive.fivebranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fivebranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fivebranchcatagory"
                                                                                    v-model="stepsefive.fivebranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefive.fivebranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefive.fivebranchcatagory.$error">{{
                                                                                        v$.stepsefive.fivebranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="fivesameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsefive.fivesameaddressstatus" />
                                                                                    <label for="fivesameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsefive.fivesameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fivestate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fivestate"
                                                                                    v-model="stepsefive.fivestate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateFiveId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefive.fivestate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefive.fivestate.$error">{{
                                                                                        v$.stepsefive.fivestate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fivecity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="fivecity"
                                                                                    v-model="stepsefive.fivecity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityFiveId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefive.fivecity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefive.fivecity.$error">{{
                                                                                        v$.stepsefive.fivecity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fivearea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="fivearea"
                                                                                    v-model="stepsefive.fivearea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefive.fivearea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefive.fivearea.$error">{{
                                                                                        v$.stepsefive.fivearea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="fiveaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="fiveaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsefive.fiveaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefive.fiveaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefive.fiveaddress.$error">{{
                                                                                        v$.stepsefive.fiveaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsefivefivepincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepFive"
                                                                                    id="stepsefivefivepincode"
                                                                                    v-model="stepsefive.fivepincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefive.fivepincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefive.fivepincode.$error">{{
                                                                                        v$.stepsefive.fivepincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="fivemaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="fivemaplink"
                                                                                    v-model="stepsefive.fivemaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefive.fivemaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefive.fivemaplink.$error">{{
                                                                                        v$.stepsefive.fivemaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepFivebranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 5">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30" id="sixbranchname"
                                                                                    v-model="stepsesix.sixbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesix.sixbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesix.sixbranchname.$error">{{
                                                                                        v$.stepsesix.sixbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="sixbranchcatagory"
                                                                                    v-model="stepsesix.sixbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesix.sixbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesix.sixbranchcatagory.$error">{{
                                                                                        v$.stepsesix.sixbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="sixsameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsesix.sixsameaddressstatus" />
                                                                                    <label for="sixsameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsesix.sixsameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="sixstate"
                                                                                    v-model="stepsesix.sixstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateSixId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesix.sixstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesix.sixstate.$error">{{
                                                                                        v$.stepsesix.sixstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixcity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="sixcity" v-model="stepsesix.sixcity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCitySixId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesix.sixcity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesix.sixcity.$error">{{
                                                                                        v$.stepsesix.sixcity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="sixarea" v-model="stepsesix.sixarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesix.sixarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesix.sixarea.$error">{{
                                                                                        v$.stepsesix.sixarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="sixaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="sixaddress" rows="2"
                                                                                    maxlength="250" placeholder="Enter Registered Address"
                                                                                    v-model="stepsesix.sixaddress" autofocus
                                                                                    :class="{ 'p-invalid': submitted && !stepsesix.sixaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesix.sixaddress.$error">{{
                                                                                        v$.stepsesix.sixaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsesixsixpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepSix"
                                                                                    id="stepsesixsixpincode" placeholder="Enter Pincode"
                                                                                    v-model="stepsesix.sixpincode" autofocus
                                                                                    :class="{ 'p-invalid': submitted && !stepsesix.sixpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesix.sixpincode.$error">{{
                                                                                        v$.stepsesix.sixpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="sixmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="sixmaplink"
                                                                                    v-model="stepsesix.sixmaplink" autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesix.sixmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesix.sixmaplink.$error">{{
                                                                                        v$.stepsesix.sixmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepSixbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 6">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sevenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="sevenbranchname"
                                                                                    v-model="stepseseven.sevenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseven.sevenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseven.sevenbranchname.$error">{{
                                                                                        v$.stepseseven.sevenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sevenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="sevenbranchcatagory"
                                                                                    v-model="stepseseven.sevenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseven.sevenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseven.sevenbranchcatagory.$error">{{
                                                                                        v$.stepseseven.sevenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="sevensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepseseven.sevensameaddressstatus" />
                                                                                    <label for="sevensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepseseven.sevensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sevenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="sevenstate"
                                                                                    v-model="stepseseven.sevenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateSevenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseven.sevenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseven.sevenstate.$error">{{
                                                                                        v$.stepseseven.sevenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sevencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="sevencity"
                                                                                    v-model="stepseseven.sevencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCitySevenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseven.sevencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseven.sevencity.$error">{{
                                                                                        v$.stepseseven.sevencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sevenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="sevenarea"
                                                                                    v-model="stepseseven.sevenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseven.sevenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseven.sevenarea.$error">{{
                                                                                        v$.stepseseven.sevenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="sevenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="sevenaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepseseven.sevenaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseven.sevenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseven.sevenaddress.$error">{{
                                                                                        v$.stepseseven.sevenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsesevensevenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepSeven"
                                                                                    id="stepsesevensevenpincode"
                                                                                    v-model="stepseseven.sevenpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseven.sevenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseven.sevenpincode.$error">{{
                                                                                        v$.stepseseven.sevenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="sevenmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="sevenmaplink"
                                                                                    v-model="stepseseven.sevenmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseven.sevenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseven.sevenmaplink.$error">{{
                                                                                        v$.stepseseven.sevenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepSevenbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 7">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eightbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="eightbranchname"
                                                                                    v-model="stepseeight.eightbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeight.eightbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeight.eightbranchname.$error">{{
                                                                                        v$.stepseeight.eightbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eightbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="eightbranchcatagory"
                                                                                    v-model="stepseeight.eightbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeight.eightbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeight.eightbranchcatagory.$error">{{
                                                                                        v$.stepseeight.eightbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="eightsameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepseeight.eightsameaddressstatus" />
                                                                                    <label for="eightsameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepseeight.eightsameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eightstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="eightstate"
                                                                                    v-model="stepseeight.eightstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateEightId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeight.eightstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeight.eightstate.$error">{{
                                                                                        v$.stepseeight.eightstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eightcity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="eightcity"
                                                                                    v-model="stepseeight.eightcity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityEightId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeight.eightcity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeight.eightcity.$error">{{
                                                                                        v$.stepseeight.eightcity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eightarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="eightarea"
                                                                                    v-model="stepseeight.eightarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeight.eightarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeight.eightarea.$error">{{
                                                                                        v$.stepseeight.eightarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="eightaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="eightaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepseeight.eightaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeight.eightaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeight.eightaddress.$error">{{
                                                                                        v$.stepseeight.eightaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepseeighteightpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepEight"
                                                                                    id="stepseeighteightpincode"
                                                                                    v-model="stepseeight.eightpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeight.eightpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeight.eightpincode.$error">{{
                                                                                        v$.stepseeight.eightpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="eightmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="eightmaplink"
                                                                                    v-model="stepseeight.eightmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeight.eightmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeight.eightmaplink.$error">{{
                                                                                        v$.stepseeight.eightmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepEightbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 8">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="ninebranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="ninebranchname"
                                                                                    v-model="stepsenine.ninebranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenine.ninebranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenine.ninebranchname.$error">{{
                                                                                        v$.stepsenine.ninebranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="ninebranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="ninebranchcatagory"
                                                                                    v-model="stepsenine.ninebranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenine.ninebranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenine.ninebranchcatagory.$error">{{
                                                                                        v$.stepsenine.ninebranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="ninesameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsenine.ninesameaddressstatus" />
                                                                                    <label for="ninesameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsenine.ninesameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="ninestate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="ninestate"
                                                                                    v-model="stepsenine.ninestate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateNineId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenine.ninestate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenine.ninestate.$error">{{
                                                                                        v$.stepsenine.ninestate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="ninecity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="ninecity"
                                                                                    v-model="stepsenine.ninecity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityNineId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenine.ninecity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenine.ninecity.$error">{{
                                                                                        v$.stepsenine.ninecity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="ninearea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="ninearea"
                                                                                    v-model="stepsenine.ninearea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenine.ninearea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenine.ninearea.$error">{{
                                                                                        v$.stepsenine.ninearea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="nineaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="nineaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsenine.nineaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenine.nineaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenine.nineaddress.$error">{{
                                                                                        v$.stepsenine.nineaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsenineninepincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepNine"
                                                                                    id="stepsenineninepincode"
                                                                                    v-model="stepsenine.ninepincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenine.ninepincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenine.ninepincode.$error">{{
                                                                                        v$.stepsenine.ninepincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="ninemaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="ninemaplink"
                                                                                    v-model="stepsenine.ninemaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenine.ninemaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenine.ninemaplink.$error">{{
                                                                                        v$.stepsenine.ninemaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepNinebranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 9">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="tenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30" id="tenbranchname"
                                                                                    v-model="stepseten.tenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepseten.tenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseten.tenbranchname.$error">{{
                                                                                        v$.stepseten.tenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="tenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="tenbranchcatagory"
                                                                                    v-model="stepseten.tenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseten.tenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseten.tenbranchcatagory.$error">{{
                                                                                        v$.stepseten.tenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="tensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepseten.tensameaddressstatus" />
                                                                                    <label for="tensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepseten.tensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="tenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="tenstate"
                                                                                    v-model="stepseten.tenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateTenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseten.tenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseten.tenstate.$error">{{
                                                                                        v$.stepseten.tenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="tencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="tencity" v-model="stepseten.tencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityTenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseten.tencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseten.tencity.$error">{{
                                                                                        v$.stepseten.tencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="tenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="tenarea" v-model="stepseten.tenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseten.tenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseten.tenarea.$error">{{
                                                                                        v$.stepseten.tenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="tenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="tenaddress" rows="2"
                                                                                    maxlength="250" placeholder="Enter Registered Address"
                                                                                    v-model="stepseten.tenaddress" autofocus
                                                                                    :class="{ 'p-invalid': submitted && !stepseten.tenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseten.tenaddress.$error">{{
                                                                                        v$.stepseten.tenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsetentenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepTen"
                                                                                    id="stepsetentenpincode" placeholder="Enter Pincode"
                                                                                    v-model="stepseten.tenpincode" autofocus
                                                                                    :class="{ 'p-invalid': submitted && !stepseten.tenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseten.tenpincode.$error">{{
                                                                                        v$.stepseten.tenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="tenmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="tenmaplink"
                                                                                    v-model="stepseten.tenmaplink" autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepseten.tenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseten.tenmaplink.$error">{{
                                                                                        v$.stepseten.tenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepTenbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 10">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="elevenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="elevenbranchname"
                                                                                    v-model="stepseeleven.elevenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeleven.elevenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeleven.elevenbranchname.$error">{{
                                                                                        v$.stepseeleven.elevenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="elevenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="elevenbranchcatagory"
                                                                                    v-model="stepseeleven.elevenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeleven.elevenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeleven.elevenbranchcatagory.$error">{{
                                                                                        v$.stepseeleven.elevenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="elevensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepseeleven.elevensameaddressstatus" />
                                                                                    <label
                                                                                        for="elevensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepseeleven.elevensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="elevenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="elevenstate"
                                                                                    v-model="stepseeleven.elevenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateElevenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeleven.elevenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeleven.elevenstate.$error">{{
                                                                                        v$.stepseeleven.elevenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="elevencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="elevencity"
                                                                                    v-model="stepseeleven.elevencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityElevenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeleven.elevencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeleven.elevencity.$error">{{
                                                                                        v$.stepseeleven.elevencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="elevenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="elevenarea"
                                                                                    v-model="stepseeleven.elevenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeleven.elevenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeleven.elevenarea.$error">{{
                                                                                        v$.stepseeleven.elevenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="elevenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="elevenaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepseeleven.elevenaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeleven.elevenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeleven.elevenaddress.$error">{{
                                                                                        v$.stepseeleven.elevenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepseelevenelevenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepEleven"
                                                                                    id="stepseelevenelevenpincode"
                                                                                    v-model="stepseeleven.elevenpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeleven.elevenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeleven.elevenpincode.$error">{{
                                                                                        v$.stepseeleven.elevenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="elevenmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="elevenmaplink"
                                                                                    v-model="stepseeleven.elevenmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeleven.elevenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeleven.elevenmaplink.$error">{{
                                                                                        v$.stepseeleven.elevenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepElevenbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 11">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twelvebranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="twelvebranchname"
                                                                                    v-model="stepsetwelve.twelvebranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwelve.twelvebranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwelve.twelvebranchname.$error">{{
                                                                                        v$.stepsetwelve.twelvebranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twelvebranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twelvebranchcatagory"
                                                                                    v-model="stepsetwelve.twelvebranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwelve.twelvebranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwelve.twelvebranchcatagory.$error">{{
                                                                                        v$.stepsetwelve.twelvebranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="twelvesameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsetwelve.twelvesameaddressstatus" />
                                                                                    <label
                                                                                        for="twelvesameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsetwelve.twelvesameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twelvestate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twelvestate"
                                                                                    v-model="stepsetwelve.twelvestate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateTwelveId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwelve.twelvestate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwelve.twelvestate.$error">{{
                                                                                        v$.stepsetwelve.twelvestate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twelvecity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="twelvecity"
                                                                                    v-model="stepsetwelve.twelvecity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityTwelveId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwelve.twelvecity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwelve.twelvecity.$error">{{
                                                                                        v$.stepsetwelve.twelvecity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twelvearea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="twelvearea"
                                                                                    v-model="stepsetwelve.twelvearea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwelve.twelvearea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwelve.twelvearea.$error">{{
                                                                                        v$.stepsetwelve.twelvearea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="twelveaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="twelveaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsetwelve.twelveaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwelve.twelveaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwelve.twelveaddress.$error">{{
                                                                                        v$.stepsetwelve.twelveaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsetwelvetwelvepincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepTwelve"
                                                                                    id="stepsetwelvetwelvepincode"
                                                                                    v-model="stepsetwelve.twelvepincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwelve.twelvepincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwelve.twelvepincode.$error">{{
                                                                                        v$.stepsetwelve.twelvepincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="twelvemaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="twelvemaplink"
                                                                                    v-model="stepsetwelve.twelvemaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwelve.twelvemaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwelve.twelvemaplink.$error">{{
                                                                                        v$.stepsetwelve.twelvemaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepTwelvebranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 12">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="thirteenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="thirteenbranchname"
                                                                                    v-model="stepsethirteen.thirteenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethirteen.thirteenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethirteen.thirteenbranchname.$error">{{
                                                                                        v$.stepsethirteen.thirteenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="thirteenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="thirteenbranchcatagory"
                                                                                    v-model="stepsethirteen.thirteenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethirteen.thirteenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethirteen.thirteenbranchcatagory.$error">{{
                                                                                        v$.stepsethirteen.thirteenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="thirteensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsethirteen.thirteensameaddressstatus" />
                                                                                    <label
                                                                                        for="thirteensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsethirteen.thirteensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="thirteenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="thirteenstate"
                                                                                    v-model="stepsethirteen.thirteenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateThirteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethirteen.thirteenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethirteen.thirteenstate.$error">{{
                                                                                        v$.stepsethirteen.thirteenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="thirteencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="thirteencity"
                                                                                    v-model="stepsethirteen.thirteencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityThirteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethirteen.thirteencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethirteen.thirteencity.$error">{{
                                                                                        v$.stepsethirteen.thirteencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="thirteenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="thirteenarea"
                                                                                    v-model="stepsethirteen.thirteenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethirteen.thirteenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethirteen.thirteenarea.$error">{{
                                                                                        v$.stepsethirteen.thirteenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="thirteenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="thirteenaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsethirteen.thirteenaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethirteen.thirteenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethirteen.thirteenaddress.$error">{{
                                                                                        v$.stepsethirteen.thirteenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsethirteenthirteenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepThirteen"
                                                                                    id="stepsethirteenthirteenpincode"
                                                                                    v-model="stepsethirteen.thirteenpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethirteen.thirteenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethirteen.thirteenpincode.$error">{{
                                                                                        v$.stepsethirteen.thirteenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="thirteenmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="thirteenmaplink"
                                                                                    v-model="stepsethirteen.thirteenmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsethirteen.thirteenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsethirteen.thirteenmaplink.$error">{{
                                                                                        v$.stepsethirteen.thirteenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepThirteenbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 13">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourteenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="fourteenbranchname"
                                                                                    v-model="stepsefourteen.fourteenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefourteen.fourteenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefourteen.fourteenbranchname.$error">{{
                                                                                        v$.stepsefourteen.fourteenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourteenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fourteenbranchcatagory"
                                                                                    v-model="stepsefourteen.fourteenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefourteen.fourteenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefourteen.fourteenbranchcatagory.$error">{{
                                                                                        v$.stepsefourteen.fourteenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="fourteensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsefourteen.fourteensameaddressstatus" />
                                                                                    <label
                                                                                        for="fourteensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsefourteen.fourteensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourteenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fourteenstate"
                                                                                    v-model="stepsefourteen.fourteenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateFourteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefourteen.fourteenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefourteen.fourteenstate.$error">{{
                                                                                        v$.stepsefourteen.fourteenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourteencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="fourteencity"
                                                                                    v-model="stepsefourteen.fourteencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityFourteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefourteen.fourteencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefourteen.fourteencity.$error">{{
                                                                                        v$.stepsefourteen.fourteencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourteenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="fourteenarea"
                                                                                    v-model="stepsefourteen.fourteenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefourteen.fourteenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefourteen.fourteenarea.$error">{{
                                                                                        v$.stepsefourteen.fourteenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="fourteenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="fourteenaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsefourteen.fourteenaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefourteen.fourteenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefourteen.fourteenaddress.$error">{{
                                                                                        v$.stepsefourteen.fourteenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsefourteenfourteenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepFourteen"
                                                                                    id="stepsefourteenfourteenpincode"
                                                                                    v-model="stepsefourteen.fourteenpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefourteen.fourteenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefourteen.fourteenpincode.$error">{{
                                                                                        v$.stepsefourteen.fourteenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="fourteenmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="fourteenmaplink"
                                                                                    v-model="stepsefourteen.fourteenmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefourteen.fourteenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefourteen.fourteenmaplink.$error">{{
                                                                                        v$.stepsefourteen.fourteenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepFourteenbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 14">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fifteenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="fifteenbranchname"
                                                                                    v-model="stepsefifteen.fifteenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefifteen.fifteenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefifteen.fifteenbranchname.$error">{{
                                                                                        v$.stepsefifteen.fifteenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fifteenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fifteenbranchcatagory"
                                                                                    v-model="stepsefifteen.fifteenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefifteen.fifteenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefifteen.fifteenbranchcatagory.$error">{{
                                                                                        v$.stepsefifteen.fifteenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="fifteensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsefifteen.fifteensameaddressstatus" />
                                                                                    <label
                                                                                        for="fifteensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsefifteen.fifteensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fifteenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fifteenstate"
                                                                                    v-model="stepsefifteen.fifteenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateFifteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefifteen.fifteenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefifteen.fifteenstate.$error">{{
                                                                                        v$.stepsefifteen.fifteenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fifteencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="fifteencity"
                                                                                    v-model="stepsefifteen.fifteencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityFifteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefifteen.fifteencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefifteen.fifteencity.$error">{{
                                                                                        v$.stepsefifteen.fifteencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fifteenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="fifteenarea"
                                                                                    v-model="stepsefifteen.fifteenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefifteen.fifteenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefifteen.fifteenarea.$error">{{
                                                                                        v$.stepsefifteen.fifteenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="fifteenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="fifteenaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsefifteen.fifteenaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefifteen.fifteenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefifteen.fifteenaddress.$error">{{
                                                                                        v$.stepsefifteen.fifteenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsefifteenfifteenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepFifteen"
                                                                                    id="stepsefifteenfifteenpincode"
                                                                                    v-model="stepsefifteen.fifteenpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefifteen.fifteenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefifteen.fifteenpincode.$error">{{
                                                                                        v$.stepsefifteen.fifteenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="fifteenmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="fifteenmaplink"
                                                                                    v-model="stepsefifteen.fifteenmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsefifteen.fifteenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsefifteen.fifteenmaplink.$error">{{
                                                                                        v$.stepsefifteen.fifteenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepFifteenbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 15">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixteenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="sixteenbranchname"
                                                                                    v-model="stepsesixteen.sixteenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesixteen.sixteenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesixteen.sixteenbranchname.$error">{{
                                                                                        v$.stepsesixteen.sixteenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixteenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="sixteenbranchcatagory"
                                                                                    v-model="stepsesixteen.sixteenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesixteen.sixteenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesixteen.sixteenbranchcatagory.$error">{{
                                                                                        v$.stepsesixteen.sixteenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="sixteensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsesixteen.sixteensameaddressstatus" />
                                                                                    <label
                                                                                        for="sixteensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsesixteen.sixteensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixteenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="sixteenstate"
                                                                                    v-model="stepsesixteen.sixteenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateSixteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesixteen.sixteenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesixteen.sixteenstate.$error">{{
                                                                                        v$.stepsesixteen.sixteenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixteencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="sixteencity"
                                                                                    v-model="stepsesixteen.sixteencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCitySixteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesixteen.sixteencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesixteen.sixteencity.$error">{{
                                                                                        v$.stepsesixteen.sixteencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixteenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="sixteenarea"
                                                                                    v-model="stepsesixteen.sixteenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesixteen.sixteenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesixteen.sixteenarea.$error">{{
                                                                                        v$.stepsesixteen.sixteenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="sixteenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="sixteenaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsesixteen.sixteenaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesixteen.sixteenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesixteen.sixteenaddress.$error">{{
                                                                                        v$.stepsesixteen.sixteenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsesixteensixteenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepSixteen"
                                                                                    id="stepsesixteensixteenpincode"
                                                                                    v-model="stepsesixteen.sixteenpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesixteen.sixteenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesixteen.sixteenpincode.$error">{{
                                                                                        v$.stepsesixteen.sixteenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="sixteenmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="sixteenmaplink"
                                                                                    v-model="stepsesixteen.sixteenmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsesixteen.sixteenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsesixteen.sixteenmaplink.$error">{{
                                                                                        v$.stepsesixteen.sixteenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepSixteenbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 16">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="seventeenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="seventeenbranchname"
                                                                                    v-model="stepseseventeen.seventeenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseventeen.seventeenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseventeen.seventeenbranchname.$error">{{
                                                                                        v$.stepseseventeen.seventeenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="seventeenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="seventeenbranchcatagory"
                                                                                    v-model="stepseseventeen.seventeenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseventeen.seventeenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseventeen.seventeenbranchcatagory.$error">{{
                                                                                        v$.stepseseventeen.seventeenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="seventeensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepseseventeen.seventeensameaddressstatus" />
                                                                                    <label
                                                                                        for="seventeensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepseseventeen.seventeensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="seventeenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="seventeenstate"
                                                                                    v-model="stepseseventeen.seventeenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateSeventeenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseventeen.seventeenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseventeen.seventeenstate.$error">{{
                                                                                        v$.stepseseventeen.seventeenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="seventeencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="seventeencity"
                                                                                    v-model="stepseseventeen.seventeencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCitySeventeenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseventeen.seventeencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseventeen.seventeencity.$error">{{
                                                                                        v$.stepseseventeen.seventeencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="seventeenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="seventeenarea"
                                                                                    v-model="stepseseventeen.seventeenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseventeen.seventeenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseventeen.seventeenarea.$error">{{
                                                                                        v$.stepseseventeen.seventeenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="seventeenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="seventeenaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepseseventeen.seventeenaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseventeen.seventeenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseventeen.seventeenaddress.$error">{{
                                                                                        v$.stepseseventeen.seventeenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label
                                                                                    for="stepseseventeenseventeenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepSeventeen"
                                                                                    id="stepseseventeenseventeenpincode"
                                                                                    v-model="stepseseventeen.seventeenpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseventeen.seventeenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseventeen.seventeenpincode.$error">{{
                                                                                        v$.stepseseventeen.seventeenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="seventeenmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="seventeenmaplink"
                                                                                    v-model="stepseseventeen.seventeenmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepseseventeen.seventeenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseseventeen.seventeenmaplink.$error">{{
                                                                                        v$.stepseseventeen.seventeenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepSeventeennbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 17">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eighteenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="eighteenbranchname"
                                                                                    v-model="stepseeighteen.eighteenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeighteen.eighteenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeighteen.eighteenbranchname.$error">{{
                                                                                        v$.stepseeighteen.eighteenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eighteenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="eighteenbranchcatagory"
                                                                                    v-model="stepseeighteen.eighteenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeighteen.eighteenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeighteen.eighteenbranchcatagory.$error">{{
                                                                                        v$.stepseeighteen.eighteenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="eighteensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepseeighteen.eighteensameaddressstatus" />
                                                                                    <label
                                                                                        for="eighteensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepseeighteen.eighteensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eighteenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="eighteenstate"
                                                                                    v-model="stepseeighteen.eighteenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateEighteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeighteen.eighteenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeighteen.eighteenstate.$error">{{
                                                                                        v$.stepseeighteen.eighteenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eighteencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="eighteencity"
                                                                                    v-model="stepseeighteen.eighteencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityEighteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeighteen.eighteencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeighteen.eighteencity.$error">{{
                                                                                        v$.stepseeighteen.eighteencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eighteenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="eighteenarea"
                                                                                    v-model="stepseeighteen.eighteenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeighteen.eighteenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeighteen.eighteenarea.$error">{{
                                                                                        v$.stepseeighteen.eighteenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="eighteenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="eighteenaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepseeighteen.eighteenaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeighteen.eighteenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeighteen.eighteenaddress.$error">{{
                                                                                        v$.stepseeighteen.eighteenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepseeighteeneighteenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepEighteen"
                                                                                    id="stepseeighteeneighteenpincode"
                                                                                    v-model="stepseeighteen.eighteenpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeighteen.eighteenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeighteen.eighteenpincode.$error">{{
                                                                                        v$.stepseeighteen.eighteenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="eighteenmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="eighteenmaplink"
                                                                                    v-model="stepseeighteen.eighteenmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepseeighteen.eighteenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepseeighteen.eighteenmaplink.$error">{{
                                                                                        v$.stepseeighteen.eighteenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepEighteenbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 18">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="nineteenbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="nineteenbranchname"
                                                                                    v-model="stepsenineteen.nineteenbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenineteen.nineteenbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenineteen.nineteenbranchname.$error">{{
                                                                                        v$.stepsenineteen.nineteenbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="nineteenbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="nineteenbranchcatagory"
                                                                                    v-model="stepsenineteen.nineteenbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenineteen.nineteenbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenineteen.nineteenbranchcatagory.$error">{{
                                                                                        v$.stepsenineteen.nineteenbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="nineteensameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsenineteen.nineteensameaddressstatus" />
                                                                                    <label
                                                                                        for="nineteensameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsenineteen.nineteensameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="nineteenstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="nineteenstate"
                                                                                    v-model="stepsenineteen.nineteenstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateNineteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenineteen.nineteenstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenineteen.nineteenstate.$error">{{
                                                                                        v$.stepsenineteen.nineteenstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="nineteencity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="nineteencity"
                                                                                    v-model="stepsenineteen.nineteencity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityNineteenId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenineteen.nineteencity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenineteen.nineteencity.$error">{{
                                                                                        v$.stepsenineteen.nineteencity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="nineteenarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="nineteenarea"
                                                                                    v-model="stepsenineteen.nineteenarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenineteen.nineteenarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenineteen.nineteenarea.$error">{{
                                                                                        v$.stepsenineteen.nineteenarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="nineteenaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="nineteenaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsenineteen.nineteenaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenineteen.nineteenaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenineteen.nineteenaddress.$error">{{
                                                                                        v$.stepsenineteen.nineteenaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsenineteennineteenpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepNineteen"
                                                                                    id="stepsenineteennineteenpincode"
                                                                                    v-model="stepsenineteen.nineteenpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenineteen.nineteenpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenineteen.nineteenpincode.$error">{{
                                                                                        v$.stepsenineteen.nineteenpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="nineteenmaplink">Google Map Link
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="nineteenmaplink"
                                                                                    v-model="stepsenineteen.nineteenmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsenineteen.nineteenmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsenineteen.nineteenmaplink.$error">{{
                                                                                        v$.stepsenineteen.nineteenmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepNineteenbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 19">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentybranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="twentybranchname"
                                                                                    v-model="stepsetwenty.twentybranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwenty.twentybranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwenty.twentybranchname.$error">{{
                                                                                        v$.stepsetwenty.twentybranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentybranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentybranchcatagory"
                                                                                    v-model="stepsetwenty.twentybranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwenty.twentybranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwenty.twentybranchcatagory.$error">{{
                                                                                        v$.stepsetwenty.twentybranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="twentysameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsetwenty.twentysameaddressstatus" />
                                                                                    <label
                                                                                        for="twentysameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsetwenty.twentysameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentystate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentystate"
                                                                                    v-model="stepsetwenty.twentystate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateTwentyId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwenty.twentystate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwenty.twentystate.$error">{{
                                                                                        v$.stepsetwenty.twentystate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentycity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="twentycity"
                                                                                    v-model="stepsetwenty.twentycity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityTwentyId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwenty.twentycity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwenty.twentycity.$error">{{
                                                                                        v$.stepsetwenty.twentycity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="twentyarea"
                                                                                    v-model="stepsetwenty.twentyarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwenty.twentyarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwenty.twentyarea.$error">{{
                                                                                        v$.stepsetwenty.twentyarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="twentyaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="twentyaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsetwenty.twentyaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwenty.twentyaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwenty.twentyaddress.$error">{{
                                                                                        v$.stepsetwenty.twentyaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="stepsetwentytwentypincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepTwenty"
                                                                                    id="stepsetwentytwentypincode"
                                                                                    v-model="stepsetwenty.twentypincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwenty.twentypincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwenty.twentypincode.$error">{{
                                                                                        v$.stepsetwenty.twentypincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="twentymaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50" id="twentymaplink"
                                                                                    v-model="stepsetwenty.twentymaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwenty.twentymaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwenty.twentymaplink.$error">{{
                                                                                        v$.stepsetwenty.twentymaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepTwentynbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 20">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyonebranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="twentyonebranchname"
                                                                                    v-model="stepsetwentyone.twentyonebranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyone.twentyonebranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyone.twentyonebranchname.$error">{{
                                                                                        v$.stepsetwentyone.twentyonebranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyonebranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentyonebranchcatagory"
                                                                                    v-model="stepsetwentyone.twentyonebranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyone.twentyonebranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyone.twentyonebranchcatagory.$error">{{
                                                                                        v$.stepsetwentyone.twentyonebranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="twentyonesameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsetwentyone.twentyonesameaddressstatus" />
                                                                                    <label
                                                                                        for="twentyonesameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsetwentyone.twentyonesameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyonestate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentyonestate"
                                                                                    v-model="stepsetwentyone.twentyonestate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateTwentyoneId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyone.twentyonestate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyone.twentyonestate.$error">{{
                                                                                        v$.stepsetwentyone.twentyonestate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyonecity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="twentyonecity"
                                                                                    v-model="stepsetwentyone.twentyonecity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityTwentyoneId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyone.twentyonecity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyone.twentyonecity.$error">{{
                                                                                        v$.stepsetwentyone.twentyonecity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyonearea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="twentyonearea"
                                                                                    v-model="stepsetwentyone.twentyonearea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyone.twentyonearea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyone.twentyonearea.$error">{{
                                                                                        v$.stepsetwentyone.twentyonearea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="twentyoneaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="twentyoneaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsetwentyone.twentyoneaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyone.twentyoneaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyone.twentyoneaddress.$error">{{
                                                                                        v$.stepsetwentyone.twentyoneaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label
                                                                                    for="stepsetwentyonetwentyonepincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepTwentyone"
                                                                                    id="stepsetwentyonetwentyonepincode"
                                                                                    v-model="stepsetwentyone.twentyonepincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyone.twentyonepincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyone.twentyonepincode.$error">{{
                                                                                        v$.stepsetwentyone.twentyonepincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="twentyonemaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="twentyonemaplink"
                                                                                    v-model="stepsetwentyone.twentyonemaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyone.twentyonemaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyone.twentyonemaplink.$error">{{
                                                                                        v$.stepsetwentyone.twentyonemaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepTwentyonebranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 21">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentytwobranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="twentytwobranchname"
                                                                                    v-model="stepsetwentytwo.twentytwobranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentytwo.twentytwobranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentytwo.twentytwobranchname.$error">{{
                                                                                        v$.stepsetwentytwo.twentytwobranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentytwobranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentytwobranchcatagory"
                                                                                    v-model="stepsetwentytwo.twentytwobranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentytwo.twentytwobranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentytwo.twentytwobranchcatagory.$error">{{
                                                                                        v$.stepsetwentytwo.twentytwobranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="twentytwosameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsetwentytwo.twentytwosameaddressstatus" />
                                                                                    <label
                                                                                        for="twentytwosameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsetwentytwo.twentytwosameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentytwostate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentytwostate"
                                                                                    v-model="stepsetwentytwo.twentytwostate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateTwentytwoId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentytwo.twentytwostate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentytwo.twentytwostate.$error">{{
                                                                                        v$.stepsetwentytwo.twentytwostate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentytwocity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="twentytwocity"
                                                                                    v-model="stepsetwentytwo.twentytwocity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityTwentytwoId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentytwo.twentytwocity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentytwo.twentytwocity.$error">{{
                                                                                        v$.stepsetwentytwo.twentytwocity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentytwoarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="twentytwoarea"
                                                                                    v-model="stepsetwentytwo.twentytwoarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentytwo.twentytwoarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentytwo.twentytwoarea.$error">{{
                                                                                        v$.stepsetwentytwo.twentytwoarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="twentytwoaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="twentytwoaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsetwentytwo.twentytwoaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentytwo.twentytwoaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentytwo.twentytwoaddress.$error">{{
                                                                                        v$.stepsetwentytwo.twentytwoaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label
                                                                                    for="stepsetwentytwotwentytwopincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepTwentytwo"
                                                                                    id="stepsetwentytwotwentytwopincode"
                                                                                    v-model="stepsetwentytwo.twentytwopincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentytwo.twentytwopincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentytwo.twentytwopincode.$error">{{
                                                                                        v$.stepsetwentytwo.twentytwopincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="twentytwomaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="twentytwomaplink"
                                                                                    v-model="stepsetwentytwo.twentytwomaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentytwo.twentytwomaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentytwo.twentytwomaplink.$error">{{
                                                                                        v$.stepsetwentytwo.twentytwomaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepTwentytwobranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 22">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentythreebranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="twentythreebranchname"
                                                                                    v-model="stepsetwentythree.twentythreebranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentythree.twentythreebranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentythree.twentythreebranchname.$error">{{
                                                                                        v$.stepsetwentythree.twentythreebranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label
                                                                                    for="twentythreebranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentythreebranchcatagory"
                                                                                    v-model="stepsetwentythree.twentythreebranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentythree.twentythreebranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentythree.twentythreebranchcatagory.$error">{{
                                                                                        v$.stepsetwentythree.twentythreebranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="twentythreesameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsetwentythree.twentythreesameaddressstatus" />
                                                                                    <label
                                                                                        for="twentythreesameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsetwentythree.twentythreesameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentythreestate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentythreestate"
                                                                                    v-model="stepsetwentythree.twentythreestate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateTwentythreeId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentythree.twentythreestate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentythree.twentythreestate.$error">{{
                                                                                        v$.stepsetwentythree.twentythreestate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentythreecity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="twentythreecity"
                                                                                    v-model="stepsetwentythree.twentythreecity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityTwentythreeId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentythree.twentythreecity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentythree.twentythreecity.$error">{{
                                                                                        v$.stepsetwentythree.twentythreecity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentythreearea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="twentythreearea"
                                                                                    v-model="stepsetwentythree.twentythreearea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentythree.twentythreearea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentythree.twentythreearea.$error">{{
                                                                                        v$.stepsetwentythree.twentythreearea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="twentythreeaddress">Address
                                                                                    <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="twentythreeaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsetwentythree.twentythreeaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentythree.twentythreeaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentythree.twentythreeaddress.$error">{{
                                                                                        v$.stepsetwentythree.twentythreeaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label
                                                                                    for="stepsetwentythreetwentythreepincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepTwentythree"
                                                                                    id="stepsetwentythreetwentythreepincode"
                                                                                    v-model="stepsetwentythree.twentythreepincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentythree.twentythreepincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentythree.twentythreepincode.$error">{{
                                                                                        v$.stepsetwentythree.twentythreepincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="twentythreemaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="twentythreemaplink"
                                                                                    v-model="stepsetwentythree.twentythreemaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentythree.twentythreemaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentythree.twentythreemaplink.$error">{{
                                                                                        v$.stepsetwentythree.twentythreemaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepTwentythreebranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 23">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfourbranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="twentyfourbranchname"
                                                                                    v-model="stepsetwentyfour.twentyfourbranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfour.twentyfourbranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfour.twentyfourbranchname.$error">{{
                                                                                        v$.stepsetwentyfour.twentyfourbranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfourbranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentyfourbranchcatagory"
                                                                                    v-model="stepsetwentyfour.twentyfourbranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfour.twentyfourbranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfour.twentyfourbranchcatagory.$error">{{
                                                                                        v$.stepsetwentyfour.twentyfourbranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="twentyfoursameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsetwentyfour.twentyfoursameaddressstatus" />
                                                                                    <label
                                                                                        for="twentyfoursameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsetwentyfour.twentyfoursameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfourstate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentyfourstate"
                                                                                    v-model="stepsetwentyfour.twentyfourstate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateTwentyfourId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfour.twentyfourstate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfour.twentyfourstate.$error">{{
                                                                                        v$.stepsetwentyfour.twentyfourstate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfourcity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="twentyfourcity"
                                                                                    v-model="stepsetwentyfour.twentyfourcity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityTwentyfourId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfour.twentyfourcity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfour.twentyfourcity.$error">{{
                                                                                        v$.stepsetwentyfour.twentyfourcity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfourarea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="twentyfourarea"
                                                                                    v-model="stepsetwentyfour.twentyfourarea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfour.twentyfourarea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfour.twentyfourarea.$error">{{
                                                                                        v$.stepsetwentyfour.twentyfourarea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="twentyfouraddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="twentyfouraddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsetwentyfour.twentyfouraddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfour.twentyfouraddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfour.twentyfouraddress.$error">{{
                                                                                        v$.stepsetwentyfour.twentyfouraddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label
                                                                                    for="stepsetwentyfourtwentyfourpincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepTwentyfour"
                                                                                    id="stepsetwentyfourtwentyfourpincode"
                                                                                    v-model="stepsetwentyfour.twentyfourpincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfour.twentyfourpincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfour.twentyfourpincode.$error">{{
                                                                                        v$.stepsetwentyfour.twentyfourpincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="twentyfourmaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="twentyfourmaplink"
                                                                                    v-model="stepsetwentyfour.twentyfourmaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfour.twentyfourmaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfour.twentyfourmaplink.$error">{{
                                                                                        v$.stepsetwentyfour.twentyfourmaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepTwentyfourbranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activebranch == 24">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfivebranchname">
                                                                                    Branch Name<span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="30"
                                                                                    id="twentyfivebranchname"
                                                                                    v-model="stepsetwentyfive.twentyfivebranchname"
                                                                                    class="p-text-capitalize" autofocus placeholder="Enter Branch Name"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfive.twentyfivebranchname }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfive.twentyfivebranchname.$error">{{
                                                                                        v$.stepsetwentyfive.twentyfivebranchname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfivebranchcatagory">Select
                                                                                    Category
                                                                                    <span class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentyfivebranchcatagory"
                                                                                    v-model="stepsetwentyfive.twentyfivebranchcatagory"
                                                                                    :options="hotelcategoryList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfive.twentyfivebranchcatagory }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfive.twentyfivebranchcatagory.$error">{{
                                                                                        v$.stepsetwentyfive.twentyfivebranchcatagory.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field-checkbox p-col-12 p-md-4">
                                                                                <div class="p-mt-4">
                                                                                    <Checkbox class="p-mr-2"
                                                                                        id="twentyfivesameaddressstatus"
                                                                                        name="option" :binary="true"
                                                                                        value="false"
                                                                                        v-model="stepsetwentyfive.twentyfivesameaddressstatus" />
                                                                                    <label
                                                                                        for="twentyfivesameaddressstatus">Same
                                                                                        As Company Registered
                                                                                        Address</label>
                                                                                    <span class="p-ml-2"><i
                                                                                            class="pi pi-info-circle indigo-color cursor-pointer custom-info-icon"
                                                                                            v-tooltip.top="'View Company Details'"
                                                                                            aria:haspopup="true"
                                                                                            aria-controls="overlay_panel"
                                                                                            @click="toggleCTNumberList1($event)"
                                                                                            style="font-size: 1rem"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid"
                                                                        v-if="stepsetwentyfive.twentyfivesameaddressstatus == false">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <!-- <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfivestate">State <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="twentyfivestate"
                                                                                    v-model="stepsetwentyfive.twentyfivestate"
                                                                                    :options="stateList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getCityByStateTwentyfiveId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfive.twentyfivestate }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfive.twentyfivestate.$error">{{
                                                                                        v$.stepsetwentyfive.twentyfivestate.$errors[0].$message
                                                                                    }}</small>
                                                                            </div> -->
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfivecity">City <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!cityloaderflag"
                                                                                    id="twentyfivecity"
                                                                                    v-model="stepsetwentyfive.twentyfivecity"
                                                                                    :options="cityList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    @change="getAreaByCityTwentyfiveId($event)"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfive.twentyfivecity }" />
                                                                                <Dropdown v-show="cityloaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfive.twentyfivecity.$error">{{
                                                                                        v$.stepsetwentyfive.twentyfivecity.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="twentyfivearea">Area <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown v-show="!arealoaderflag"
                                                                                    id="twentyfivearea"
                                                                                    v-model="stepsetwentyfive.twentyfivearea"
                                                                                    :options="areaList" optionLabel="label"
                                                                                    autofocus :filter="true"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfive.twentyfivearea }" />
                                                                                <Dropdown v-show="arealoaderflag"
                                                                                    :searchable="false"
                                                                                    placeholder="Loading..." loading />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfive.twentyfivearea.$error">{{
                                                                                        v$.stepsetwentyfive.twentyfivearea.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-8">
                                                                                <label for="twentyfiveaddress">Address <span
                                                                                        class="p-invalid p-error">*</span></label>
                                                                                <Textarea id="twentyfiveaddress" rows="2"
                                                                                    maxlength="250"
                                                                                    v-model="stepsetwentyfive.twentyfiveaddress"
                                                                                    autofocus placeholder="Enter Registered Address"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfive.twentyfiveaddress }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfive.twentyfiveaddress.$error">{{
                                                                                        v$.stepsetwentyfive.twentyfiveaddress.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label
                                                                                    for="stepsetwentyfivetwentyfivepincode">
                                                                                    Pincode <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText minlength="6" maxlength="6"
                                                                                    @keypress="pincodeFormatSecondStepTwentyfive"
                                                                                    id="stepsetwentyfivetwentyfivepincode"
                                                                                    v-model="stepsetwentyfive.twentyfivepincode"
                                                                                    autofocus placeholder="Enter Pincode"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfive.twentyfivepincode }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfive.twentyfivepincode.$error">{{
                                                                                        v$.stepsetwentyfive.twentyfivepincode.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-12">
                                                                                <label for="twentyfivemaplink">
                                                                                    Google Map Link <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <InputText maxlength="50"
                                                                                    id="twentyfivemaplink"
                                                                                    v-model="stepsetwentyfive.twentyfivemaplink"
                                                                                    autofocus placeholder="Enter Google Map Link"
                                                                                    :class="{ 'p-invalid': submitted && !stepsetwentyfive.twentyfivemaplink }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepsetwentyfive.twentyfivemaplink.$error">{{
                                                                                        v$.stepsetwentyfive.twentyfivemaplink.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-fluid">
                                                                        <div
                                                                            class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row">
                                                                            <Button size="small" label="Back"
                                                                                icon="pi pi-arrow-circle-left"
                                                                                iconPos="left"
                                                                                @click="backToWizardStepOnebranchBtn"
                                                                                style="width: 80px" />
                                                                            <Button v-if="!showsecstepLoader" size="small"
                                                                                label="Save & Next"
                                                                                icon="pi pi-arrow-circle-right"
                                                                                iconPos="right"
                                                                                @click="secondWizardStepTwentyfivebranchBtn"
                                                                                style="width: 130px" />
                                                                            <Button v-if="showsecstepLoader" size="small"
                                                                                icon="pi pi-spin pi-spinner indigo-color"
                                                                                class="p-button-text"
                                                                                style="width: 130px"></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-fluid p-formgrid p-text-left p-pt-3"
                                            v-if="stepse.branchescount == ''">
                                            <Button size="small" label="Back" icon="pi pi-arrow-circle-left" iconPos="left"
                                                @click="backToWizardStepOnebranchBtn" style="width: 80px" />
                                        </div>
                                    </div>
                                </div>
                                <div class="p-fluid p-formgrid comman-overflow-scroll-outer" v-if="active == 2"
                                    style="height: calc(100vh - 290px)">
                                    <div class="p-px-3">
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class="p-col-12 p-md-12 ">
                                                <div class="flex ">
                                                    <div class="gap-2 flex flex-column justify-content-start p-pt-3">
                                                        <Button @click="activeuser = 0" rounded label="User 1"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 0"
                                                            v-show="activeuser >= 0 || userTabActiveFlag != ''" />
                                                        <Button @click="activeuser = 1" rounded label="User 2"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 1"
                                                            v-show="activeuser >= 1 || userTabActiveFlag >= 1" />
                                                        <Button @click="activeuser = 2" rounded label="User 3"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 2"
                                                            v-show="activeuser >= 2 || userTabActiveFlag == 2" />
                                                        <Button @click="activeuser = 3" rounded label="User 4"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 3"
                                                            v-show="activeuser >= 3 || userTabActiveFlag == 3" />

                                                        <Button @click="activeuser = 4" rounded label="User 5"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 4"
                                                            v-show="activeuser >= 4 || userTabActiveFlag == 4" />

                                                        <Button @click="activeuser = 5" rounded label="User 6"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 5"
                                                            v-show="activeuser >= 5 || userTabActiveFlag == 5" />

                                                        <Button @click="activeuser = 6" rounded label="User 7"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 6"
                                                            v-show="activeuser >= 6 || userTabActiveFlag == 6" />

                                                        <Button @click="activeuser = 7" rounded label="User 8"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 7"
                                                            v-show="activeuser >= 7 || userTabActiveFlag == 7" />

                                                        <Button @click="activeuser = 8" rounded label="User 9"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 8"
                                                            v-show="activeuser >= 8 || userTabActiveFlag == 8" />

                                                        <Button @click="activeuser = 9" rounded label="User 10"
                                                            class="h-2rem p-0 p-mr-3 custom-border-radius"
                                                            :outlined="activeuser !== 9"
                                                            v-show="activeuser >= 9 || userTabActiveFlag == 9" />
                                                    </div>
                                                    <Card class="p-ml-3 p-mb-1" style="width:100%">
                                                        <template #content>
                                                            <div v-if="activeuser == 0">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="firstusertitle"
                                                                                            v-model="stepthone.firstusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthone.firstusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="firstuserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="stepthone.firstuserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthone.firstuserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthone.firstusertitle.$error">{{
                                                                                        v$.stepthone.firstusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="stepthone.firstusertitle != '' && v$.stepthone.firstuserfullname.$error">{{
                                                                                        v$.stepthone.firstuserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileFirstUser"
                                                                                            id="firstusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="stepthone.firstusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthone.firstusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthone.firstusermobileno.$error">{{
                                                                                        v$.stepthone.firstusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstuserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="firstuserbranches"
                                                                                    v-model="stepthone.firstuserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthone.firstuserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthone.firstuserbranches.$error">{{
                                                                                        v$.stepthone.firstuserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistFirstUser">
                                                                                <label for="firstuserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="firstuserdesignation"
                                                                                    v-model="stepthone.firstuserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Designation Not found?,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationFirstUser">Click
                                                                                            here to add new</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistFirstUser">
                                                                                <label for="firstuserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameFirstUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationFirstUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstuserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="firstuserrole"
                                                                                    v-model="stepthone.firstuserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthone.firstuserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthone.firstuserrole.$error">{{
                                                                                        v$.stepthone.firstuserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 0">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Add More User"
                                                                                    @click="addMoreSecondRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-plus" severity="info"
                                                                                    outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreSecondRowSubmit()"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activeuser == 1">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="secondusertitle"
                                                                                            v-model="stepthtwo.secondusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthtwo.secondusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="seconduserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="stepthtwo.seconduserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthtwo.seconduserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthtwo.secondusertitle.$error">{{
                                                                                        v$.stepthtwo.secondusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="stepthtwo.secondusertitle != '' && v$.stepthtwo.seconduserfullname.$error">{{
                                                                                        v$.stepthtwo.seconduserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileSecondUser"
                                                                                            id="secondusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="stepthtwo.secondusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthtwo.secondusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthtwo.secondusermobileno.$error">{{
                                                                                        v$.stepthtwo.secondusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="seconduserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="seconduserbranches"
                                                                                    v-model="stepthtwo.seconduserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthtwo.seconduserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthtwo.seconduserbranches.$error">{{
                                                                                        v$.stepthtwo.seconduserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistSecondUser">
                                                                                <label for="seconduserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="seconduserdesignation"
                                                                                    v-model="stepthtwo.seconduserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Not found,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationSecondUser">Click
                                                                                            here for create</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistSecondUser">
                                                                                <label for="seconduserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameSecondUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationSecondUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="seconduserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="seconduserrole"
                                                                                    v-model="stepthtwo.seconduserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthtwo.seconduserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthtwo.seconduserrole.$error">{{
                                                                                        v$.stepthtwo.seconduserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 1">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Remove User"
                                                                                    @click="removeSecondRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-trash" severity="danger"
                                                                                    class="p-mr-2" outlined />
                                                                                <Button label="Add More User"
                                                                                    @click="addMoreThirdRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-plus" severity="info"
                                                                                    outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreThirdRowSubmit"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activeuser == 2">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="thirdusertitle"
                                                                                            v-model="stepththree.thirdusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepththree.thirdusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="thirduserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="stepththree.thirduserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepththree.thirduserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepththree.thirdusertitle.$error">{{
                                                                                        v$.stepththree.thirdusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="stepththree.thirdusertitle != '' && v$.stepththree.thirduserfullname.$error">{{
                                                                                        v$.stepththree.thirduserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileThirdUser"
                                                                                            id="thirdusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="stepththree.thirdusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepththree.thirdusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepththree.thirdusermobileno.$error">{{
                                                                                        v$.stepththree.thirdusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="thirduserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="thirduserbranches"
                                                                                    v-model="stepththree.thirduserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepththree.thirduserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepththree.thirduserbranches.$error">{{
                                                                                        v$.stepththree.thirduserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistThirdUser">
                                                                                <label for="thirduserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="thirduserdesignation"
                                                                                    v-model="stepththree.thirduserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Not found,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationThirdUser">Click
                                                                                            here for create</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistThirdUser">
                                                                                <label for="thirduserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameThirdUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationThirdUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="thirduserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="thirduserrole"
                                                                                    v-model="stepththree.thirduserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepththree.thirduserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepththree.thirduserrole.$error">{{
                                                                                        v$.stepththree.thirduserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 2">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Remove User"
                                                                                    @click="removeThirdRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-trash" severity="danger"
                                                                                    class="p-mr-2" outlined />
                                                                                <Button label="Add More User"
                                                                                    @click="addMoreFourthRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-plus" severity="info"
                                                                                    outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreFourRowSubmit"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activeuser == 3">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="fourthusertitle"
                                                                                            v-model="stepthfour.fourthusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthfour.fourthusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="fourthuserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="stepthfour.fourthuserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthfour.fourthuserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthfour.fourthusertitle.$error">{{
                                                                                        v$.stepthfour.fourthusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="stepthfour.fourthusertitle != '' && v$.stepthfour.fourthuserfullname.$error">{{
                                                                                        v$.stepthfour.fourthuserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileFourthUser"
                                                                                            id="fourthusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="stepthfour.fourthusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthfour.fourthusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthfour.fourthusermobileno.$error">{{
                                                                                        v$.stepthfour.fourthusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourthuserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fourthuserbranches"
                                                                                    v-model="stepthfour.fourthuserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthfour.fourthuserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthfour.fourthuserbranches.$error">{{
                                                                                        v$.stepthfour.fourthuserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistFourthUser">
                                                                                <label for="fourthuserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="fourthuserdesignation"
                                                                                    v-model="stepthfour.fourthuserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Not found,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationFourthUser">Click
                                                                                            here for create</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistFourthUser">
                                                                                <label for="fourthuserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameFourthUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationFourthUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fourthuserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fourthuserrole"
                                                                                    v-model="stepthfour.fourthuserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthfour.fourthuserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthfour.fourthuserrole.$error">{{
                                                                                        v$.stepthfour.fourthuserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 3">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Remove User"
                                                                                    @click="removeFourthRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-trash" severity="danger"
                                                                                    class="p-mr-2" outlined />
                                                                                <Button label="Add More User"
                                                                                    @click="addMoreFifthRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-plus" severity="info"
                                                                                    outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreFifthRowSubmit"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activeuser == 4">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="fifthusertitle"
                                                                                            v-model="stepthfive.fifthusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthfive.fifthusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="fifthuserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="stepthfive.fifthuserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthfive.fifthuserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthfive.fifthusertitle.$error">{{
                                                                                        v$.stepthfive.fifthusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="stepthfive.fifthusertitle != '' && v$.stepthfive.fifthuserfullname.$error">{{
                                                                                        v$.stepthfive.fifthuserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileFifthUser"
                                                                                            id="fifthusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="stepthfive.fifthusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthfive.fifthusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthfive.fifthusermobileno.$error">{{
                                                                                        v$.stepthfive.fifthusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fifthuserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fifthuserbranches"
                                                                                    v-model="stepthfive.fifthuserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthfive.fifthuserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthfive.fifthuserbranches.$error">{{
                                                                                        v$.stepthfive.fifthuserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistFifthUser">
                                                                                <label for="fifthuserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="fifthuserdesignation"
                                                                                    v-model="stepthfive.fifthuserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Not found,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationFifthUser">Click
                                                                                            here for create</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistFifthUser">
                                                                                <label for="fifthuserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameFifthUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationFifthUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fifthuserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="fifthuserrole"
                                                                                    v-model="stepthfive.fifthuserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthfive.fifthuserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthfive.fifthuserrole.$error">{{
                                                                                        v$.stepthfive.fifthuserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 4">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Remove User"
                                                                                    @click="removeFifthRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-trash" severity="danger"
                                                                                    class="p-mr-2" outlined />
                                                                                <Button label="Add More User"
                                                                                    @click="addMoreSixRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-plus" severity="info"
                                                                                    outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreSixRowSubmit"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activeuser == 5">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="sixthusertitle"
                                                                                            v-model="stepthsix.sixthusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthsix.sixthusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="sixthuserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="stepthsix.sixthuserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthsix.sixthuserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthsix.sixthusertitle.$error">{{
                                                                                        v$.stepthsix.sixthusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="stepthsix.sixthusertitle != '' && v$.stepthsix.sixthuserfullname.$error">{{
                                                                                        v$.stepthsix.sixthuserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileSixthUser"
                                                                                            id="sixthusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="stepthsix.sixthusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthsix.sixthusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthsix.sixthusermobileno.$error">{{
                                                                                        v$.stepthsix.sixthusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixthuserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="sixthuserbranches"
                                                                                    v-model="stepthsix.sixthuserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthsix.sixthuserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthsix.sixthuserbranches.$error">{{
                                                                                        v$.stepthsix.sixthuserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistSixthUser">
                                                                                <label for="sixthuserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="sixthuserdesignation"
                                                                                    v-model="stepthsix.sixthuserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Not found,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationsixthUser">Click
                                                                                            here for create</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistSixthUser">
                                                                                <label for="sixthuserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameSixthUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationSixthUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="sixthuserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="sixthuserrole"
                                                                                    v-model="stepthsix.sixthuserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthsix.sixthuserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthsix.sixthuserrole.$error">{{
                                                                                        v$.stepthsix.sixthuserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 5">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Remove User"
                                                                                    @click="removeSixthRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-trash" severity="danger"
                                                                                    class="p-mr-2" outlined />
                                                                                <Button label="Add More User"
                                                                                    @click="addMoreSevenRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-plus" severity="info"
                                                                                    outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreSevenRowSubmit"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activeuser == 6">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="seventhusertitle"
                                                                                            v-model="stepthseven.seventhusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthseven.seventhusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="seventhuserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="stepthseven.seventhuserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthseven.seventhuserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthseven.seventhusertitle.$error">{{
                                                                                        v$.stepthseven.seventhusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="stepthseven.seventhusertitle != '' && v$.stepthseven.seventhuserfullname.$error">{{
                                                                                        v$.stepthseven.seventhuserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileSeventhUser"
                                                                                            id="seventhusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="stepthseven.seventhusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthseven.seventhusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthseven.seventhusermobileno.$error">{{
                                                                                        v$.stepthseven.seventhusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="seventhuserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="seventhuserbranches"
                                                                                    v-model="stepthseven.seventhuserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthseven.seventhuserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthseven.seventhuserbranches.$error">{{
                                                                                        v$.stepthseven.seventhuserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistSeventhUser">
                                                                                <label for="seventhuserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="seventhuserdesignation"
                                                                                    v-model="stepthseven.seventhuserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Not found,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationSeventhUser">Click
                                                                                            here for create</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistSeventhUser">
                                                                                <label for="seventhuserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameSeventhUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationSeventhUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="seventhuserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="seventhuserrole"
                                                                                    v-model="stepthseven.seventhuserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthseven.seventhuserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthseven.seventhuserrole.$error">{{
                                                                                        v$.stepthseven.seventhuserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 6">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Remove User"
                                                                                    @click="removeSeventhRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-trash" severity="danger"
                                                                                    class="p-mr-2" outlined />
                                                                                <Button label="Add More User"
                                                                                    @click="addMoreEightRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-plus" severity="info"
                                                                                    outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreEightRowSubmit"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activeuser == 7">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="eighthusertitle"
                                                                                            v-model="steptheight.eighthusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !steptheight.eighthusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="eighthuserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="steptheight.eighthuserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !steptheight.eighthuserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.steptheight.eighthusertitle.$error">{{
                                                                                        v$.steptheight.eighthusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="steptheight.eighthusertitle != '' && v$.steptheight.eighthuserfullname.$error">{{
                                                                                        v$.steptheight.eighthuserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileEighthUser"
                                                                                            id="eighthusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="steptheight.eighthusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !steptheight.eighthusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.steptheight.eighthusermobileno.$error">{{
                                                                                        v$.steptheight.eighthusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eighthuserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="eighthuserbranches"
                                                                                    v-model="steptheight.eighthuserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !steptheight.eighthuserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.steptheight.eighthuserbranches.$error">{{
                                                                                        v$.steptheight.eighthuserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistEighthUser">
                                                                                <label for="eighthuserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="eighthuserdesignation"
                                                                                    v-model="steptheight.eighthuserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Not found,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationEighthUser">Click
                                                                                            here for create</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistEighthUser">
                                                                                <label for="eighthuserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameEighthUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationEighthUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="eighthuserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="eighthuserrole"
                                                                                    v-model="steptheight.eighthuserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !steptheight.eighthuserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.steptheight.eighthuserrole.$error">{{
                                                                                        v$.steptheight.eighthuserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 7">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Remove User"
                                                                                    @click="removeEighthRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-trash" severity="danger"
                                                                                    class="p-mr-2" outlined />
                                                                                <Button label="Add More User"
                                                                                    @click="addMoreNineRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-plus" severity="info"
                                                                                    outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreNineRowSubmit"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activeuser == 8">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="ninethusertitle"
                                                                                            v-model="stepthnine.ninethusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthnine.ninethusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="ninethuserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="stepthnine.ninethuserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthnine.ninethuserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthnine.ninethusertitle.$error">{{
                                                                                        v$.stepthnine.ninethusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="stepthnine.ninethusertitle != '' && v$.stepthnine.ninethuserfullname.$error">{{
                                                                                        v$.stepthnine.ninethuserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileNinethUser"
                                                                                            id="ninethusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="stepthnine.ninethusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthnine.ninethusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthnine.ninethusermobileno.$error">{{
                                                                                        v$.stepthnine.ninethusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="ninethuserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="ninethuserbranches"
                                                                                    v-model="stepthnine.ninethuserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthnine.ninethuserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthnine.ninethuserbranches.$error">{{
                                                                                        v$.stepthnine.ninethuserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistNinethUser">
                                                                                <label for="ninethuserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="ninethuserdesignation"
                                                                                    v-model="stepthnine.ninethuserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Not found,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationNinethUser">Click
                                                                                            here for create</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistNinethUser">
                                                                                <label for="ninethuserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameNinethUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationNinethUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="ninethuserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="ninethuserrole"
                                                                                    v-model="stepthnine.ninethuserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthnine.ninethuserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthnine.ninethuserrole.$error">{{
                                                                                        v$.stepthnine.ninethuserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 8">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Remove User"
                                                                                    @click="removeNinethRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-trash" severity="danger"
                                                                                    class="p-mr-2" outlined />
                                                                                <Button label="Add More User"
                                                                                    @click="addMoreTenRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-plus" severity="info"
                                                                                    outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreTenRowSubmit"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="activeuser == 9">
                                                                <div class="p-fluid p-col-12 p-md-12">
                                                                    <div class="p-fluid">
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="fullname">
                                                                                    Name <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="tenthusertitle"
                                                                                            v-model="stepthten.tenthusertitle"
                                                                                            :options="titleList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthten.tenthusertitle }"
                                                                                            style="border: 0;" />
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText id="tenthuserfullname"
                                                                                            @keypress="isLetterWithSpace($event)"
                                                                                            maxlength="30" placeholder="Enter Name"
                                                                                            class="p-text-capitalize"
                                                                                            v-model="stepthten.tenthuserfullname"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthten.tenthuserfullname }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthten.tenthusertitle.$error">{{
                                                                                        v$.stepthten.tenthusertitle.$errors[0].$message
                                                                                    }}</small>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="stepthten.tenthusertitle != '' && v$.stepthten.tenthuserfullname.$error">{{
                                                                                        v$.stepthten.tenthuserfullname.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="firstusermobileno">
                                                                                    Mobile Number <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <div class="p-inputgroup p-field mb-0">
                                                                                    <span class="p-inputgroup-addon"
                                                                                        style="padding: 0; min-width: 100px;">
                                                                                        <Dropdown id="country"
                                                                                            v-model="countrycode"
                                                                                            :options="countryCodeList"
                                                                                            optionLabel="label"
                                                                                            placeholder="Select..."
                                                                                            :disabled="true" appendTo="body"
                                                                                            style="border: 0;">
                                                                                        </Dropdown>
                                                                                    </span>
                                                                                    <span class="p-float-label">
                                                                                        <InputText maxlength="10"
                                                                                            @keypress="onlyNumberMobileTenthUser"
                                                                                            id="tenthusermobileno" placeholder="Enter Mobile Number"
                                                                                            v-model="stepthten.tenthusermobileno"
                                                                                            autofocus
                                                                                            :class="{ 'p-invalid': submitted && !stepthten.tenthusermobileno }" />
                                                                                    </span>
                                                                                </div>
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthten.tenthusermobileno.$error">{{
                                                                                        v$.stepthten.tenthusermobileno.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="tenthuserbranches">
                                                                                    Select Branch <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="tenthuserbranches"
                                                                                    v-model="stepthten.tenthuserbranches"
                                                                                    :options="branchList"
                                                                                    optionLabel="label" autofocus
                                                                                    :filter="false" placeholder="Select..."
                                                                                    appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthten.tenthuserbranches }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthten.tenthuserbranches.$error">{{
                                                                                        v$.stepthten.tenthuserbranches.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid">
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="!isDesignationExistTenthUser">
                                                                                <label for="tenthuserdesignation">
                                                                                    Designation
                                                                                </label>
                                                                                <Dropdown id="tenthuserdesignation"
                                                                                    v-model="stepthten.tenthuserdesignation"
                                                                                    :options="designationList"
                                                                                    optionLabel="label" :filter="true"
                                                                                    placeholder="Select..."
                                                                                    appendTo="body" />
                                                                                <label class="p-mt-1">
                                                                                    Not found,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationTenthUser">Click
                                                                                            here for create</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4"
                                                                                v-show="isDesignationExistTenthUser">
                                                                                <label for="tenthuserdesignation">
                                                                                    Create Designation
                                                                                </label>
                                                                                <InputText class="p-text-capitalize"
                                                                                    @input="createOtherDesignationNameTenthUser"
                                                                                    placeholder="Enter Designation" />
                                                                                <label class="p-mt-1">
                                                                                    For search,
                                                                                    <span>
                                                                                        <a class="p-ai-center p-text-right cursor-pointer"
                                                                                            style="color: #3f51b5"
                                                                                            @click="toggleDesignationTenthUser">Click
                                                                                            here</a>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="p-field p-col-12 p-md-4">
                                                                                <label for="tenthuserrole">
                                                                                    Select the Best Suitable Role? <span
                                                                                        class="p-invalid p-error">*</span>
                                                                                </label>
                                                                                <Dropdown id="tenthuserrole"
                                                                                    v-model="stepthten.tenthuserrole"
                                                                                    :options="roleList" optionLabel="label"
                                                                                    autofocus :filter="false"
                                                                                    placeholder="Select..." appendTo="body"
                                                                                    :class="{ 'p-invalid': submitted && !stepthten.tenthuserrole }" />
                                                                                <small class="p-invalid p-error"
                                                                                    v-if="v$.stepthten.tenthuserrole.$error">{{
                                                                                        v$.stepthten.tenthuserrole.$errors[0].$message
                                                                                    }}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid p-formgrid p-grid"
                                                                            v-show="userTabActiveFlag <= 8">
                                                                            <div
                                                                                class="p-field p-col-12 p-md-12 p-text-right">
                                                                                <Button label="Remove User"
                                                                                    @click="removeTenthRow()"
                                                                                    style="width: 140px" size="small"
                                                                                    icon="pi pi-trash" severity="danger"
                                                                                    class="p-mr-2" outlined />
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-fluid">
                                                                            <div
                                                                                class="p-fluid p-formgrid p-d-flex p-jc-between p-flex-row p-mt-5">
                                                                                <Button size="small" label="Back"
                                                                                    icon="pi pi-arrow-circle-left"
                                                                                    iconPos="left"
                                                                                    @click="backToWizardStepTwobranchBtn"
                                                                                    style="width: 80px" />
                                                                                <Button v-if="!showthirdstepLoader"
                                                                                    size="small" label="Save & Finish"
                                                                                    icon="pi pi-arrow-circle-right"
                                                                                    iconPos="right"
                                                                                    @click="addMoreElevenRowSubmit"
                                                                                    style="width: 130px" />
                                                                                <Button v-if="showthirdstepLoader"
                                                                                    size="small"
                                                                                    icon="pi pi-spin pi-spinner indigo-color"
                                                                                    class="p-button-text"
                                                                                    style="width: 130px"></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-fluid p-formgrid comman-overflow-scroll-outer" v-if="active == 3"
                                    style="height: calc(100vh - 290px)">
                                    <div class="p-px-3">
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div
                                                class="p-col-12 p-md-12">
                                                <div class="p-mb-2 p-text-center">
                                                    <img src="/assets/images/thank-you.gif" alt="thankyou" width="80" />
                                                </div>
                                                <div class="thank-you-label p-text-center p-mt-2">Congratulations!</div>
                                                <div class="thank-you-second-label p-text-center p-mb-3">Your registration as the Host for the Goa Government Digitization program has been successfully completed.</div>
                                                <div class="thank-you-third-label p-text-center p-mb-4">The "Visitor Connect" web application will take you there, where you may automate the check-in and check-out procedures for your guests.</div>
                                                <div class="thank-you-third-label p-text-center">Also included below are the login credentials:</div>
                                                <div class="p-d-flex p-flex-column p-ai-center justify-content-center">
                                                    <div class="voter-exit-box-outer">
                                                    <div class="thank-you-fourth-label p-text-left"><span class="thank-you-third-label p-mr-2">Login URL :</span>http://demo.visitor-connect.in/</div>
                                                    <div class="thank-you-fourth-label p-text-left"><span class="thank-you-third-label p-mr-2">Login Id :</span>{{this.hostusername}}</div>
                                                    <div class="thank-you-fourth-label p-text-left"><span class="thank-you-third-label p-mr-2">Password :</span>{{this.hostuserpassword}}</div>
                                                    </div>
                                                </div>
                                                <div class="p-mt-3 p-text-center p-fluid p-formgrid p-grid">
                                                    <div class="p-field p-col-12 p-md-5 p-pt-4">
                                                        <Button size="small" label="Click To Login"
                                                            @click="redirectToLogin()" style="width: 130px" />
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-2">
                                                        <Divider layout="vertical" align="center"><b>OR</b></Divider>
                                                    </div>
                                                    <div class="p-field p-col-12 p-md-5">
                                                        <div class="thank-you-third-label p-text-center p-mb-3">Download Visitor Connect App</div>
                                                        <a href="#" target="_blank" class="mobile-app-link-btn p-mr-3"><img
                                                                alt="playstore" src="/assets//images/playstore-image.svg"
                                                                class="image-header-right" width="150px" /></a>
                                                        <a href="#" target="_blank" class="mobile-app-link-btn"><img
                                                                alt="appstore" src="/assets//images/appstore-image.svg"
                                                                class="image-header-right" width="150px" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Card>
                    </div>
                </template>
                <template #footer>
                    <div class="flex justify-content-center">
                        <div class="copyright-label">Designed & Developed by <a href="#" target="_blank"
                                class="copyright-linkbtn">Samruddh Bharat Tech Private Limited</a></div>
                    </div>
                </template>
            </Card>
        </div>
    </div>
    <OverlayPanel ref="op1" class="custom-overlay-jobpost-outer" appendTo="body" :showCloseIcon="true" id="overlay_panel"
        style="width: auto; max-width: 900px" :breakpoints="{ '960px': '75vw' }">
        <div class="p-grid">
            <div class="p-md-6">
                <small class="p-text-capitalize"><span class="p-text-bold">Brand
                        Name : </span>{{
                            this.brandnamehostvalue
                        }}</small>
            </div>
            <div class="p-md-6">
                <small class="p-text-capitalize"><span class="p-text-bold">Legal Name : </span>{{
                    this.legalnamehostvalue
                }}</small>
            </div>
            <div class="p-md-6">
                <small class="p-text-capitalize"><span class="p-text-bold">Entity
                        Type : </span>{{
                            this.entitytypehostvalue
                        }}</small>
            </div>
            <Divider class="p-my-2" />
            <div class="p-md-4" v-if="this.statehostvalue">
                <small class="p-text-capitalize"><span class="p-text-bold">State
                        : </span>{{
                            this.statehostvalue
                        }}</small>
            </div>
            <div class="p-md-4" v-if="this.cityhostvalue">
                <small class="p-text-capitalize"><span class="p-text-bold">City
                        : </span>{{
                            this.cityhostvalue
                        }}</small>
            </div>
            <div class="p-md-4" v-if="this.areahostvalue">
                <small class="p-text-capitalize"><span class="p-text-bold">Area
                        : </span>{{
                            this.areahostvalue
                        }}</small>
            </div>
            <div class="p-md-8">
                <small class="p-text-capitalize"><span class="p-text-bold">Address
                        : </span>{{
                            this.addresshostvalue
                        }}</small>
            </div>
            <div class="p-md-4" v-if="this.pincodehostvalue">
                <small class="p-text-capitalize"><span class="p-text-bold">Pincode
                        : </span>{{
                            this.pincodehostvalue
                        }}</small>
            </div>
            <div class="p-md-12">
                <small><span class="p-text-bold">Google
                        Map Link : </span>{{
                            this.maplinkhostvalue
                        }}</small>
            </div>
            <Divider class="p-my-2" />
            <div class="p-md-6" v-if="this.gstnumberhostvalue">
                <small class="p-text-capitalize"><span class="p-text-bold">GST
                        No. : </span><span class="p-text-uppercase">{{
                            this.gstnumberhostvalue
                        }}</span></small>
            </div>
            <div class="p-md-6" v-if="this.pannumberhostvalue">
                <small class="p-text-capitalize"><span class="p-text-bold">PAN
                        No. : </span><span class="p-text-uppercase">{{
                            this.pannumberhostvalue
                        }}</span></small>
            </div>
        </div>
    </OverlayPanel>
</template>

<script>
import useValidate from '@vuelidate/core';
import ApiService from "../service/ApiService";
import { required, requiredIf, minLength, maxLength, url, numeric, helpers } from '@vuelidate/validators';
import EventService from '../service/EventService';
export default {
    data() {
        return {
            v$: useValidate(),
            guildelineactive: false,
            active: 0,
            items: [
                {
                    label: 'Host Details'
                },
                {
                    label: 'Branch Details'
                },
                {
                    label: 'Users'
                }
            ],
            countrycode: { value: 101, label: '+91' },
            stepopen: {
                title: { value: 1, label: 'Mr.' },
                fullname: '',
                mobileno: '',
            },
            isDesignationExist: false,
            isDesignationExistFirstUser: false,
            isDesignationExistSecondUser: false,
            isDesignationExistThirdUser: false,
            isDesignationExistFourthUser: false,
            isDesignationExistFifthUser: false,
            isDesignationExistSixthUser: false,
            isDesignationExistSeventhUser: false,
            isDesignationExistEighthUser: false,
            isDesignationExistNinethUser: false,
            isDesignationExistTenthUser: false,
            stepf: {
                designation: '',
                brandname: '',
                isgstnumberflag: '',
                legalname: '',
                entitytype: '',
                city: '',
                area: '',
                state: { value: 11, label: 'Goa' },
                address: '',
                pincode: '',
                maplink: '',
                gstnumber: '',
                pannumber: '',
            },
            stepse: {
                branchescount: '',
                branchcatagory: '',
            },
            stepseone: {
                // first branch variable
                onebranchname: '',
                onebranchcatagory: '',
                onesameaddressstatus: false,
                onestate: { value: 11, label: 'Goa' },
                onecity: '',
                onearea: '',
                oneaddress: '',
                onepincode: '',
                onemaplink: '',
            },
            stepsetwo: {
                // two branch variable
                twobranchname: '',
                twobranchcatagory: '',
                twosameaddressstatus: false,
                twostate: { value: 11, label: 'Goa' },
                twocity: '',
                twoarea: '',
                twoaddress: '',
                twopincode: '',
                twomaplink: '',
            },
            stepsethree: {
                // three branch variable
                threebranchname: '',
                threebranchcatagory: '',
                threesameaddressstatus: false,
                threestate: { value: 11, label: 'Goa' },
                threecity: '',
                threearea: '',
                threeaddress: '',
                threepincode: '',
                threemaplink: '',
            },
            stepsefour: {
                // Four branch variable
                fourbranchname: '',
                fourbranchcatagory: '',
                foursameaddressstatus: false,
                fourstate: { value: 11, label: 'Goa' },
                fourcity: '',
                fourarea: '',
                fouraddress: '',
                fourpincode: '',
                fourmaplink: '',
            },
            stepsefive: {
                // Five branch variable
                fivebranchname: '',
                fivebranchcatagory: '',
                fivesameaddressstatus: false,
                fivestate: { value: 11, label: 'Goa' },
                fivecity: '',
                fivearea: '',
                fiveaddress: '',
                fivepincode: '',
                fivemaplink: '',
            },
            stepsesix: {
                // Six branch variable
                sixbranchname: '',
                sixbranchcatagory: '',
                sixsameaddressstatus: false,
                sixstate: { value: 11, label: 'Goa' },
                sixcity: '',
                sixarea: '',
                sixaddress: '',
                sixpincode: '',
                sixmaplink: '',
            },
            stepseseven: {
                // Seven branch variable
                sevenbranchname: '',
                sevenbranchcatagory: '',
                sevensameaddressstatus: false,
                sevenstate: { value: 11, label: 'Goa' },
                sevencity: '',
                sevenarea: '',
                sevenaddress: '',
                sevenpincode: '',
                sevenmaplink: '',
            },
            stepseeight: {
                // Eight branch variable
                eightbranchname: '',
                eightbranchcatagory: '',
                eightsameaddressstatus: false,
                eightstate: { value: 11, label: 'Goa' },
                eightcity: '',
                eightarea: '',
                eightaddress: '',
                eightpincode: '',
                eightmaplink: '',
            },
            stepsenine: {
                // Nine branch variable
                ninebranchname: '',
                ninebranchcatagory: '',
                ninesameaddressstatus: false,
                ninestate: { value: 11, label: 'Goa' },
                ninecity: '',
                ninearea: '',
                nineaddress: '',
                ninepincode: '',
                ninemaplink: '',
            },
            stepseten: {
                // Ten branch variable
                tenbranchname: '',
                tenbranchcatagory: '',
                tensameaddressstatus: false,
                tenstate: { value: 11, label: 'Goa' },
                tencity: '',
                tenarea: '',
                tenaddress: '',
                tenpincode: '',
                tenmaplink: '',
            },
            stepseeleven: {
                // Eleven branch variable
                elevenbranchname: '',
                elevenbranchcatagory: '',
                elevensameaddressstatus: false,
                elevenstate: { value: 11, label: 'Goa' },
                elevencity: '',
                elevenarea: '',
                elevenaddress: '',
                elevenpincode: '',
                elevenmaplink: '',
            },
            stepsetwelve: {
                // twelve branch variable
                twelvebranchname: '',
                twelvebranchcatagory: '',
                twelvesameaddressstatus: false,
                twelvestate: { value: 11, label: 'Goa' },
                twelvecity: '',
                twelvearea: '',
                twelveaddress: '',
                twelvepincode: '',
                twelvemaplink: '',
            },
            stepsethirteen: {
                // thirteen branch variable
                thirteenbranchname: '',
                thirteenbranchcatagory: '',
                thirteensameaddressstatus: false,
                thirteenstate: { value: 11, label: 'Goa' },
                thirteencity: '',
                thirteenarea: '',
                thirteenaddress: '',
                thirteenpincode: '',
                thirteenmaplink: '',
            },
            stepsefourteen: {
                // fourteen branch variable
                fourteenbranchname: '',
                fourteenbranchcatagory: '',
                fourteensameaddressstatus: false,
                fourteenstate: { value: 11, label: 'Goa' },
                fourteencity: '',
                fourteenarea: '',
                fourteenaddress: '',
                fourteenpincode: '',
                fourteenmaplink: '',
            },
            stepsefifteen: {
                // fifteen branch variable
                fifteenbranchname: '',
                fifteenbranchcatagory: '',
                fifteensameaddressstatus: false,
                fifteenstate: { value: 11, label: 'Goa' },
                fifteencity: '',
                fifteenarea: '',
                fifteenaddress: '',
                fifteenpincode: '',
                fifteenmaplink: '',
            },
            stepsesixteen: {
                // sixteen branch variable
                sixteenbranchname: '',
                sixteenbranchcatagory: '',
                sixteensameaddressstatus: false,
                sixteenstate: { value: 11, label: 'Goa' },
                sixteencity: '',
                sixteenarea: '',
                sixteenaddress: '',
                sixteenpincode: '',
                sixteenmaplink: '',
            },
            stepseseventeen: {
                // seventeen branch variable
                seventeenbranchname: '',
                seventeenbranchcatagory: '',
                seventeensameaddressstatus: false,
                seventeenstate: { value: 11, label: 'Goa' },
                seventeencity: '',
                seventeenarea: '',
                seventeenaddress: '',
                seventeenpincode: '',
                seventeenmaplink: '',
            },
            stepseeighteen: {
                // eighteen branch variable
                eighteenbranchname: '',
                eighteenbranchcatagory: '',
                eighteensameaddressstatus: false,
                eighteenstate: { value: 11, label: 'Goa' },
                eighteencity: '',
                eighteenarea: '',
                eighteenaddress: '',
                eighteenpincode: '',
                eighteenmaplink: '',
            },
            stepsenineteen: {
                // nineteen branch variable
                nineteenbranchname: '',
                nineteenbranchcatagory: '',
                nineteensameaddressstatus: false,
                nineteenstate: { value: 11, label: 'Goa' },
                nineteencity: '',
                nineteenarea: '',
                nineteenaddress: '',
                nineteenpincode: '',
                nineteenmaplink: '',
            },
            stepsetwenty: {
                // twenty branch variable
                twentybranchname: '',
                twentybranchcatagory: '',
                twentysameaddressstatus: false,
                twentystate: { value: 11, label: 'Goa' },
                twentycity: '',
                twentyarea: '',
                twentyaddress: '',
                twentypincode: '',
                twentymaplink: '',
            },
            stepsetwentyone: {
                // twentyone branch variable
                twentyonebranchname: '',
                twentyonebranchcatagory: '',
                twentyonesameaddressstatus: false,
                twentyonestate: { value: 11, label: 'Goa' },
                twentyonecity: '',
                twentyonearea: '',
                twentyoneaddress: '',
                twentyonepincode: '',
                twentyonemaplink: '',
            },
            stepsetwentytwo: {
                // twentytwo branch variable
                twentytwobranchname: '',
                twentytwobranchcatagory: '',
                twentytwosameaddressstatus: false,
                twentytwostate: { value: 11, label: 'Goa' },
                twentytwocity: '',
                twentytwoarea: '',
                twentytwoaddress: '',
                twentytwopincode: '',
                twentytwomaplink: '',
            },
            stepsetwentythree: {
                // twentythree branch variable
                twentythreebranchname: '',
                twentythreebranchcatagory: '',
                twentythreesameaddressstatus: false,
                twentythreestate: { value: 11, label: 'Goa' },
                twentythreecity: '',
                twentythreearea: '',
                twentythreeaddress: '',
                twentythreepincode: '',
                twentythreemaplink: '',
            },
            stepsetwentyfour: {
                // twentyfour branch variable
                twentyfourbranchname: '',
                twentyfourbranchcatagory: '',
                twentyfoursameaddressstatus: false,
                twentyfourstate: { value: 11, label: 'Goa' },
                twentyfourcity: '',
                twentyfourarea: '',
                twentyfouraddress: '',
                twentyfourpincode: '',
                twentyfourmaplink: '',
            },
            stepsetwentyfive: {
                // twentyfive branch variable
                twentyfivebranchname: '',
                twentyfivebranchcatagory: '',
                twentyfivesameaddressstatus: false,
                twentyfivestate: { value: 11, label: 'Goa' },
                twentyfivecity: '',
                twentyfivearea: '',
                twentyfiveaddress: '',
                twentyfivepincode: '',
                twentyfivemaplink: '',
            },
            stepthone: {
                firstusertitle: { value: 1, label: 'Mr.' },
                firstuserfullname: '',
                firstusermobileno: '',
                firstuserdesignation: '',
                firstuserbranches: '',
                firstuserrole: '',
            },
            stepthtwo: {
                secondusertitle: { value: 1, label: 'Mr.' },
                seconduserfullname: '',
                secondusermobileno: '',
                seconduserdesignation: '',
                seconduserbranches: '',
                seconduserrole: '',
            },
            stepththree: {
                thirdusertitle: { value: 1, label: 'Mr.' },
                thirduserfullname: '',
                thirdusermobileno: '',
                thirduserdesignation: '',
                thirduserbranches: '',
                thirduserrole: '',
            },
            stepthfour: {
                fourthusertitle: { value: 1, label: 'Mr.' },
                fourthuserfullname: '',
                fourthusermobileno: '',
                fourthuserdesignation: '',
                fourthuserbranches: '',
                fourthuserrole: '',
            },
            stepthfive: {
                fifthusertitle: { value: 1, label: 'Mr.' },
                fifthuserfullname: '',
                fifthusermobileno: '',
                fifthuserdesignation: '',
                fifthuserbranches: '',
                fifthuserrole: '',
            },
            stepthsix: {
                sixthusertitle: { value: 1, label: 'Mr.' },
                sixthuserfullname: '',
                sixthusermobileno: '',
                sixthuserdesignation: '',
                sixthuserbranches: '',
                sixthuserrole: '',
            },
            stepthseven: {
                seventhusertitle: { value: 1, label: 'Mr.' },
                seventhuserfullname: '',
                seventhusermobileno: '',
                seventhuserdesignation: '',
                seventhuserbranches: '',
                seventhuserrole: '',
            },
            steptheight: {
                eighthusertitle: { value: 1, label: 'Mr.' },
                eighthuserfullname: '',
                eighthusermobileno: '',
                eighthuserdesignation: '',
                eighthuserbranches: '',
                eighthuserrole: '',
            },
            stepthnine: {
                ninethusertitle: { value: 1, label: 'Mr.' },
                ninethuserfullname: '',
                ninethusermobileno: '',
                ninethuserdesignation: '',
                ninethuserbranches: '',
                ninethuserrole: '',
            },
            stepthten: {
                tenthusertitle: { value: 1, label: 'Mr.' },
                tenthuserfullname: '',
                tenthusermobileno: '',
                tenthuserdesignation: '',
                tenthuserbranches: '',
                tenthuserrole: '',
            },

            titleList: [],
            roleList: [],
            countryCodeList: [],
            mobileotp: '',
            designationList: [],
            entitytypeList: [],
            cityList: [],
            areaList: [],
            stateList: [],
            hotelcategoryList: [],
            submitted: false,
            errmsg: {},
            showfirststepLoader: false,
            showsecstepLoader: false,
            showotpsendLoader: false,
            showotpresendLoader: false,
            showthirdstepLoader: false,
            showotpverifyLoader: false,
            timerMobileInterval: null,
            timerMobileStatus: false,
            timerMobileCount: 120,
            mobdisabledflag: false,
            nameinputdisabledflag: false,
            otpsendbtnhide: true,
            otpsendinputshow: false,
            resendOtpBtnStatus: false,
            branchCountList: [],
            branchList: [],
            secondrowshowstatus: false,
            secondrowbtnstatus: true,

            thirdrowshowstatus: false,
            thirdrowbtnstatus: true,

            fourthrowshowstatus: false,
            fourthrowbtnstatus: false,

            fifthrowshowstatus: false,
            fifthrowbtnstatus: false,

            seconduserrequire: false,
            thirduserrequire: false,
            fourthuserrequire: false,
            fifthuserrequire: false,
            sixthuserrequire: false,
            seventhuserrequire: false,
            eighthuserrequire: false,
            ninethuserrequire: false,
            tenthuserrequire: false,

            activebranch: 0,
            verifiedsuccessotp: false,
            hostId: '',
            cityloaderflag: false,
            arealoaderflag: false,
            disabledflagoneuser: false,
            disabledflagtwouser: false,
            disabledflagthreeuser: false,
            disabledflagfouruser: false,
            mobileExistsErrorMsg: '',
            activeuser: 0,
            userTabActiveFlag: '',
            showgstdataLoader: false,
            gstcompanydetails: '',
            mobileotperr: '',
            hidegstsubmitbtn: false,
            hideotpinput: false,
            firststephostdetails: '',
            maplinkhostvalue: '',
            gstnumberhostvalue: '',
            pannumberhostvalue: '',
            brandnamehostvalue: '',
            legalnamehostvalue: '',
            entitytypehostvalue: '',
            statehostvalue: '',
            cityhostvalue: '',
            areahostvalue: '',
            addresshostvalue: '',
            pincodehostvalue: '',
            hostusername: '',
            hostuserpassword: '',
            mobileotpvalue: '',
            userCompanyFk: '',
            singalBranchEditFkValue: '',
            oneBranchEditFkValue: '',
            twoBranchEditFkValue: '',
            threeBranchEditFkValue: '',
            fourBranchEditFkValue: '',
            fiveBranchEditFkValue: '',
            sixBranchEditFkValue: '',
            sevenBranchEditFkValue: '',
            eightBranchEditFkValue: '',
            nineBranchEditFkValue: '',
            tenBranchEditFkValue: '',
            elevenBranchEditFkValue: '',
            twelveBranchEditFkValue: '',
            thirteenBranchEditFkValue: '',
            fourteenBranchEditFkValue: '',
            fifteenBranchEditFkValue: '',
            sixteenBranchEditFkValue: '',
            seventeenBranchEditFkValue: '',
            eighteenBranchEditFkValue: '',
            nineteenBranchEditFkValue: '',
            twentyBranchEditFkValue: '',
            twentyoneBranchEditFkValue: '',
            twentytwoBranchEditFkValue: '',
            twentythreeBranchEditFkValue: '',
            twentyfourBranchEditFkValue: '',
            twentyfiveBranchEditFkValue: '',
            oneUserEditFkValue: '',
            twoUserEditFkValue: '',
            threeUserEditFkValue: '',
            fourUserEditFkValue: '',
            fiveUserEditFkValue: '',
            sixUserEditFkValue: '',
            sevenUserEditFkValue: '',
            eightUserEditFkValue: '',
            nineUserEditFkValue: '',
            tenUserEditFkValue: '',
        }
    },
    eventService: null,
    ApiService: null,
    created() {
        this.eventService = new EventService();
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            mobileotp: { required: helpers.withMessage('Please enter Code', required) },
            stepopen: {
                title: { required: helpers.withMessage('Please select title', required) },
                fullname: { required: helpers.withMessage('Please enter name', required) },
                mobileno: { required: helpers.withMessage('Please enter mobile No.', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
            },
            stepf: {
                brandname: { required: helpers.withMessage('Please enter brand name', required) },
                legalname: { requiredIf: helpers.withMessage('Please enter legal name', requiredIf(this.stepf.isgstnumberflag == 2)), },
                entitytype: { requiredIf: helpers.withMessage('Please select entity type', requiredIf(this.stepf.isgstnumberflag == 2)) },
                city: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepf.isgstnumberflag == 2)) },
                area: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepf.isgstnumberflag == 2)) },
                state: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepf.isgstnumberflag == 2)) },
                address: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepf.isgstnumberflag == 2)) },
                pincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepf.isgstnumberflag == 2)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                maplink: { required: helpers.withMessage('Please enter map link', required), url: helpers.withMessage("Enter vaild map link", url), },
                gstnumber: { requiredIf: helpers.withMessage('Please enter GST Number', requiredIf(this.stepf.isgstnumberflag == 1)) },
            },
            stepse: {
                branchescount: { required: helpers.withMessage('Please select option', required) },
                branchcatagory: { requiredIf: helpers.withMessage("Please select category", requiredIf(this.stepse.branchescount.value == 1)), },
            },
            stepseone: {
                // First branch validation
                onebranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 1)), },
                onebranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 1)), },
                onestate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepseone.onesameaddressstatus == false && this.stepse.branchescount.value > 1)), },
                onecity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepseone.onesameaddressstatus == false && this.stepse.branchescount.value > 1)), },
                onearea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepseone.onesameaddressstatus == false && this.stepse.branchescount.value > 1)), },
                oneaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepseone.onesameaddressstatus == false && this.stepse.branchescount.value > 1)), },
                onepincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepseone.onesameaddressstatus == false && this.stepse.branchescount.value > 1)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                onemaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepseone.onesameaddressstatus == false && this.stepse.branchescount.value > 1)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsetwo: {
                // Second branch validation
                twobranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 1)), },
                twobranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 1)), },
                twostate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsetwo.twosameaddressstatus == false && this.stepse.branchescount.value > 1)), },
                twocity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsetwo.twosameaddressstatus == false && this.stepse.branchescount.value > 1)), },
                twoarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsetwo.twosameaddressstatus == false && this.stepse.branchescount.value > 1)), },
                twoaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsetwo.twosameaddressstatus == false && this.stepse.branchescount.value > 1)), },
                twopincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsetwo.twosameaddressstatus == false && this.stepse.branchescount.value > 1)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                twomaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsetwo.twosameaddressstatus == false && this.stepse.branchescount.value > 1)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsethree: {
                // three branch validation
                threebranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 2)), },
                threebranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 2)), },
                threestate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsethree.threesameaddressstatus == false && this.stepse.branchescount.value > 2)), },
                threecity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsethree.threesameaddressstatus == false && this.stepse.branchescount.value > 2)), },
                threearea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsethree.threesameaddressstatus == false && this.stepse.branchescount.value > 2)), },
                threeaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsethree.threesameaddressstatus == false && this.stepse.branchescount.value > 2)), },
                threepincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsethree.threesameaddressstatus == false && this.stepse.branchescount.value > 2)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                threemaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsethree.threesameaddressstatus == false && this.stepse.branchescount.value > 2)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsefour: {
                // four branch validation
                fourbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 3)), },
                fourbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 3)), },
                fourstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsefour.foursameaddressstatus == false && this.stepse.branchescount.value > 3)), },
                fourcity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsefour.foursameaddressstatus == false && this.stepse.branchescount.value > 3)), },
                fourarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsefour.foursameaddressstatus == false && this.stepse.branchescount.value > 3)), },
                fouraddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsefour.foursameaddressstatus == false && this.stepse.branchescount.value > 3)), },
                fourpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsefour.foursameaddressstatus == false && this.stepse.branchescount.value > 3)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                fourmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsefour.foursameaddressstatus == false && this.stepse.branchescount.value > 3)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsefive: {
                // five branch validation
                fivebranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 4)), },
                fivebranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 4)), },
                fivestate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsefive.fivesameaddressstatus == false && this.stepse.branchescount.value > 4)), },
                fivecity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsefive.fivesameaddressstatus == false && this.stepse.branchescount.value > 4)), },
                fivearea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsefive.fivesameaddressstatus == false && this.stepse.branchescount.value > 4)), },
                fiveaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsefive.fivesameaddressstatus == false && this.stepse.branchescount.value > 4)), },
                fivepincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsefive.fivesameaddressstatus == false && this.stepse.branchescount.value > 4)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                fivemaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsefive.fivesameaddressstatus == false && this.stepse.branchescount.value > 4)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsesix: {
                // six branch validation
                sixbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 5)), },
                sixbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 5)), },
                sixstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsesix.sixsameaddressstatus == false && this.stepse.branchescount.value > 5)), },
                sixcity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsesix.sixsameaddressstatus == false && this.stepse.branchescount.value > 5)), },
                sixarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsesix.sixsameaddressstatus == false && this.stepse.branchescount.value > 5)), },
                sixaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsesix.sixsameaddressstatus == false && this.stepse.branchescount.value > 5)), },
                sixpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsesix.sixsameaddressstatus == false && this.stepse.branchescount.value > 5)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                sixmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsesix.sixsameaddressstatus == false && this.stepse.branchescount.value > 5)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepseseven: {
                // seven branch validation
                sevenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 6)), },
                sevenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 6)), },
                sevenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepseseven.sevensameaddressstatus == false && this.stepse.branchescount.value > 6)), },
                sevencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepseseven.sevensameaddressstatus == false && this.stepse.branchescount.value > 6)), },
                sevenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepseseven.sevensameaddressstatus == false && this.stepse.branchescount.value > 6)), },
                sevenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepseseven.sevensameaddressstatus == false && this.stepse.branchescount.value > 6)), },
                sevenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepseseven.sevensameaddressstatus == false && this.stepse.branchescount.value > 6)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                sevenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepseseven.sevensameaddressstatus == false && this.stepse.branchescount.value > 6)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepseeight: {
                // eight branch validation
                eightbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 7)), },
                eightbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 7)), },
                eightstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepseeight.eightsameaddressstatus == false && this.stepse.branchescount.value > 7)), },
                eightcity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepseeight.eightsameaddressstatus == false && this.stepse.branchescount.value > 7)), },
                eightarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepseeight.eightsameaddressstatus == false && this.stepse.branchescount.value > 7)), },
                eightaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepseeight.eightsameaddressstatus == false && this.stepse.branchescount.value > 7)), },
                eightpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepseeight.eightsameaddressstatus == false && this.stepse.branchescount.value > 7)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                eightmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepseeight.eightsameaddressstatus == false && this.stepse.branchescount.value > 7)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsenine: {
                // nine branch validation
                ninebranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 8)), },
                ninebranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 8)), },
                ninestate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsenine.ninesameaddressstatus == false && this.stepse.branchescount.value > 8)), },
                ninecity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsenine.ninesameaddressstatus == false && this.stepse.branchescount.value > 8)), },
                ninearea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsenine.ninesameaddressstatus == false && this.stepse.branchescount.value > 8)), },
                nineaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsenine.ninesameaddressstatus == false && this.stepse.branchescount.value > 8)), },
                ninepincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsenine.ninesameaddressstatus == false && this.stepse.branchescount.value > 8)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                ninemaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsenine.ninesameaddressstatus == false && this.stepse.branchescount.value > 8)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepseten: {
                // ten branch validation
                tenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 9)), },
                tenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 9)), },
                tenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepseten.tensameaddressstatus == false && this.stepse.branchescount.value > 9)), },
                tencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepseten.tensameaddressstatus == false && this.stepse.branchescount.value > 9)), },
                tenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepseten.tensameaddressstatus == false && this.stepse.branchescount.value > 9)), },
                tenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepseten.tensameaddressstatus == false && this.stepse.branchescount.value > 9)), },
                tenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepseten.tensameaddressstatus == false && this.stepse.branchescount.value > 9)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                tenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepseten.tensameaddressstatus == false && this.stepse.branchescount.value > 9)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepseeleven: {
                // eleven branch validation
                elevenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 10)), },
                elevenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 10)), },
                elevenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepseeleven.elevensameaddressstatus == false && this.stepse.branchescount.value > 10)), },
                elevencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepseeleven.elevensameaddressstatus == false && this.stepse.branchescount.value > 10)), },
                elevenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepseeleven.elevensameaddressstatus == false && this.stepse.branchescount.value > 10)), },
                elevenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepseeleven.elevensameaddressstatus == false && this.stepse.branchescount.value > 10)), },
                elevenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepseeleven.elevensameaddressstatus == false && this.stepse.branchescount.value > 10)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                elevenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepseeleven.elevensameaddressstatus == false && this.stepse.branchescount.value > 10)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsetwelve: {
                // twelve branch validation
                twelvebranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 11)), },
                twelvebranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 11)), },
                twelvestate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsetwelve.twelvesameaddressstatus == false && this.stepse.branchescount.value > 11)), },
                twelvecity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsetwelve.twelvesameaddressstatus == false && this.stepse.branchescount.value > 11)), },
                twelvearea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsetwelve.twelvesameaddressstatus == false && this.stepse.branchescount.value > 11)), },
                twelveaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsetwelve.twelvesameaddressstatus == false && this.stepse.branchescount.value > 11)), },
                twelvepincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsetwelve.twelvesameaddressstatus == false && this.stepse.branchescount.value > 11)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                twelvemaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsetwelve.twelvesameaddressstatus == false && this.stepse.branchescount.value > 11)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsethirteen: {
                // thirteen branch validation
                thirteenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 12)), },
                thirteenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 12)), },
                thirteenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsethirteen.thirteensameaddressstatus == false && this.stepse.branchescount.value > 12)), },
                thirteencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsethirteen.thirteensameaddressstatus == false && this.stepse.branchescount.value > 12)), },
                thirteenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsethirteen.thirteensameaddressstatus == false && this.stepse.branchescount.value > 12)), },
                thirteenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsethirteen.thirteensameaddressstatus == false && this.stepse.branchescount.value > 12)), },
                thirteenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsethirteen.thirteensameaddressstatus == false && this.stepse.branchescount.value > 12)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                thirteenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsethirteen.thirteensameaddressstatus == false && this.stepse.branchescount.value > 12)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsefourteen: {
                // fourteen branch validation
                fourteenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 13)), },
                fourteenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 13)), },
                fourteenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsefourteen.fourteensameaddressstatus == false && this.stepse.branchescount.value > 13)), },
                fourteencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsefourteen.fourteensameaddressstatus == false && this.stepse.branchescount.value > 13)), },
                fourteenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsefourteen.fourteensameaddressstatus == false && this.stepse.branchescount.value > 13)), },
                fourteenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsefourteen.fourteensameaddressstatus == false && this.stepse.branchescount.value > 13)), },
                fourteenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsefourteen.fourteensameaddressstatus == false && this.stepse.branchescount.value > 13)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                fourteenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsefourteen.fourteensameaddressstatus == false && this.stepse.branchescount.value > 13)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsefifteen: {
                // fifteen branch validation
                fifteenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 14)), },
                fifteenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 14)), },
                fifteenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsefifteen.fifteensameaddressstatus == false && this.stepse.branchescount.value > 14)), },
                fifteencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsefifteen.fifteensameaddressstatus == false && this.stepse.branchescount.value > 14)), },
                fifteenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsefifteen.fifteensameaddressstatus == false && this.stepse.branchescount.value > 14)), },
                fifteenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsefifteen.fifteensameaddressstatus == false && this.stepse.branchescount.value > 14)), },
                fifteenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsefifteen.fifteensameaddressstatus == false && this.stepse.branchescount.value > 14)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                fifteenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsefifteen.fifteensameaddressstatus == false && this.stepse.branchescount.value > 14)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsesixteen: {
                // sixteen branch validation
                sixteenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 15)), },
                sixteenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 15)), },
                sixteenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsesixteen.sixteensameaddressstatus == false && this.stepse.branchescount.value > 15)), },
                sixteencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsesixteen.sixteensameaddressstatus == false && this.stepse.branchescount.value > 15)), },
                sixteenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsesixteen.sixteensameaddressstatus == false && this.stepse.branchescount.value > 15)), },
                sixteenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsesixteen.sixteensameaddressstatus == false && this.stepse.branchescount.value > 15)), },
                sixteenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsesixteen.sixteensameaddressstatus == false && this.stepse.branchescount.value > 15)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                sixteenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsesixteen.sixteensameaddressstatus == false && this.stepse.branchescount.value > 15)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepseseventeen: {
                // seventeen branch validation
                seventeenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 16)), },
                seventeenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 16)), },
                seventeenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepseseventeen.seventeensameaddressstatus == false && this.stepse.branchescount.value > 16)), },
                seventeencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepseseventeen.seventeensameaddressstatus == false && this.stepse.branchescount.value > 16)), },
                seventeenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepseseventeen.seventeensameaddressstatus == false && this.stepse.branchescount.value > 16)), },
                seventeenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepseseventeen.seventeensameaddressstatus == false && this.stepse.branchescount.value > 16)), },
                seventeenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepseseventeen.seventeensameaddressstatus == false && this.stepse.branchescount.value > 16)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                seventeenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepseseventeen.seventeensameaddressstatus == false && this.stepse.branchescount.value > 16)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepseeighteen: {
                // eighteen branch validation
                eighteenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 17)), },
                eighteenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 17)), },
                eighteenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepseeighteen.eighteensameaddressstatus == false && this.stepse.branchescount.value > 17)), },
                eighteencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepseeighteen.eighteensameaddressstatus == false && this.stepse.branchescount.value > 17)), },
                eighteenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepseeighteen.eighteensameaddressstatus == false && this.stepse.branchescount.value > 17)), },
                eighteenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepseeighteen.eighteensameaddressstatus == false && this.stepse.branchescount.value > 17)), },
                eighteenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepseeighteen.eighteensameaddressstatus == false && this.stepse.branchescount.value > 17)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                eighteenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepseeighteen.eighteensameaddressstatus == false && this.stepse.branchescount.value > 17)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsenineteen: {
                // nineteen branch validation
                nineteenbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 18)), },
                nineteenbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 18)), },
                nineteenstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsenineteen.nineteensameaddressstatus == false && this.stepse.branchescount.value > 18)), },
                nineteencity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsenineteen.nineteensameaddressstatus == false && this.stepse.branchescount.value > 18)), },
                nineteenarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsenineteen.nineteensameaddressstatus == false && this.stepse.branchescount.value > 18)), },
                nineteenaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsenineteen.nineteensameaddressstatus == false && this.stepse.branchescount.value > 18)), },
                nineteenpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsenineteen.nineteensameaddressstatus == false && this.stepse.branchescount.value > 18)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                nineteenmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsenineteen.nineteensameaddressstatus == false && this.stepse.branchescount.value > 18)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsetwenty: {
                // twenty branch validation
                twentybranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 19)), },
                twentybranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 19)), },
                twentystate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsetwenty.twentysameaddressstatus == false && this.stepse.branchescount.value > 19)), },
                twentycity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsetwenty.twentysameaddressstatus == false && this.stepse.branchescount.value > 19)), },
                twentyarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsetwenty.twentysameaddressstatus == false && this.stepse.branchescount.value > 19)), },
                twentyaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsetwenty.twentysameaddressstatus == false && this.stepse.branchescount.value > 19)), },
                twentypincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsetwenty.twentysameaddressstatus == false && this.stepse.branchescount.value > 19)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                twentymaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsetwenty.twentysameaddressstatus == false && this.stepse.branchescount.value > 19)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsetwentyone: {
                // twentyone branch validation
                twentyonebranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 20)), },
                twentyonebranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 20)), },
                twentyonestate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsetwentyone.twentyonesameaddressstatus == false && this.stepse.branchescount.value > 20)), },
                twentyonecity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsetwentyone.twentyonesameaddressstatus == false && this.stepse.branchescount.value > 20)), },
                twentyonearea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsetwentyone.twentyonesameaddressstatus == false && this.stepse.branchescount.value > 20)), },
                twentyoneaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsetwentyone.twentyonesameaddressstatus == false && this.stepse.branchescount.value > 20)), },
                twentyonepincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsetwentyone.twentyonesameaddressstatus == false && this.stepse.branchescount.value > 20)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                twentyonemaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsetwentyone.twentyonesameaddressstatus == false && this.stepse.branchescount.value > 20)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsetwentytwo: {
                // twentytwo branch validation
                twentytwobranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 21)), },
                twentytwobranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 21)), },
                twentytwostate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsetwentytwo.twentytwosameaddressstatus == false && this.stepse.branchescount.value > 21)), },
                twentytwocity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsetwentytwo.twentytwosameaddressstatus == false && this.stepse.branchescount.value > 21)), },
                twentytwoarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsetwentytwo.twentytwosameaddressstatus == false && this.stepse.branchescount.value > 21)), },
                twentytwoaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsetwentytwo.twentytwosameaddressstatus == false && this.stepse.branchescount.value > 21)), },
                twentytwopincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsetwentytwo.twentytwosameaddressstatus == false && this.stepse.branchescount.value > 21)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                twentytwomaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsetwentytwo.twentytwosameaddressstatus == false && this.stepse.branchescount.value > 21)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsetwentythree: {
                // twentythree branch validation
                twentythreebranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 22)), },
                twentythreebranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 22)), },
                twentythreestate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsetwentythree.twentythreesameaddressstatus == false && this.stepse.branchescount.value > 22)), },
                twentythreecity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsetwentythree.twentythreesameaddressstatus == false && this.stepse.branchescount.value > 22)), },
                twentythreearea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsetwentythree.twentythreesameaddressstatus == false && this.stepse.branchescount.value > 22)), },
                twentythreeaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsetwentythree.twentythreesameaddressstatus == false && this.stepse.branchescount.value > 22)), },
                twentythreepincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsetwentythree.twentythreesameaddressstatus == false && this.stepse.branchescount.value > 22)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                twentythreemaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsetwentythree.twentythreesameaddressstatus == false && this.stepse.branchescount.value > 22)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsetwentyfour: {
                // twentyfour branch validation
                twentyfourbranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 23)), },
                twentyfourbranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 23)), },
                twentyfourstate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsetwentyfour.twentyfoursameaddressstatus == false && this.stepse.branchescount.value > 23)), },
                twentyfourcity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsetwentyfour.twentyfoursameaddressstatus == false && this.stepse.branchescount.value > 23)), },
                twentyfourarea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsetwentyfour.twentyfoursameaddressstatus == false && this.stepse.branchescount.value > 23)), },
                twentyfouraddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsetwentyfour.twentyfoursameaddressstatus == false && this.stepse.branchescount.value > 23)), },
                twentyfourpincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsetwentyfour.twentyfoursameaddressstatus == false && this.stepse.branchescount.value > 23)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                twentyfourmaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsetwentyfour.twentyfoursameaddressstatus == false && this.stepse.branchescount.value > 23)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepsetwentyfive: {
                // twentyfive branch validation
                twentyfivebranchname: { requiredIf: helpers.withMessage('Please enter branch name', requiredIf(this.stepse.branchescount.value > 24)), },
                twentyfivebranchcatagory: { requiredIf: helpers.withMessage('Please select category', requiredIf(this.stepse.branchescount.value > 24)), },
                twentyfivestate: { requiredIf: helpers.withMessage('Please select state', requiredIf(this.stepsetwentyfive.twentyfivesameaddressstatus == false && this.stepse.branchescount.value > 24)), },
                twentyfivecity: { requiredIf: helpers.withMessage('Please select city', requiredIf(this.stepsetwentyfive.twentyfivesameaddressstatus == false && this.stepse.branchescount.value > 24)), },
                twentyfivearea: { requiredIf: helpers.withMessage('Please select area', requiredIf(this.stepsetwentyfive.twentyfivesameaddressstatus == false && this.stepse.branchescount.value > 24)), },
                twentyfiveaddress: { requiredIf: helpers.withMessage('Please enter address', requiredIf(this.stepsetwentyfive.twentyfivesameaddressstatus == false && this.stepse.branchescount.value > 24)), },
                twentyfivepincode: {
                    requiredIf: helpers.withMessage('Please enter pincode', requiredIf(this.stepsetwentyfive.twentyfivesameaddressstatus == false && this.stepse.branchescount.value > 24)), minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
                twentyfivemaplink: {
                    requiredIf: helpers.withMessage('Please enter map link', requiredIf(this.stepsetwentyfive.twentyfivesameaddressstatus == false && this.stepse.branchescount.value > 24)), url: helpers.withMessage("Enter vaild map link"
                        , url),
                },
            },
            stepthone: {
                firstusertitle: { required: helpers.withMessage('Please select title', required) },
                firstuserfullname: { required: helpers.withMessage('Please enter name', required) },
                firstusermobileno: { required: helpers.withMessage('Please enter mobile No.', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                firstuserbranches: { required: helpers.withMessage('Please select branch name', required) },
                firstuserrole: { required: helpers.withMessage('Please select role', required) },
            },
            stepthtwo: {
                secondusertitle: { requiredIf: helpers.withMessage("Please select title", requiredIf(this.seconduserrequire == true)), },
                seconduserfullname: { requiredIf: helpers.withMessage("Please enter name", requiredIf(this.seconduserrequire == true)), },
                secondusermobileno: { requiredIf: helpers.withMessage("Please enter mobile No.", requiredIf(this.seconduserrequire == true)), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                seconduserbranches: { requiredIf: helpers.withMessage("Please select branch name", requiredIf(this.seconduserrequire == true)), },
                seconduserrole: { requiredIf: helpers.withMessage("Please select role", requiredIf(this.seconduserrequire == true)), },
            },
            stepththree: {
                thirdusertitle: { requiredIf: helpers.withMessage("Please select title", requiredIf(this.thirduserrequire == true)), },
                thirduserfullname: { requiredIf: helpers.withMessage("Please enter name", requiredIf(this.thirduserrequire == true)), },
                thirdusermobileno: { requiredIf: helpers.withMessage("Please enter mobile No.", requiredIf(this.thirduserrequire == true)), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                thirduserbranches: { requiredIf: helpers.withMessage("Please select branch name", requiredIf(this.thirduserrequire == true)), },
                thirduserrole: { requiredIf: helpers.withMessage("Please select role", requiredIf(this.thirduserrequire == true)), },
            },
            stepthfour: {
                fourthusertitle: { requiredIf: helpers.withMessage("Please select title", requiredIf(this.fourthuserrequire == true)), },
                fourthuserfullname: { requiredIf: helpers.withMessage("Please enter name", requiredIf(this.fourthuserrequire == true)), },
                fourthusermobileno: { requiredIf: helpers.withMessage("Please enter mobile No.", requiredIf(this.fourthuserrequire == true)), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                fourthuserbranches: { requiredIf: helpers.withMessage("Please select branch name", requiredIf(this.fourthuserrequire == true)), },
                fourthuserrole: { requiredIf: helpers.withMessage("Please select role", requiredIf(this.fourthuserrequire == true)), },
            },
            stepthfive: {
                fifthusertitle: { requiredIf: helpers.withMessage("Please select title", requiredIf(this.fifthuserrequire == true)), },
                fifthuserfullname: { requiredIf: helpers.withMessage("Please enter name", requiredIf(this.fifthuserrequire == true)), },
                fifthusermobileno: { requiredIf: helpers.withMessage("Please enter mobile No.", requiredIf(this.fifthuserrequire == true)), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                fifthuserbranches: { requiredIf: helpers.withMessage("Please select branch name", requiredIf(this.fifthuserrequire == true)), },
                fifthuserrole: { requiredIf: helpers.withMessage("Please select role", requiredIf(this.fifthuserrequire == true)), },
            },
            stepthsix: {
                sixthusertitle: { requiredIf: helpers.withMessage("Please select title", requiredIf(this.sixthuserrequire == true)), },
                sixthuserfullname: { requiredIf: helpers.withMessage("Please enter name", requiredIf(this.sixthuserrequire == true)), },
                sixthusermobileno: { requiredIf: helpers.withMessage("Please enter mobile No.", requiredIf(this.sixthuserrequire == true)), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                sixthuserbranches: { requiredIf: helpers.withMessage("Please select branch name", requiredIf(this.sixthuserrequire == true)), },
                sixthuserrole: { requiredIf: helpers.withMessage("Please select role", requiredIf(this.sixthuserrequire == true)), },
            },
            stepthseven: {
                seventhusertitle: { requiredIf: helpers.withMessage("Please select title", requiredIf(this.seventhuserrequire == true)), },
                seventhuserfullname: { requiredIf: helpers.withMessage("Please enter name", requiredIf(this.seventhuserrequire == true)), },
                seventhusermobileno: { requiredIf: helpers.withMessage("Please enter mobile No.", requiredIf(this.seventhuserrequire == true)), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                seventhuserbranches: { requiredIf: helpers.withMessage("Please select branch name", requiredIf(this.seventhuserrequire == true)), },
                seventhuserrole: { requiredIf: helpers.withMessage("Please select role", requiredIf(this.seventhuserrequire == true)), },
            },
            steptheight: {
                eighthusertitle: { requiredIf: helpers.withMessage("Please select title", requiredIf(this.eighthuserrequire == true)), },
                eighthuserfullname: { requiredIf: helpers.withMessage("Please enter name", requiredIf(this.eighthuserrequire == true)), },
                eighthusermobileno: { requiredIf: helpers.withMessage("Please enter mobile No.", requiredIf(this.eighthuserrequire == true)), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                eighthuserbranches: { requiredIf: helpers.withMessage("Please select branch name", requiredIf(this.eighthuserrequire == true)), },
                eighthuserrole: { requiredIf: helpers.withMessage("Please select role", requiredIf(this.eighthuserrequire == true)), },
            },
            stepthnine: {
                ninethusertitle: { requiredIf: helpers.withMessage("Please select title", requiredIf(this.ninethuserrequire == true)), },
                ninethuserfullname: { requiredIf: helpers.withMessage("Please enter name", requiredIf(this.ninethuserrequire == true)), },
                ninethusermobileno: { requiredIf: helpers.withMessage("Please enter mobile No.", requiredIf(this.ninethuserrequire == true)), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                ninethuserbranches: { requiredIf: helpers.withMessage("Please select branch name", requiredIf(this.ninethuserrequire == true)), },
                ninethuserrole: { requiredIf: helpers.withMessage("Please select role", requiredIf(this.ninethuserrequire == true)), },
            },
            stepthten: {
                tenthusertitle: { requiredIf: helpers.withMessage("Please select title", requiredIf(this.tenthuserrequire == true)), },
                tenthuserfullname: { requiredIf: helpers.withMessage("Please enter name", requiredIf(this.tenthuserrequire == true)), },
                tenthusermobileno: { requiredIf: helpers.withMessage("Please enter mobile No.", requiredIf(this.tenthuserrequire == true)), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
                tenthuserbranches: { requiredIf: helpers.withMessage("Please select branch name", requiredIf(this.tenthuserrequire == true)), },
                tenthuserrole: { requiredIf: helpers.withMessage("Please select role", requiredIf(this.tenthuserrequire == true)), },
            },
        };
    },
    mounted() {
        this.getAgeEvents();
        this.getalltitle();
        this.getCountryCode();
        this.getuserdesignations();
        this.getRoles();
        this.getcompanytypes();
        this.getCategories();
        this.getstates();
        this.getLoadCityApi();
    },

    methods: {
        toggleCTNumberList1(event) {
            this.$refs.op1.toggle(event);
        },
        createOtherDesignationName(e) {
            this.stepf.designation = { value: 0, label: e.target.value };
        },
        toggleDesignation() {
            this.isDesignationExist = !this.isDesignationExist;
            this.stepf.designation = '';
        },
        // First User
        toggleDesignationFirstUser() {
            this.isDesignationExistFirstUser = !this.isDesignationExistFirstUser;
            this.stepthone.firstuserdesignation = '';
        },
        createOtherDesignationNameFirstUser(e) {
            this.stepthone.firstuserdesignation = { value: 0, label: e.target.value };
        },
        // Second User
        toggleDesignationSecondUser() {
            this.isDesignationExistSecondUser = !this.isDesignationExistSecondUser;
            this.stepthtwo.seconduserdesignation = '';
        },
        createOtherDesignationNameSecondUser(e) {
            this.stepthtwo.seconduserdesignation = { value: 0, label: e.target.value };
        },
        // Third User
        toggleDesignationThirdUser() {
            this.isDesignationExistThirdUser = !this.isDesignationExistThirdUser;
            this.stepththree.thirduserdesignation = '';
        },
        createOtherDesignationNameThirdUser(e) {
            this.stepththree.thirduserdesignation = { value: 0, label: e.target.value };
        },
        // Fourth User
        toggleDesignationFourthUser() {
            this.isDesignationExistFourthUser = !this.isDesignationExistFourthUser;
            this.stepthfour.fourthuserdesignation = '';
        },
        createOtherDesignationNameFourthUser(e) {
            this.stepthfour.fourthuserdesignation = { value: 0, label: e.target.value };
        },
        // Fifth User
        toggleDesignationFifthUser() {
            this.isDesignationExistFifthUser = !this.isDesignationExistFifthUser;
            this.stepthfive.fifthuserdesignation = '';
        },
        createOtherDesignationNameFifthUser(e) {
            this.stepthfive.fifthuserdesignation = { value: 0, label: e.target.value };
        },
        // Sixth User
        toggleDesignationSixthUser() {
            this.isDesignationExistSixthUser = !this.isDesignationExistSixthUser;
            this.stepthsix.sixthuserdesignation = '';
        },
        createOtherDesignationNameSixthUser(e) {
            this.stepthsix.sixthuserdesignation = { value: 0, label: e.target.value };
        },
        // Seventh User
        toggleDesignationSeventhUser() {
            this.isDesignationExistSeventhUser = !this.isDesignationExistSeventhUser;
            this.stepthseven.seventhuserdesignation = '';
        },
        createOtherDesignationNameSeventhUser(e) {
            this.stepthseven.seventhuserdesignation = { value: 0, label: e.target.value };
        },
        // Eighth User
        toggleDesignationEighthUser() {
            this.isDesignationExistEighthUser = !this.isDesignationExistEighthUser;
            this.steptheight.eighthuserdesignation = '';
        },
        createOtherDesignationNameEighthUser(e) {
            this.steptheight.eighthuserdesignation = { value: 0, label: e.target.value };
        },
        // Nineth User
        toggleDesignationNinethUser() {
            this.isDesignationExistNinethUser = !this.isDesignationExistNinethUser;
            this.stepthnine.ninethuserdesignation = '';
        },
        createOtherDesignationNameNinethUser(e) {
            this.stepthnine.ninethuserdesignation = { value: 0, label: e.target.value };
        },
        // Tenth User
        toggleDesignationTenthUser() {
            this.isDesignationExistTenthUser = !this.isDesignationExistTenthUser;
            this.stepthten.tenthuserdesignation = '';
        },
        createOtherDesignationNameTenthUser(e) {
            this.stepthten.tenthuserdesignation = { value: 0, label: e.target.value };
        },


        getCountryCode(e) {
            this.ApiService.getCountryCode(e).then((data) => {
                if (data.status == 200) {
                    this.countryCodeList = data.data;
                } else {
                    this.countryCodeList = null;
                }
            });
        },
        getalltitle(e) {
            this.ApiService.getalltitle(e).then((data) => {
                if (data.status == 200) {
                    this.titleList = data.data;
                } else {
                    this.titleList = null;
                }
            });
        },
        getuserdesignations(e) {
            this.ApiService.getuserdesignations(e).then((data) => {
                if (data.status == 200) {
                    this.designationList = data.data;
                } else {
                    this.designationList = null;
                }
            });
        },
        getRoles() {
            this.ApiService.getRoles({ at4: 2 }).then((data) => {
                if (data.status == 200) {
                    this.roleList = data.data;
                } else {
                    this.roleList = null;
                }
            });
        },
        getcompanytypes(e) {
            this.ApiService.getcompanytypes(e).then((data) => {
                if (data.status == 200) {
                    this.entitytypeList = data.data;
                } else {
                    this.entitytypeList = null;
                }
            });
        },
        getCategories(e) {
            this.ApiService.getCategories(e).then((data) => {
                if (data.status == 200) {
                    this.hotelcategoryList = data.data;
                } else {
                    this.hotelcategoryList = null;
                }
            });
        },
        getstates() {
            this.ApiService.getstates().then((data) => {
                if (data.success == true) {
                    this.stateList = data.data;
                } else {
                    this.stateList = null;
                }
            });
        },
        getLoadCityApi() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepf.state.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateId(event) {
            this.cityloaderflag = true;
            this.stepf.area = '';
            this.stepf.city = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getHostBranches(event) {
            this.ApiService.getHostBranches(event).then((data) => {
                if (data.success == true) {
                    this.branchList = data.data;
                } else {
                    this.branchList = null;
                }
            });
        },
        agreeWizardStepBtn() {
            this.guildelineactive = true;
            this.active = 0;
        },
        // branch tab state city area function start here
        getLoadCityByStateOneId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepseone.onestate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateOneId(event) {
            this.cityloaderflag = true;
            this.stepseone.onecity = '';
            this.stepseone.onearea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityOneId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateTwoId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsetwo.twostate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateTwoId(event) {
            this.cityloaderflag = true;
            this.stepsetwo.twocity = '';
            this.stepsetwo.twoarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityTwoId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateThreeId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsethree.threestate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateThreeId(event) {
            this.cityloaderflag = true;
            this.stepsethree.threecity = '';
            this.stepsethree.threearea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityThreeId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateFourId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsefour.fourstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateFourId(event) {
            this.cityloaderflag = true;
            this.stepsefour.fourcity = '';
            this.stepsefour.fourarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityFourId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateFiveId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsefive.fivestate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateFiveId(event) {
            this.cityloaderflag = true;
            this.stepsefive.fivecity = '';
            this.stepsefive.fivearea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityFiveId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateSixId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsesix.sixstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateSixId(event) {
            this.cityloaderflag = true;
            this.stepsesix.sixcity = '';
            this.stepsesix.sixarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCitySixId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateSevenId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepseseven.sevenstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateSevenId(event) {
            this.cityloaderflag = true;
            this.stepseseven.sevencity = '';
            this.stepseseven.sevenarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCitySevenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateEightId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepseeight.eightstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateEightId(event) {
            this.cityloaderflag = true;
            this.stepseeight.eightcity = '';
            this.stepseeight.eightarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityEightId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateNineId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsenine.ninestate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateNineId(event) {
            this.cityloaderflag = true;
            this.stepsenine.ninecity = '';
            this.stepsenine.ninearea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityNineId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatetenId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepseten.tenstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateTenId(event) {
            this.cityloaderflag = true;
            this.stepseten.tencity = '';
            this.stepseten.tenarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityTenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateelevenId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepseeleven.elevenstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateElevenId(event) {
            this.cityloaderflag = true;
            this.stepseeleven.elevencity = '';
            this.stepseeleven.elevenarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityElevenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatetwelveId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsetwelve.twelvestate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateTwelveId(event) {
            this.cityloaderflag = true;
            this.stepsetwelve.twelvecity = '';
            this.stepsetwelve.twelvearea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityTwelveId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatethirteeId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsethirtee.thirteestate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateThirteenId(event) {
            this.cityloaderflag = true;
            this.stepsethirtee.thirteecity = '';
            this.stepsethirtee.thirteearea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityThirteenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatefourteenId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsefourteen.fourteenstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateFourteenId(event) {
            this.cityloaderflag = true;
            this.stepsefourteen.fourteencity = '';
            this.stepsefourteen.fourteenarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityFourteenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatefifteenId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsefifteen.fifteenstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateFifteenId(event) {
            this.cityloaderflag = true;
            this.stepsefifteen.fifteencity = '';
            this.stepsefifteen.fifteenarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityFifteenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatesixteenId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsesixteen.sixteenstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateSixteenId(event) {
            this.cityloaderflag = true;
            this.stepsesixteen.sixteencity = '';
            this.stepsesixteen.sixteenarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCitySixteenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateseventeenId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepseseventeen.seventeenstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateSeventeenId(event) {
            this.cityloaderflag = true;
            this.stepseseventeen.seventeencity = '';
            this.stepseseventeen.seventeenarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCitySeventeenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStateeighteenId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepseeighteen.eighteenstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateEighteenId(event) {
            this.cityloaderflag = true;
            this.stepseeighteen.eighteencity = '';
            this.stepseeighteen.eighteenarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityEighteenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatenineteenId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsenineteen.nineteenstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateNineteenId(event) {
            this.cityloaderflag = true;
            this.stepsenineteen.nineteencity = '';
            this.stepsenineteen.nineteenarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityNineteenId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatetwentyId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsetwenty.twentystate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateTwentyId(event) {
            this.cityloaderflag = true;
            this.stepsetwenty.twentycity = '';
            this.stepsetwenty.twentyarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityTwentyId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatetwentyoneId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsetwentyone.twentyonestate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateTwentyoneId(event) {
            this.cityloaderflag = true;
            this.stepsetwentyone.twentyonecity = '';
            this.stepsetwentyone.twentyonearea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityTwentyoneId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatetwentytwoId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsetwentytwo.twentytwostate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateTwentytwoId(event) {
            this.cityloaderflag = true;
            this.stepsetwentytwo.twentytwocity = '';
            this.stepsetwentytwo.twentytwoarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityTwentytwoId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatetwentythreeId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsetwentythree.twentythreestate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateTwentythreeId(event) {
            this.cityloaderflag = true;
            this.stepsetwentythree.twentythreecity = '';
            this.stepsetwentythree.twentythreearea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityTwentythreeId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatetwentyfourId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsetwentyfour.twentyfourstate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateTwentyfourId(event) {
            this.cityloaderflag = true;
            this.stepsetwentyfour.twentyfourcity = '';
            this.stepsetwentyfour.twentyfourarea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityTwentyfourId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },
        getLoadCityByStatetwentyfiveId() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.stepsetwentyfive.twentyfivestate.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateTwentyfiveId(event) {
            this.cityloaderflag = true;
            this.stepsetwentyfive.twentyfivecity = '';
            this.stepsetwentyfive.twentyfivearea = '';
            this.ApiService.getcities({ state_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getAreaByCityTwentyfiveId(event) {
            this.arealoaderflag = true;
            this.ApiService.getareas({ city_id: event.value.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = null;
                }
            });
        },

        sendOTPBtn() {
            this.submitted = true;
            this.v$.stepopen.$validate();
            let fields = {};
            fields["ac26"] = this.stepopen.title.value;
            fields["ac27"] = this.stepopen.title.label;
            fields["ac6"] = this.stepopen.fullname;
            fields["ac7"] = this.stepopen.mobileno;
            if (!this.v$.stepopen.$error) {
                this.showotpsendLoader = true;
                this.mobileExistsErrorMsg = '';
                this.ApiService.generateHostOtp(fields).then((data) => {
                    if (data.success == true) {
                        this.hostId = data.data.ac1;
                        this.mobileotpvalue = data.data.otp;
                        this.showotpsendLoader = false;
                        this.startMobileTimer();
                        this.mobdisabledflag = true;
                        this.nameinputdisabledflag = true;
                        this.otpsendbtnhide = false;
                        this.otpsendinputshow = true;
                        var successMsg = data.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.mobileExistsErrorMsg = data.message;
                        this.showotpsendLoader = false;
                        this.mobdisabledflag = false;
                        this.nameinputdisabledflag = false;
                        this.otpsendbtnhide = true;
                        this.otpsendinputshow = false;
                    }
                });
            }
        },
        changeMobileBtn() {
            this.timerMobileInterval = null;
            this.timerMobileStatus = false;
            this.timerMobileCount = 120;
            this.mobdisabledflag = false;
            this.otpsendbtnhide = true;
            this.otpsendinputshow = false;
            this.resendOtpBtnStatus = false;
            this.showotpsendLoader = false;
            this.mobileotp = '';
            this.otpsendinputshow = false;
        },
        startMobileTimer() {
            this.timerMobileStatus = true;
            if (this.timerMobileCount > 0) {
                setTimeout(() => {
                    this.timerMobileCount--;
                    this.startMobileTimer();
                }, 1000);
                const minutes = Math.floor(this.timerMobileCount / 60);
                let seconds = this.timerMobileCount % 60;
                if (seconds < 10) {
                    this.timerMobileInterval = `0${minutes}:0${seconds}`;
                } else {
                    this.timerMobileInterval = `0${minutes}:${seconds}`;
                }
                this.resendOtpBtnStatus = false;
            } else {
                this.timerMobileCount = 120;
                this.timerMobileStatus = false;
                this.resendOtpBtnStatus = true;
            }
        },
        reSendOTPBtn() {
            this.submitted = true;
            this.v$.stepopen.$validate();
            let fields = {};
            fields["ac1"] = this.hostId;
            fields["ac26"] = this.stepopen.title.value;
            fields["ac27"] = this.stepopen.title.label;
            fields["ac6"] = this.stepopen.fullname;
            fields["ac7"] = this.stepopen.mobileno;
            if (!this.v$.stepopen.$error) {
                this.showotpresendLoader = true;
                this.ApiService.resendHostOtp(fields).then((data) => {
                    if (data.success == true) {
                        this.hostId = data.data.ac1;
                        this.showotpresendLoader = false;
                        this.mobileotp = '';
                        this.mobileotperr = '';
                        this.startMobileTimer();
                        var successMsg = data.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.showotpresendLoader = false;
                        this.resendOtpBtnStatus = false;
                    }
                });
            }
        },
        verifyOTPBtn() {
            this.showotpverifyLoader = true;
            let fields = {};
            fields["ac1"] = this.hostId;
            fields["ac26"] = this.stepopen.title.value;
            fields["ac27"] = this.stepopen.title.label;
            fields["ac6"] = this.stepopen.fullname;
            fields["ac7"] = this.stepopen.mobileno;
            fields["otp"] = this.mobileotp;
            console.log(this.hostId);
            // verifyHost OTp
            this.ApiService.verifyHostOtp(fields).then((items) => {
                if (items.success === true) {
                    this.hostusername = items.data.ad1;
                    this.hostuserpassword = items.data.ad17;
                    this.hostId = items.data.ac1;
                    this.userCompanyFk = items.data.ad1;
                    this.showotpverifyLoader = false;
                    this.otpsendinputshow = false;
                    this.verifiedsuccessotp = true;
                    this.mobileotp = '';
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                } else {
                    this.mobileotp = '';
                    this.mobileotperr = items.message;
                    this.timerMobileInterval = null;
                    this.timerMobileStatus = false;
                    this.timerMobileCount = 0;
                    this.showotpverifyLoader = false;
                    this.otpsendinputshow = true;
                    this.resendOtpBtnStatus = true;
                    // this.hideotpinput = true;
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                }
            });
        },
        firstWizardStepBtn() {
            this.submitted = true;
            this.v$.stepf.$validate();
            let fields = {};
            fields['ac1'] = this.hostId;
            fields['ad1'] = this.userCompanyFk;
            fields['ac29'] = this.stepf.maplink;
            fields['ac3'] = this.stepf.brandname;
            fields['ac36'] = this.stepf.isgstnumberflag;
            if (this.stepf.designation) {
                    fields['ac8'] = this.stepf.designation.value;
                    fields['ac9'] = this.stepf.designation.label;
                }
            if(this.stepf.isgstnumberflag == 1){
                fields['ac4'] = this.stepf.gstnumber;
            }
            if(this.stepf.isgstnumberflag == 2){
                if (this.stepf.pannumber) {
                    fields['ac5'] = this.stepf.pannumber;
                }
                fields['ac28'] = this.stepf.legalname;
                fields['ac22'] = this.stepf.entitytype.value;
                fields['ac23'] = this.stepf.entitytype.label;
                fields['ac10'] = this.stepf.state.value;
                fields['ac11'] = this.stepf.state.label;
                fields['ac12'] = this.stepf.city.value;
                fields['ac13'] = this.stepf.city.label;
                fields['ac14'] = this.stepf.area.value;
                fields['ac15'] = this.stepf.area.label;
                fields['ac21'] = this.stepf.address;
                fields['ac16'] = this.stepf.pincode;
            }
            if (!this.v$.stepf.$error && !this.errmsg.pannumber) {
                this.showfirststepLoader = true;

                this.ApiService.updateCompanyInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.hostId = items.data.ac1;
                        this.firststephostdetails = items.data;
                        this.maplinkhostvalue = this.firststephostdetails.ac29;
                        this.gstnumberhostvalue = this.firststephostdetails.ac4;
                        this.pannumberhostvalue = this.firststephostdetails.ac5;
                        this.brandnamehostvalue = this.firststephostdetails.ac3;
                        this.legalnamehostvalue = this.firststephostdetails.ac28;
                        this.entitytypehostvalue = this.firststephostdetails.ac23;
                        this.statehostvalue = this.firststephostdetails.ac11;
                        this.cityhostvalue = this.firststephostdetails.ac13;
                        this.areahostvalue = this.firststephostdetails.ac15;
                        this.addresshostvalue = this.firststephostdetails.ac21;
                        this.pincodehostvalue = this.firststephostdetails.ac16;
                        this.showfirststepLoader = false;
                        this.submitted = false;
                        this.active = 1;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showfirststepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        backToWizardStepOnebranchBtn() {
            this.active = 0;
        },
        backToWizardStepTwobranchBtn() {
            this.active = 1;
        },
        secondWizardStepSingleBtn() {
            this.submitted = true;
            this.v$.stepse.$validate();
            let fields = {};
            if(this.singalBranchEditFkValue){
                fields['ae1'] = this.singalBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            if (this.stepse.branchcatagory) {
                fields['ae13'] = this.stepse.branchcatagory.value;
                fields['ae14'] = this.stepse.branchcatagory.label;
            }
            if (this.stepf.designation) {
                fields['ac8'] = this.stepf.designation.value;
                fields['ac9'] = this.stepf.designation.label;
            }
            fields['ae15'] = 1;
            fields['ae5'] = this.stepf.brandname;
            fields['ae16'] = this.stepf.state.value;
            fields['ae17'] = this.stepf.state.label;
            fields['ae6'] = this.stepf.city.value;
            fields['ae7'] = this.stepf.city.label;
            fields['ae8'] = this.stepf.area.value;
            fields['ae9'] = this.stepf.area.label;
            fields['ae11'] = this.stepf.address;
            fields['ae10'] = this.stepf.pincode;
            fields['ae12'] = this.stepf.maplink;
            if (!this.v$.stepse.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                    this.singalBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        this.active = 2;
                        this.getHostBranches({ ac1: this.hostId });
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepOnebranchBtn() {
            this.submitted = true;
            this.v$.stepseone.$validate();
            let fields = {};
            if(this.oneBranchEditFkValue){
                fields['ae1'] = this.oneBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepseone.onebranchname) {
                fields['ae5'] = this.stepseone.onebranchname;
            }
            if (this.stepseone.onebranchcatagory) {
                fields['ae13'] = this.stepseone.onebranchcatagory.value;
                fields['ae14'] = this.stepseone.onebranchcatagory.label;
            }
            if (this.stepseone.onesameaddressstatus) {
                if (this.stepseone.onesameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepseone.onesameaddressstatus == false) {
                if (this.stepseone.onestate) {
                    fields['ae16'] = this.stepseone.onestate.value;
                    fields['ae17'] = this.stepseone.onestate.label;
                }
                if (this.stepseone.onecity) {
                    fields['ae6'] = this.stepseone.onecity.value;
                    fields['ae7'] = this.stepseone.onecity.label;
                }
                if (this.stepseone.onearea) {
                    fields['ae8'] = this.stepseone.onearea.value;
                    fields['ae9'] = this.stepseone.onearea.label;
                }
                if (this.stepseone.oneaddress) {
                    fields['ae11'] = this.stepseone.oneaddress;
                }
                if (this.stepseone.onepincode) {
                    fields['ae10'] = this.stepseone.onepincode;
                }
                if (this.stepseone.onemaplink) {
                    fields['ae12'] = this.stepseone.onemaplink;
                }
            }
            if (!this.v$.stepseone.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.oneBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        this.activebranch = 1;
                        this.getLoadCityByStateTwoId();
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepTwobranchBtn() {
            this.submitted = true;
            this.v$.stepsetwo.$validate();
            let fields = {};
            if(this.twoBranchEditFkValue){
                fields['ae1'] = this.twoBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsetwo.twobranchname) {
                fields['ae5'] = this.stepsetwo.twobranchname;
            }
            if (this.stepsetwo.twobranchcatagory) {
                fields['ae13'] = this.stepsetwo.twobranchcatagory.value;
                fields['ae14'] = this.stepsetwo.twobranchcatagory.label;
            }
            if (this.stepsetwo.twosameaddressstatus) {
                if (this.stepsetwo.twosameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsetwo.twosameaddressstatus == false) {
                if (this.stepsetwo.twostate) {
                    fields['ae16'] = this.stepsetwo.twostate.value;
                    fields['ae17'] = this.stepsetwo.twostate.label;
                }
                if (this.stepsetwo.twocity) {
                    fields['ae6'] = this.stepsetwo.twocity.value;
                    fields['ae7'] = this.stepsetwo.twocity.label;
                }
                if (this.stepsetwo.twoarea) {
                    fields['ae8'] = this.stepsetwo.twoarea.value;
                    fields['ae9'] = this.stepsetwo.twoarea.label;
                }
                if (this.stepsetwo.twoaddress) {
                    fields['ae11'] = this.stepsetwo.twoaddress;
                }
                if (this.stepsetwo.twopincode) {
                    fields['ae10'] = this.stepsetwo.twopincode;
                }
                if (this.stepsetwo.twomaplink) {
                    fields['ae12'] = this.stepsetwo.twomaplink;
                }
            }
            if (!this.v$.stepsetwo.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.twoBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 2) {
                            this.activebranch = 2;
                            this.getLoadCityByStateThreeId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepThreebranchBtn() {
            this.submitted = true;
            this.v$.stepsethree.$validate();
            let fields = {};
            if(this.threeBranchEditFkValue){
                fields['ae1'] = this.threeBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsethree.threebranchname) {
                fields['ae5'] = this.stepsethree.threebranchname;
            }
            if (this.stepsethree.threebranchcatagory) {
                fields['ae13'] = this.stepsethree.threebranchcatagory.value;
                fields['ae14'] = this.stepsethree.threebranchcatagory.label;
            }
            if (this.stepsethree.threesameaddressstatus) {
                if (this.stepsethree.threesameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsethree.threesameaddressstatus == false) {
                if (this.stepsethree.threestate) {
                    fields['ae16'] = this.stepsethree.threestate.value;
                    fields['ae17'] = this.stepsethree.threestate.label;
                }
                if (this.stepsethree.threecity) {
                    fields['ae6'] = this.stepsethree.threecity.value;
                    fields['ae7'] = this.stepsethree.threecity.label;
                }
                if (this.stepsethree.threearea) {
                    fields['ae8'] = this.stepsethree.threearea.value;
                    fields['ae9'] = this.stepsethree.threearea.label;
                }
                if (this.stepsethree.threeaddress) {
                    fields['ae11'] = this.stepsethree.threeaddress;
                }
                if (this.stepsethree.threepincode) {
                    fields['ae10'] = this.stepsethree.threepincode;
                }
                if (this.stepsethree.threemaplink) {
                    fields['ae12'] = this.stepsethree.threemaplink;
                }
            }
            if (!this.v$.stepsethree.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.threeBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 3) {
                            this.activebranch = 3;
                            this.getLoadCityByStateFourId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepFourbranchBtn() {
            this.submitted = true;
            this.v$.stepsefour.$validate();
            let fields = {};
            if(this.fourBranchEditFkValue){
                fields['ae1'] = this.fourBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsefour.fourbranchname) {
                fields['ae5'] = this.stepsefour.fourbranchname;
            }
            if (this.stepsefour.fourbranchcatagory) {
                fields['ae13'] = this.stepsefour.fourbranchcatagory.value;
                fields['ae14'] = this.stepsefour.fourbranchcatagory.label;
            }
            if (this.stepsefour.foursameaddressstatus) {
                if (this.stepsefour.foursameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsefour.foursameaddressstatus == false) {
                if (this.stepsefour.fourstate) {
                    fields['ae16'] = this.stepsefour.fourstate.value;
                    fields['ae17'] = this.stepsefour.fourstate.label;
                }
                if (this.stepsefour.fourcity) {
                    fields['ae6'] = this.stepsefour.fourcity.value;
                    fields['ae7'] = this.stepsefour.fourcity.label;
                }
                if (this.stepsefour.fourarea) {
                    fields['ae8'] = this.stepsefour.fourarea.value;
                    fields['ae9'] = this.stepsefour.fourarea.label;
                }
                if (this.stepsefour.fouraddress) {
                    fields['ae11'] = this.stepsefour.fouraddress;
                }
                if (this.stepsefour.fourpincode) {
                    fields['ae10'] = this.stepsefour.fourpincode;
                }
                if (this.stepsefour.fourmaplink) {
                    fields['ae12'] = this.stepsefour.fourmaplink;
                }
            }
            if (!this.v$.stepsefour.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.fourBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 4) {
                            this.activebranch = 4;
                            this.getLoadCityByStateFiveId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepFivebranchBtn() {
            this.submitted = true;
            this.v$.stepsefive.$validate();
            let fields = {};
            if(this.fiveBranchEditFkValue){
                fields['ae1'] = this.fiveBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsefive.fivebranchname) {
                fields['ae5'] = this.stepsefive.fivebranchname;
            }
            if (this.stepsefive.fivebranchcatagory) {
                fields['ae13'] = this.stepsefive.fivebranchcatagory.value;
                fields['ae14'] = this.stepsefive.fivebranchcatagory.label;
            }
            if (this.stepsefive.fivesameaddressstatus) {
                if (this.stepsefive.fivesameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsefive.fivesameaddressstatus == false) {
                if (this.stepsefive.fivestate) {
                    fields['ae16'] = this.stepsefive.fivestate.value;
                    fields['ae17'] = this.stepsefive.fivestate.label;
                }
                if (this.stepsefive.fivecity) {
                    fields['ae6'] = this.stepsefive.fivecity.value;
                    fields['ae7'] = this.stepsefive.fivecity.label;
                }
                if (this.stepsefive.fivearea) {
                    fields['ae8'] = this.stepsefive.fivearea.value;
                    fields['ae9'] = this.stepsefive.fivearea.label;
                }
                if (this.stepsefive.fiveaddress) {
                    fields['ae11'] = this.stepsefive.fiveaddress;
                }
                if (this.stepsefive.fivepincode) {
                    fields['ae10'] = this.stepsefive.fivepincode;
                }
                if (this.stepsefive.fivemaplink) {
                    fields['ae12'] = this.stepsefive.fivemaplink;
                }
            }
            if (!this.v$.stepsefive.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.fiveBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 5) {
                            this.activebranch = 5;
                            this.getLoadCityByStateSixId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepSixbranchBtn() {
            this.submitted = true;
            this.v$.stepsesix.$validate();
            let fields = {};
            if(this.sixBranchEditFkValue){
                fields['ae1'] = this.sixBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsesix.sixbranchname) {
                fields['ae5'] = this.stepsesix.sixbranchname;
            }
            if (this.stepsesix.sixbranchcatagory) {
                fields['ae13'] = this.stepsesix.sixbranchcatagory.value;
                fields['ae14'] = this.stepsesix.sixbranchcatagory.label;
            }
            if (this.stepsesix.sixsameaddressstatus) {
                if (this.stepsesix.sixsameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsesix.sixsameaddressstatus == false) {
                if (this.stepsesix.sixstate) {
                    fields['ae16'] = this.stepsesix.sixstate.value;
                    fields['ae17'] = this.stepsesix.sixstate.label;
                }
                if (this.stepsesix.sixcity) {
                    fields['ae6'] = this.stepsesix.sixcity.value;
                    fields['ae7'] = this.stepsesix.sixcity.label;
                }
                if (this.stepsesix.sixarea) {
                    fields['ae8'] = this.stepsesix.sixarea.value;
                    fields['ae9'] = this.stepsesix.sixarea.label;
                }
                if (this.stepsesix.sixaddress) {
                    fields['ae11'] = this.stepsesix.sixaddress;
                }
                if (this.stepsesix.sixpincode) {
                    fields['ae10'] = this.stepsesix.sixpincode;
                }
                if (this.stepsesix.sixmaplink) {
                    fields['ae12'] = this.stepsesix.sixmaplink;
                }
            }
            if (!this.v$.stepsesix.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.sixBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 6) {
                            this.activebranch = 6;
                            this.getLoadCityByStateSevenId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepSevenbranchBtn() {
            this.submitted = true;
            this.v$.stepseseven.$validate();
            let fields = {};
            if(this.sevenBranchEditFkValue){
                fields['ae1'] = this.sevenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepseseven.sevenbranchname) {
                fields['ae5'] = this.stepseseven.sevenbranchname;
            }
            if (this.stepseseven.sevenbranchcatagory) {
                fields['ae13'] = this.stepseseven.sevenbranchcatagory.value;
                fields['ae14'] = this.stepseseven.sevenbranchcatagory.label;
            }
            if (this.stepseseven.sevensameaddressstatus) {
                if (this.stepseseven.sevensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepseseven.sevensameaddressstatus == false) {
                if (this.stepseseven.sevenstate) {
                    fields['ae16'] = this.stepseseven.sevenstate.value;
                    fields['ae17'] = this.stepseseven.sevenstate.label;
                }
                if (this.stepseseven.sevencity) {
                    fields['ae6'] = this.stepseseven.sevencity.value;
                    fields['ae7'] = this.stepseseven.sevencity.label;
                }
                if (this.stepseseven.sevenarea) {
                    fields['ae8'] = this.stepseseven.sevenarea.value;
                    fields['ae9'] = this.stepseseven.sevenarea.label;
                }
                if (this.stepseseven.sevenaddress) {
                    fields['ae11'] = this.stepseseven.sevenaddress;
                }
                if (this.stepseseven.sevenpincode) {
                    fields['ae10'] = this.stepseseven.sevenpincode;
                }
                if (this.stepseseven.sevenmaplink) {
                    fields['ae12'] = this.stepseseven.sevenmaplink;
                }
            }
            if (!this.v$.stepseseven.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.sevenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 7) {
                            this.activebranch = 7;
                            this.getLoadCityByStateEightId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepEightbranchBtn() {
            this.submitted = true;
            this.v$.stepseeight.$validate();
            let fields = {};
            if(this.eightBranchEditFkValue){
                fields['ae1'] = this.eightBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepseeight.eightbranchname) {
                fields['ae5'] = this.stepseeight.eightbranchname;
            }
            if (this.stepseeight.eightbranchcatagory) {
                fields['ae13'] = this.stepseeight.eightbranchcatagory.value;
                fields['ae14'] = this.stepseeight.eightbranchcatagory.label;
            }
            if (this.stepseeight.eightsameaddressstatus) {
                if (this.stepseeight.eightsameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepseeight.eightsameaddressstatus == false) {
                if (this.stepseeight.eightstate) {
                    fields['ae16'] = this.stepseeight.eightstate.value;
                    fields['ae17'] = this.stepseeight.eightstate.label;
                }
                if (this.stepseeight.eightcity) {
                    fields['ae6'] = this.stepseeight.eightcity.value;
                    fields['ae7'] = this.stepseeight.eightcity.label;
                }
                if (this.stepseeight.eightarea) {
                    fields['ae8'] = this.stepseeight.eightarea.value;
                    fields['ae9'] = this.stepseeight.eightarea.label;
                }
                if (this.stepseeight.eightaddress) {
                    fields['ae11'] = this.stepseeight.eightaddress;
                }
                if (this.stepseeight.eightpincode) {
                    fields['ae10'] = this.stepseeight.eightpincode;
                }
                if (this.stepseeight.eightmaplink) {
                    fields['ae12'] = this.stepseeight.eightmaplink;
                }
            }
            if (!this.v$.stepseeight.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.eightBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 8) {
                            this.activebranch = 8;
                            this.getLoadCityByStateNineId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepNinebranchBtn() {
            this.submitted = true;
            this.v$.stepsenine.$validate();
            let fields = {};
            if(this.nineBranchEditFkValue){
                fields['ae1'] = this.nineBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsenine.ninebranchname) {
                fields['ae5'] = this.stepsenine.ninebranchname;
            }
            if (this.stepsenine.ninebranchcatagory) {
                fields['ae13'] = this.stepsenine.ninebranchcatagory.value;
                fields['ae14'] = this.stepsenine.ninebranchcatagory.label;
            }
            if (this.stepsenine.ninesameaddressstatus) {
                if (this.stepsenine.ninesameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsenine.ninesameaddressstatus == false) {
                if (this.stepsenine.ninestate) {
                    fields['ae16'] = this.stepsenine.ninestate.value;
                    fields['ae17'] = this.stepsenine.ninestate.label;
                }
                if (this.stepsenine.ninecity) {
                    fields['ae6'] = this.stepsenine.ninecity.value;
                    fields['ae7'] = this.stepsenine.ninecity.label;
                }
                if (this.stepsenine.ninearea) {
                    fields['ae8'] = this.stepsenine.ninearea.value;
                    fields['ae9'] = this.stepsenine.ninearea.label;
                }
                if (this.stepsenine.nineaddress) {
                    fields['ae11'] = this.stepsenine.nineaddress;
                }
                if (this.stepsenine.ninepincode) {
                    fields['ae10'] = this.stepsenine.ninepincode;
                }
                if (this.stepsenine.ninemaplink) {
                    fields['ae12'] = this.stepsenine.ninemaplink;
                }
            }
            if (!this.v$.stepsenine.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.nineBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 9) {
                            this.activebranch = 9;
                            this.getLoadCityByStatetenId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepTenbranchBtn() {
            this.submitted = true;
            this.v$.stepseten.$validate();
            let fields = {};
            if(this.tenBranchEditFkValue){
                fields['ae1'] = this.tenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepseten.tenbranchname) {
                fields['ae5'] = this.stepseten.tenbranchname;
            }
            if (this.stepseten.tenbranchcatagory) {
                fields['ae13'] = this.stepseten.tenbranchcatagory.value;
                fields['ae14'] = this.stepseten.tenbranchcatagory.label;
            }
            if (this.stepseten.tensameaddressstatus) {
                if (this.stepseten.tensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepseten.tensameaddressstatus == false) {
                if (this.stepseten.tenstate) {
                    fields['ae16'] = this.stepseten.tenstate.value;
                    fields['ae17'] = this.stepseten.tenstate.label;
                }
                if (this.stepseten.tencity) {
                    fields['ae6'] = this.stepseten.tencity.value;
                    fields['ae7'] = this.stepseten.tencity.label;
                }
                if (this.stepseten.tenarea) {
                    fields['ae8'] = this.stepseten.tenarea.value;
                    fields['ae9'] = this.stepseten.tenarea.label;
                }
                if (this.stepseten.tenaddress) {
                    fields['ae11'] = this.stepseten.tenaddress;
                }
                if (this.stepseten.tenpincode) {
                    fields['ae10'] = this.stepseten.tenpincode;
                }
                if (this.stepseten.tenmaplink) {
                    fields['ae12'] = this.stepseten.tenmaplink;
                }
            }
            if (!this.v$.stepseten.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.tenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 10) {
                            this.activebranch = 10;
                            this.getLoadCityByStateelevenId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepElevenbranchBtn() {
            this.submitted = true;
            this.v$.stepseeleven.$validate();
            let fields = {};
            if(this.elevenBranchEditFkValue){
                fields['ae1'] = this.elevenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepseeleven.elevenbranchname) {
                fields['ae5'] = this.stepseeleven.elevenbranchname;
            }
            if (this.stepseeleven.elevenbranchcatagory) {
                fields['ae13'] = this.stepseeleven.elevenbranchcatagory.value;
                fields['ae14'] = this.stepseeleven.elevenbranchcatagory.label;
            }
            if (this.stepseeleven.elevensameaddressstatus) {
                if (this.stepseeleven.elevensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepseeleven.elevensameaddressstatus == false) {
                if (this.stepseeleven.elevenstate) {
                    fields['ae16'] = this.stepseeleven.elevenstate.value;
                    fields['ae17'] = this.stepseeleven.elevenstate.label;
                }
                if (this.stepseeleven.elevencity) {
                    fields['ae6'] = this.stepseeleven.elevencity.value;
                    fields['ae7'] = this.stepseeleven.elevencity.label;
                }
                if (this.stepseeleven.elevenarea) {
                    fields['ae8'] = this.stepseeleven.elevenarea.value;
                    fields['ae9'] = this.stepseeleven.elevenarea.label;
                }
                if (this.stepseeleven.elevenaddress) {
                    fields['ae11'] = this.stepseeleven.elevenaddress;
                }
                if (this.stepseeleven.elevenpincode) {
                    fields['ae10'] = this.stepseeleven.elevenpincode;
                }
                if (this.stepseeleven.elevenmaplink) {
                    fields['ae12'] = this.stepseeleven.elevenmaplink;
                }
            }
            if (!this.v$.stepseeleven.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.elevenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 11) {
                            this.activebranch = 11;
                            this.getLoadCityByStatetwelveId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepTwelvebranchBtn() {
            this.submitted = true;
            this.v$.stepsetwelve.$validate();
            let fields = {};
            if(this.twelveBranchEditFkValue){
                fields['ae1'] = this.twelveBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsetwelve.twelvebranchname) {
                fields['ae5'] = this.stepsetwelve.twelvebranchname;
            }
            if (this.stepsetwelve.twelvebranchcatagory) {
                fields['ae13'] = this.stepsetwelve.twelvebranchcatagory.value;
                fields['ae14'] = this.stepsetwelve.twelvebranchcatagory.label;
            }
            if (this.stepsetwelve.twelvesameaddressstatus) {
                if (this.stepsetwelve.twelvesameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsetwelve.twelvesameaddressstatus == false) {
                if (this.stepsetwelve.twelvestate) {
                    fields['ae16'] = this.stepsetwelve.twelvestate.value;
                    fields['ae17'] = this.stepsetwelve.twelvestate.label;
                }
                if (this.stepsetwelve.twelvecity) {
                    fields['ae6'] = this.stepsetwelve.twelvecity.value;
                    fields['ae7'] = this.stepsetwelve.twelvecity.label;
                }
                if (this.stepsetwelve.twelvearea) {
                    fields['ae8'] = this.stepsetwelve.twelvearea.value;
                    fields['ae9'] = this.stepsetwelve.twelvearea.label;
                }
                if (this.stepsetwelve.twelveaddress) {
                    fields['ae11'] = this.stepsetwelve.twelveaddress;
                }
                if (this.stepsetwelve.twelvepincode) {
                    fields['ae10'] = this.stepsetwelve.twelvepincode;
                }
                if (this.stepsetwelve.twelvemaplink) {
                    fields['ae12'] = this.stepsetwelve.twelvemaplink;
                }
            }
            if (!this.v$.stepsetwelve.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.twelveBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 12) {
                            this.activebranch = 12;
                            this.getLoadCityByStatethirteeId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepThirteenbranchBtn() {
            this.submitted = true;
            this.v$.stepsethirteen.$validate();
            let fields = {};
            if(this.thirteenBranchEditFkValue){
                fields['ae1'] = this.thirteenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsethirteen.thirteenbranchname) {
                fields['ae5'] = this.stepsethirteen.thirteenbranchname;
            }
            if (this.stepsethirteen.thirteenbranchcatagory) {
                fields['ae13'] = this.stepsethirteen.thirteenbranchcatagory.value;
                fields['ae14'] = this.stepsethirteen.thirteenbranchcatagory.label;
            }
            if (this.stepsethirteen.thirteensameaddressstatus) {
                if (this.stepsethirteen.thirteensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsethirteen.thirteensameaddressstatus == false) {
                if (this.stepsethirteen.thirteenstate) {
                    fields['ae16'] = this.stepsethirteen.thirteenstate.value;
                    fields['ae17'] = this.stepsethirteen.thirteenstate.label;
                }
                if (this.stepsethirteen.thirteencity) {
                    fields['ae6'] = this.stepsethirteen.thirteencity.value;
                    fields['ae7'] = this.stepsethirteen.thirteencity.label;
                }
                if (this.stepsethirteen.thirteenarea) {
                    fields['ae8'] = this.stepsethirteen.thirteenarea.value;
                    fields['ae9'] = this.stepsethirteen.thirteenarea.label;
                }
                if (this.stepsethirteen.thirteenaddress) {
                    fields['ae11'] = this.stepsethirteen.thirteenaddress;
                }
                if (this.stepsethirteen.thirteenpincode) {
                    fields['ae10'] = this.stepsethirteen.thirteenpincode;
                }
                if (this.stepsethirteen.thirteenmaplink) {
                    fields['ae12'] = this.stepsethirteen.thirteenmaplink;
                }
            }
            if (!this.v$.stepsethirteen.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.thirteenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 13) {
                            this.activebranch = 13;
                            this.getLoadCityByStatefourteenId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepFourteenbranchBtn() {
            this.submitted = true;
            this.v$.stepsefourteen.$validate();
            let fields = {};
            if(this.fourteenBranchEditFkValue){
                fields['ae1'] = this.fourteenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsefourteen.fourteenbranchname) {
                fields['ae5'] = this.stepsefourteen.fourteenbranchname;
            }
            if (this.stepsefourteen.fourteenbranchcatagory) {
                fields['ae13'] = this.stepsefourteen.fourteenbranchcatagory.value;
                fields['ae14'] = this.stepsefourteen.fourteenbranchcatagory.label;
            }
            if (this.stepsefourteen.fourteensameaddressstatus) {
                if (this.stepsefourteen.fourteensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsefourteen.fourteensameaddressstatus == false) {
                if (this.stepsefourteen.fourteenstate) {
                    fields['ae16'] = this.stepsefourteen.fourteenstate.value;
                    fields['ae17'] = this.stepsefourteen.fourteenstate.label;
                }
                if (this.stepsefourteen.fourteencity) {
                    fields['ae6'] = this.stepsefourteen.fourteencity.value;
                    fields['ae7'] = this.stepsefourteen.fourteencity.label;
                }
                if (this.stepsefourteen.fourteenarea) {
                    fields['ae8'] = this.stepsefourteen.fourteenarea.value;
                    fields['ae9'] = this.stepsefourteen.fourteenarea.label;
                }
                if (this.stepsefourteen.fourteenaddress) {
                    fields['ae11'] = this.stepsefourteen.fourteenaddress;
                }
                if (this.stepsefourteen.fourteenpincode) {
                    fields['ae10'] = this.stepsefourteen.fourteenpincode;
                }
                if (this.stepsefourteen.fourteenmaplink) {
                    fields['ae12'] = this.stepsefourteen.fourteenmaplink;
                }
            }
            if (!this.v$.stepsefourteen.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.fourteenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 14) {
                            this.activebranch = 14;
                            this.getLoadCityByStatefifteenId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepFifteenbranchBtn() {
            this.submitted = true;
            this.v$.stepsefifteen.$validate();
            let fields = {};
            if(this.fifteenBranchEditFkValue){
                fields['ae1'] = this.fifteenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsefifteen.fifteenbranchname) {
                fields['ae5'] = this.stepsefifteen.fifteenbranchname;
            }
            if (this.stepsefifteen.fifteenbranchcatagory) {
                fields['ae13'] = this.stepsefifteen.fifteenbranchcatagory.value;
                fields['ae14'] = this.stepsefifteen.fifteenbranchcatagory.label;
            }
            if (this.stepsefifteen.fifteensameaddressstatus) {
                if (this.stepsefifteen.fifteensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsefifteen.fifteensameaddressstatus == false) {
                if (this.stepsefifteen.fifteenstate) {
                    fields['ae16'] = this.stepsefifteen.fifteenstate.value;
                    fields['ae17'] = this.stepsefifteen.fifteenstate.label;
                }
                if (this.stepsefifteen.fifteencity) {
                    fields['ae6'] = this.stepsefifteen.fifteencity.value;
                    fields['ae7'] = this.stepsefifteen.fifteencity.label;
                }
                if (this.stepsefifteen.fifteenarea) {
                    fields['ae8'] = this.stepsefifteen.fifteenarea.value;
                    fields['ae9'] = this.stepsefifteen.fifteenarea.label;
                }
                if (this.stepsefifteen.fifteenaddress) {
                    fields['ae11'] = this.stepsefifteen.fifteenaddress;
                }
                if (this.stepsefifteen.fifteenpincode) {
                    fields['ae10'] = this.stepsefifteen.fifteenpincode;
                }
                if (this.stepsefifteen.fifteenmaplink) {
                    fields['ae12'] = this.stepsefifteen.fifteenmaplink;
                }
            }
            if (!this.v$.stepsefifteen.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.fifteenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 15) {
                            this.activebranch = 15;
                            this.getLoadCityByStatesixteenId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepSixteenbranchBtn() {
            this.submitted = true;
            this.v$.stepsesixteen.$validate();
            let fields = {};
            if(this.sixteenBranchEditFkValue){
                fields['ae1'] = this.sixteenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsesixteen.sixteenbranchname) {
                fields['ae5'] = this.stepsesixteen.sixteenbranchname;
            }
            if (this.stepsesixteen.sixteenbranchcatagory) {
                fields['ae13'] = this.stepsesixteen.sixteenbranchcatagory.value;
                fields['ae14'] = this.stepsesixteen.sixteenbranchcatagory.label;
            }
            if (this.stepsesixteen.sixteensameaddressstatus) {
                if (this.stepsesixteen.sixteensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsesixteen.sixteensameaddressstatus == false) {
                if (this.stepsesixteen.sixteenstate) {
                    fields['ae16'] = this.stepsesixteen.sixteenstate.value;
                    fields['ae17'] = this.stepsesixteen.sixteenstate.label;
                }
                if (this.stepsesixteen.sixteencity) {
                    fields['ae6'] = this.stepsesixteen.sixteencity.value;
                    fields['ae7'] = this.stepsesixteen.sixteencity.label;
                }
                if (this.stepsesixteen.sixteenarea) {
                    fields['ae8'] = this.stepsesixteen.sixteenarea.value;
                    fields['ae9'] = this.stepsesixteen.sixteenarea.label;
                }
                if (this.stepsesixteen.sixteenaddress) {
                    fields['ae11'] = this.stepsesixteen.sixteenaddress;
                }
                if (this.stepsesixteen.sixteenpincode) {
                    fields['ae10'] = this.stepsesixteen.sixteenpincode;
                }
                if (this.stepsesixteen.sixteenmaplink) {
                    fields['ae12'] = this.stepsesixteen.sixteenmaplink;
                }
            }
            if (!this.v$.stepsesixteen.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.sixteenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 16) {
                            this.activebranch = 16;
                            this.getLoadCityByStateseventeenId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepSeventeennbranchBtn() {
            this.submitted = true;
            this.v$.stepseseventeen.$validate();
            let fields = {};
            if(this.seventeenBranchEditFkValue){
                fields['ae1'] = this.seventeenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepseseventeen.seventeenbranchname) {
                fields['ae5'] = this.stepseseventeen.seventeenbranchname;
            }
            if (this.stepseseventeen.seventeenbranchcatagory) {
                fields['ae13'] = this.stepseseventeen.seventeenbranchcatagory.value;
                fields['ae14'] = this.stepseseventeen.seventeenbranchcatagory.label;
            }
            if (this.stepseseventeen.seventeensameaddressstatus) {
                if (this.stepseseventeen.seventeensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepseseventeen.seventeensameaddressstatus == false) {
                if (this.stepseseventeen.seventeenstate) {
                    fields['ae16'] = this.stepseseventeen.seventeenstate.value;
                    fields['ae17'] = this.stepseseventeen.seventeenstate.label;
                }
                if (this.stepseseventeen.seventeencity) {
                    fields['ae6'] = this.stepseseventeen.seventeencity.value;
                    fields['ae7'] = this.stepseseventeen.seventeencity.label;
                }
                if (this.stepseseventeen.seventeenarea) {
                    fields['ae8'] = this.stepseseventeen.seventeenarea.value;
                    fields['ae9'] = this.stepseseventeen.seventeenarea.label;
                }
                if (this.stepseseventeen.seventeenaddress) {
                    fields['ae11'] = this.stepseseventeen.seventeenaddress;
                }
                if (this.stepseseventeen.seventeenpincode) {
                    fields['ae10'] = this.stepseseventeen.seventeenpincode;
                }
                if (this.stepseseventeen.seventeenmaplink) {
                    fields['ae12'] = this.stepseseventeen.seventeenmaplink;
                }
            }
            if (!this.v$.stepseseventeen.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.seventeenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 17) {
                            this.activebranch = 17;
                            this.getLoadCityByStateeighteenId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepEighteenbranchBtn() {
            this.submitted = true;
            this.v$.stepseeighteen.$validate();
            let fields = {};
            if(this.eighteenBranchEditFkValue){
                fields['ae1'] = this.eighteenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepseeighteen.eighteenbranchname) {
                fields['ae5'] = this.stepseeighteen.eighteenbranchname;
            }
            if (this.stepseeighteen.eighteenbranchcatagory) {
                fields['ae13'] = this.stepseeighteen.eighteenbranchcatagory.value;
                fields['ae14'] = this.stepseeighteen.eighteenbranchcatagory.label;
            }
            if (this.stepseeighteen.eighteensameaddressstatus) {
                if (this.stepseeighteen.eighteensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepseeighteen.eighteensameaddressstatus == false) {
                if (this.stepseeighteen.eighteenstate) {
                    fields['ae16'] = this.stepseeighteen.eighteenstate.value;
                    fields['ae17'] = this.stepseeighteen.eighteenstate.label;
                }
                if (this.stepseeighteen.eighteencity) {
                    fields['ae6'] = this.stepseeighteen.eighteencity.value;
                    fields['ae7'] = this.stepseeighteen.eighteencity.label;
                }
                if (this.stepseeighteen.eighteenarea) {
                    fields['ae8'] = this.stepseeighteen.eighteenarea.value;
                    fields['ae9'] = this.stepseeighteen.eighteenarea.label;
                }
                if (this.stepseeighteen.eighteenaddress) {
                    fields['ae11'] = this.stepseeighteen.eighteenaddress;
                }
                if (this.stepseeighteen.eighteenpincode) {
                    fields['ae10'] = this.stepseeighteen.eighteenpincode;
                }
                if (this.stepseeighteen.eighteenmaplink) {
                    fields['ae12'] = this.stepseeighteen.eighteenmaplink;
                }
            }
            if (!this.v$.stepseeighteen.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.eighteenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 18) {
                            this.activebranch = 18;
                            this.getLoadCityByStatenineteenId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepNineteenbranchBtn() {
            this.submitted = true;
            this.v$.stepsenineteen.$validate();
            let fields = {};
            if(this.nineteenBranchEditFkValue){
                fields['ae1'] = this.nineteenBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsenineteen.nineteenbranchname) {
                fields['ae5'] = this.stepsenineteen.nineteenbranchname;
            }
            if (this.stepsenineteen.nineteenbranchcatagory) {
                fields['ae13'] = this.stepsenineteen.nineteenbranchcatagory.value;
                fields['ae14'] = this.stepsenineteen.nineteenbranchcatagory.label;
            }
            if (this.stepsenineteen.nineteensameaddressstatus) {
                if (this.stepsenineteen.nineteensameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsenineteen.nineteensameaddressstatus == false) {
                if (this.stepsenineteen.nineteenstate) {
                    fields['ae16'] = this.stepsenineteen.nineteenstate.value;
                    fields['ae17'] = this.stepsenineteen.nineteenstate.label;
                }
                if (this.stepsenineteen.nineteencity) {
                    fields['ae6'] = this.stepsenineteen.nineteencity.value;
                    fields['ae7'] = this.stepsenineteen.nineteencity.label;
                }
                if (this.stepsenineteen.nineteenarea) {
                    fields['ae8'] = this.stepsenineteen.nineteenarea.value;
                    fields['ae9'] = this.stepsenineteen.nineteenarea.label;
                }
                if (this.stepsenineteen.nineteenaddress) {
                    fields['ae11'] = this.stepsenineteen.nineteenaddress;
                }
                if (this.stepsenineteen.nineteenpincode) {
                    fields['ae10'] = this.stepsenineteen.nineteenpincode;
                }
                if (this.stepsenineteen.nineteenmaplink) {
                    fields['ae12'] = this.stepsenineteen.nineteenmaplink;
                }
            }
            if (!this.v$.stepsenineteen.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.nineteenBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 19) {
                            this.activebranch = 19;
                            this.getLoadCityByStatetwentyId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepTwentynbranchBtn() {
            this.submitted = true;
            this.v$.stepsetwenty.$validate();
            let fields = {};
            if(this.twentyBranchEditFkValue){
                fields['ae1'] = this.twentyBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsetwenty.twentybranchname) {
                fields['ae5'] = this.stepsetwenty.twentybranchname;
            }
            if (this.stepsetwenty.twentybranchcatagory) {
                fields['ae13'] = this.stepsetwenty.twentybranchcatagory.value;
                fields['ae14'] = this.stepsetwenty.twentybranchcatagory.label;
            }
            if (this.stepsetwenty.twentysameaddressstatus) {
                if (this.stepsetwenty.twentysameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsetwenty.twentysameaddressstatus == false) {
                if (this.stepsetwenty.twentystate) {
                    fields['ae16'] = this.stepsetwenty.twentystate.value;
                    fields['ae17'] = this.stepsetwenty.twentystate.label;
                }
                if (this.stepsetwenty.twentycity) {
                    fields['ae6'] = this.stepsetwenty.twentycity.value;
                    fields['ae7'] = this.stepsetwenty.twentycity.label;
                }
                if (this.stepsetwenty.twentyarea) {
                    fields['ae8'] = this.stepsetwenty.twentyarea.value;
                    fields['ae9'] = this.stepsetwenty.twentyarea.label;
                }
                if (this.stepsetwenty.twentyaddress) {
                    fields['ae11'] = this.stepsetwenty.twentyaddress;
                }
                if (this.stepsetwenty.twentypincode) {
                    fields['ae10'] = this.stepsetwenty.twentypincode;
                }
                if (this.stepsetwenty.twentymaplink) {
                    fields['ae12'] = this.stepsetwenty.twentymaplink;
                }
            }
            if (!this.v$.stepsetwenty.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.twentyBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 20) {
                            this.activebranch = 20;
                            this.getLoadCityByStatetwentyoneId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepTwentyonebranchBtn() {
            this.submitted = true;
            this.v$.stepsetwentyone.$validate();
            let fields = {};
            if(this.twentyoneBranchEditFkValue){
                fields['ae1'] = this.twentyoneBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsetwentyone.twentyonebranchname) {
                fields['ae5'] = this.stepsetwentyone.twentyonebranchname;
            }
            if (this.stepsetwentyone.twentyonebranchcatagory) {
                fields['ae13'] = this.stepsetwentyone.twentyonebranchcatagory.value;
                fields['ae14'] = this.stepsetwentyone.twentyonebranchcatagory.label;
            }
            if (this.stepsetwentyone.twentyonesameaddressstatus) {
                if (this.stepsetwentyone.twentyonesameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsetwentyone.twentyonesameaddressstatus == false) {
                if (this.stepsetwentyone.twentyonestate) {
                    fields['ae16'] = this.stepsetwentyone.twentyonestate.value;
                    fields['ae17'] = this.stepsetwentyone.twentyonestate.label;
                }
                if (this.stepsetwentyone.twentyonecity) {
                    fields['ae6'] = this.stepsetwentyone.twentyonecity.value;
                    fields['ae7'] = this.stepsetwentyone.twentyonecity.label;
                }
                if (this.stepsetwentyone.twentyonearea) {
                    fields['ae8'] = this.stepsetwentyone.twentyonearea.value;
                    fields['ae9'] = this.stepsetwentyone.twentyonearea.label;
                }
                if (this.stepsetwentyone.twentyoneaddress) {
                    fields['ae11'] = this.stepsetwentyone.twentyoneaddress;
                }
                if (this.stepsetwentyone.twentyonepincode) {
                    fields['ae10'] = this.stepsetwentyone.twentyonepincode;
                }
                if (this.stepsetwentyone.twentyonemaplink) {
                    fields['ae12'] = this.stepsetwentyone.twentyonemaplink;
                }
            }
            if (!this.v$.stepsetwentyone.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.twentyoneBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 21) {
                            this.activebranch = 21;
                            this.getLoadCityByStatetwentytwoId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepTwentytwobranchBtn() {
            this.submitted = true;
            this.v$.stepsetwentytwo.$validate();
            let fields = {};
            if(this.twentytwoBranchEditFkValue){
                fields['ae1'] = this.twentytwoBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsetwentytwo.twentytwobranchname) {
                fields['ae5'] = this.stepsetwentytwo.twentytwobranchname;
            }
            if (this.stepsetwentytwo.twentytwobranchcatagory) {
                fields['ae13'] = this.stepsetwentytwo.twentytwobranchcatagory.value;
                fields['ae14'] = this.stepsetwentytwo.twentytwobranchcatagory.label;
            }
            if (this.stepsetwentytwo.twentytwosameaddressstatus) {
                if (this.stepsetwentytwo.twentytwosameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsetwentytwo.twentytwosameaddressstatus == false) {
                if (this.stepsetwentytwo.twentytwostate) {
                    fields['ae16'] = this.stepsetwentytwo.twentytwostate.value;
                    fields['ae17'] = this.stepsetwentytwo.twentytwostate.label;
                }
                if (this.stepsetwentytwo.twentytwocity) {
                    fields['ae6'] = this.stepsetwentytwo.twentytwocity.value;
                    fields['ae7'] = this.stepsetwentytwo.twentytwocity.label;
                }
                if (this.stepsetwentytwo.twentytwoarea) {
                    fields['ae8'] = this.stepsetwentytwo.twentytwoarea.value;
                    fields['ae9'] = this.stepsetwentytwo.twentytwoarea.label;
                }
                if (this.stepsetwentytwo.twentytwoaddress) {
                    fields['ae11'] = this.stepsetwentytwo.twentytwoaddress;
                }
                if (this.stepsetwentytwo.twentytwopincode) {
                    fields['ae10'] = this.stepsetwentytwo.twentytwopincode;
                }
                if (this.stepsetwentytwo.twentytwomaplink) {
                    fields['ae12'] = this.stepsetwentytwo.twentytwomaplink;
                }
            }
            if (!this.v$.stepsetwentytwo.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.twentytwoBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 22) {
                            this.activebranch = 22;
                            this.getLoadCityByStatetwentythreeId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepTwentythreebranchBtn() {
            this.submitted = true;
            this.v$.stepsetwentythree.$validate();
            let fields = {};
            if(this.twentythreeBranchEditFkValue){
                fields['ae1'] = this.twentythreeBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsetwentythree.twentythreebranchname) {
                fields['ae5'] = this.stepsetwentythree.twentythreebranchname;
            }
            if (this.stepsetwentythree.twentythreebranchcatagory) {
                fields['ae13'] = this.stepsetwentythree.twentythreebranchcatagory.value;
                fields['ae14'] = this.stepsetwentythree.twentythreebranchcatagory.label;
            }
            if (this.stepsetwentythree.twentythreesameaddressstatus) {
                if (this.stepsetwentythree.twentythreesameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsetwentythree.twentythreesameaddressstatus == false) {
                if (this.stepsetwentythree.twentythreestate) {
                    fields['ae16'] = this.stepsetwentythree.twentythreestate.value;
                    fields['ae17'] = this.stepsetwentythree.twentythreestate.label;
                }
                if (this.stepsetwentythree.twentythreecity) {
                    fields['ae6'] = this.stepsetwentythree.twentythreecity.value;
                    fields['ae7'] = this.stepsetwentythree.twentythreecity.label;
                }
                if (this.stepsetwentythree.twentythreearea) {
                    fields['ae8'] = this.stepsetwentythree.twentythreearea.value;
                    fields['ae9'] = this.stepsetwentythree.twentythreearea.label;
                }
                if (this.stepsetwentythree.twentythreeaddress) {
                    fields['ae11'] = this.stepsetwentythree.twentythreeaddress;
                }
                if (this.stepsetwentythree.twentythreepincode) {
                    fields['ae10'] = this.stepsetwentythree.twentythreepincode;
                }
                if (this.stepsetwentythree.twentythreemaplink) {
                    fields['ae12'] = this.stepsetwentythree.twentythreemaplink;
                }
            }
            if (!this.v$.stepsetwentythree.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.twentythreeBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 23) {
                            this.activebranch = 23;
                            this.getLoadCityByStatetwentyfourId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepTwentyfourbranchBtn() {
            this.submitted = true;
            this.v$.stepsetwentyfour.$validate();
            let fields = {};
            if(this.twentyfourBranchEditFkValue){
                fields['ae1'] = this.twentyfourBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsetwentyfour.twentyfourbranchname) {
                fields['ae5'] = this.stepsetwentyfour.twentyfourbranchname;
            }
            if (this.stepsetwentyfour.twentyfourbranchcatagory) {
                fields['ae13'] = this.stepsetwentyfour.twentyfourbranchcatagory.value;
                fields['ae14'] = this.stepsetwentyfour.twentyfourbranchcatagory.label;
            }
            if (this.stepsetwentyfour.twentyfoursameaddressstatus) {
                if (this.stepsetwentyfour.twentyfoursameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsetwentyfour.twentyfoursameaddressstatus == false) {
                if (this.stepsetwentyfour.twentyfourstate) {
                    fields['ae16'] = this.stepsetwentyfour.twentyfourstate.value;
                    fields['ae17'] = this.stepsetwentyfour.twentyfourstate.label;
                }
                if (this.stepsetwentyfour.twentyfourcity) {
                    fields['ae6'] = this.stepsetwentyfour.twentyfourcity.value;
                    fields['ae7'] = this.stepsetwentyfour.twentyfourcity.label;
                }
                if (this.stepsetwentyfour.twentyfourarea) {
                    fields['ae8'] = this.stepsetwentyfour.twentyfourarea.value;
                    fields['ae9'] = this.stepsetwentyfour.twentyfourarea.label;
                }
                if (this.stepsetwentyfour.twentyfouraddress) {
                    fields['ae11'] = this.stepsetwentyfour.twentyfouraddress;
                }
                if (this.stepsetwentyfour.twentyfourpincode) {
                    fields['ae10'] = this.stepsetwentyfour.twentyfourpincode;
                }
                if (this.stepsetwentyfour.twentyfourmaplink) {
                    fields['ae12'] = this.stepsetwentyfour.twentyfourmaplink;
                }
            }
            if (!this.v$.stepsetwentyfour.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.twentyfourBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        if (this.stepse.branchescount.value > 24) {
                            this.activebranch = 24;
                            this.getLoadCityByStatetwentyfiveId();
                        } else {
                            this.active = 2;
                            this.getHostBranches({ ac1: this.hostId });
                        }

                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        secondWizardStepTwentyfivebranchBtn() {
            this.submitted = true;
            this.v$.stepsetwentyfive.$validate();
            let fields = {};
            if(this.twentyfiveBranchEditFkValue){
                fields['ae1'] = this.twentyfiveBranchEditFkValue;
            }
            fields['ae2'] = this.hostId;
            fields['ae4'] = this.stepf.entitytype.value;
            // first branch
            if (this.stepsetwentyfive.twentyfivebranchname) {
                fields['ae5'] = this.stepsetwentyfive.twentyfivebranchname;
            }
            if (this.stepsetwentyfive.twentyfivebranchcatagory) {
                fields['ae13'] = this.stepsetwentyfive.twentyfivebranchcatagory.value;
                fields['ae14'] = this.stepsetwentyfive.twentyfivebranchcatagory.label;
            }
            if (this.stepsetwentyfive.twentyfivesameaddressstatus) {
                if (this.stepsetwentyfive.twentyfivesameaddressstatus == true) {
                    fields['ae15'] = 1;
                } else {
                    fields['ae15'] = 0;
                }
            }
            if (this.stepsetwentyfive.twentyfivesameaddressstatus == false) {
                if (this.stepsetwentyfive.twentyfivestate) {
                    fields['ae16'] = this.stepsetwentyfive.twentyfivestate.value;
                    fields['ae17'] = this.stepsetwentyfive.twentyfivestate.label;
                }
                if (this.stepsetwentyfive.twentyfivecity) {
                    fields['ae6'] = this.stepsetwentyfive.twentyfivecity.value;
                    fields['ae7'] = this.stepsetwentyfive.twentyfivecity.label;
                }
                if (this.stepsetwentyfive.twentyfivearea) {
                    fields['ae8'] = this.stepsetwentyfive.twentyfivearea.value;
                    fields['ae9'] = this.stepsetwentyfive.twentyfivearea.label;
                }
                if (this.stepsetwentyfive.twentyfiveaddress) {
                    fields['ae11'] = this.stepsetwentyfive.twentyfiveaddress;
                }
                if (this.stepsetwentyfive.twentyfivepincode) {
                    fields['ae10'] = this.stepsetwentyfive.twentyfivepincode;
                }
                if (this.stepsetwentyfive.twentyfivemaplink) {
                    fields['ae12'] = this.stepsetwentyfive.twentyfivemaplink;
                }
            }
            if (!this.v$.stepsetwentyfive.$error) {
                this.showsecstepLoader = true;
                this.ApiService.updateBranchInfo(fields).then(items => {
                    if (items.status === 200) {
                        this.twentyfiveBranchEditFkValue = items.branchfk;
                        this.showsecstepLoader = false;
                        this.submitted = false;
                        this.active = 2;
                        this.getHostBranches({ ac1: this.hostId });
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showsecstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        removeSecondRow() {
            this.userTabActiveFlag = '';
            this.activeuser = 0;
            this.stepthtwo.secondusertitle = { value: 1, label: 'Mr.' };
            this.stepthtwo.seconduserfullname = '';
            this.stepthtwo.secondusermobileno = '';
            this.stepthtwo.seconduserdesignation = '';
            this.stepthtwo.seconduserbranches = '';
            this.stepthtwo.seconduserrole = '';
            this.twoUserEditFkValue = '';
        },
        removeThirdRow() {
            this.userTabActiveFlag = 1;
            this.activeuser = 1;
            this.stepththree.thirdusertitle = { value: 1, label: 'Mr.' };
            this.stepththree.thirduserfullname = '';
            this.stepththree.thirdusermobileno = '';
            this.stepththree.thirduserdesignation = '';
            this.stepththree.thirduserbranches = '';
            this.stepththree.thirduserrole = '';
            this.threeUserEditFkValue = '';
        },
        removeFourthRow() {
            this.userTabActiveFlag = 2;
            this.activeuser = 2;
            this.stepthfour.fourthusertitle = { value: 1, label: 'Mr.' };
            this.stepthfour.fourthuserfullname = '';
            this.stepthfour.fourthusermobileno = '';
            this.stepthfour.fourthuserdesignation = '';
            this.stepthfour.fourthuserbranches = '';
            this.stepthfour.fourthuserrole = '';
            this.fourUserEditFkValue = '';
        },
        removeFifthRow() {
            this.userTabActiveFlag = 3;
            this.activeuser = 3;
            this.stepthfive.fifthusertitle = { value: 1, label: 'Mr.' };
            this.stepthfive.fifthuserfullname = '';
            this.stepthfive.fifthusermobileno = '';
            this.stepthfive.fifthuserdesignation = '';
            this.stepthfive.fifthuserbranches = '';
            this.stepthfive.fifthuserrole = '';
            this.fiveUserEditFkValue = '';
        },
        removeSixthRow() {
            this.userTabActiveFlag = 4;
            this.activeuser = 4;
            this.stepthsix.sixthusertitle = { value: 1, label: 'Mr.' };
            this.stepthsix.sixthuserfullname = '';
            this.stepthsix.sixthusermobileno = '';
            this.stepthsix.sixthuserdesignation = '';
            this.stepthsix.sixthuserbranches = '';
            this.stepthsix.sixthuserrole = '';
            this.sixUserEditFkValue = '';
        },
        removeSeventhRow() {
            this.userTabActiveFlag = 5;
            this.activeuser = 5;
            this.stepthseven.seventhusertitle = { value: 1, label: 'Mr.' };
            this.stepthseven.seventhuserfullname = '';
            this.stepthseven.seventhusermobileno = '';
            this.stepthseven.seventhuserdesignation = '';
            this.stepthseven.seventhuserbranches = '';
            this.stepthseven.seventhuserrole = '';
            this.sevenUserEditFkValue = '';
        },
        removeEighthRow() {
            this.userTabActiveFlag = 6;
            this.activeuser = 6;
            this.steptheight.eighthusertitle = { value: 1, label: 'Mr.' };
            this.steptheight.eighthuserfullname = '';
            this.steptheight.eighthusermobileno = '';
            this.steptheight.eighthuserdesignation = '';
            this.steptheight.eighthuserbranches = '';
            this.steptheight.eighthuserrole = '';
            this.eightUserEditFkValue = '';
        },
        removeNineRow() {
            this.userTabActiveFlag = 7;
            this.activeuser = 7;
            this.stepthnine.ninethusertitle = { value: 1, label: 'Mr.' };
            this.stepthnine.ninethuserfullname = '';
            this.stepthnine.ninethusermobileno = '';
            this.stepthnine.ninethuserdesignation = '';
            this.stepthnine.ninethuserbranches = '';
            this.stepthnine.ninethuserrole = '';
            this.nineUserEditFkValue = '';
        },
        removeTenthRow() {
            this.userTabActiveFlag = 8;
            this.activeuser = 8;
            this.stepthten.tenthusertitle = { value: 1, label: 'Mr.' };
            this.stepthten.tenthuserfullname = '';
            this.stepthten.tenthusermobileno = '';
            this.stepthten.tenthuserdesignation = '';
            this.stepthten.tenthuserbranches = '';
            this.stepthten.tenthuserrole = '';
            this.tenUserEditFkValue = '';
        },



        addMoreSecondRow() {
            this.submitted = true;
            this.v$.stepthone.$validate();
            let fields = {};
            if(this.oneUserEditFkValue){
                fields['ad1'] = this.oneUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            fields['ad4'] = this.stepthone.firstusertitle.value;
            fields['ad5'] = this.stepthone.firstusertitle.label;
            fields['ad9'] = this.stepthone.firstuserfullname;
            fields['ad15'] = this.stepthone.firstusermobileno;
            if (this.stepthone.firstuserdesignation) {
                fields['ad10'] = this.stepthone.firstuserdesignation.value;
                fields['ad11'] = this.stepthone.firstuserdesignation.label;
            }
            fields['ad24'] = this.stepthone.firstuserbranches.value;
            fields['ad25'] = this.stepthone.firstuserbranches.label;
            fields['ad26'] = this.stepthone.firstuserrole.value;
            fields['ad27'] = this.stepthone.firstuserrole.label;
            if (!this.v$.stepthone.$error) {
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.oneUserEditFkValue = items.data.ad1;
                        this.secondrowshowstatus = true;
                        this.secondrowbtnstatus = false;
                        this.thirdrowbtnstatus = true;
                        this.seconduserrequire = true;
                        this.disabledflagoneuser = true;
                        this.submitted = false;
                        this.userTabActiveFlag = 1;
                        this.activeuser = 1;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreSecondRowSubmit() {
            this.submitted = true;
            this.v$.stepthone.$validate();
            let fields = {};
            if(this.oneUserEditFkValue){
                fields['ad1'] = this.oneUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            fields['ad4'] = this.stepthone.firstusertitle.value;
            fields['ad5'] = this.stepthone.firstusertitle.label;
            fields['ad9'] = this.stepthone.firstuserfullname;
            fields['ad15'] = this.stepthone.firstusermobileno;
            if (this.stepthone.firstuserdesignation) {
                fields['ad10'] = this.stepthone.firstuserdesignation.value;
                fields['ad11'] = this.stepthone.firstuserdesignation.label;
            }
            fields['ad24'] = this.stepthone.firstuserbranches.value;
            fields['ad25'] = this.stepthone.firstuserbranches.label;
            fields['ad26'] = this.stepthone.firstuserrole.value;
            fields['ad27'] = this.stepthone.firstuserrole.label;
            if (!this.v$.stepthone.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.active = 3;
                        this.showthirdstepLoader = false;
                        this.submitted = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        addMoreThirdRow() {
            this.submitted = true;
            this.v$.stepthtwo.$validate();
            let fields = {};
            if(this.twoUserEditFkValue){
                fields['ad1'] = this.twoUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthtwo.secondusertitle) {
                fields['ad4'] = this.stepthtwo.secondusertitle.value;
                fields['ad5'] = this.stepthtwo.secondusertitle.label;
            }
            if (this.stepthtwo.seconduserfullname) {
                fields['ad9'] = this.stepthtwo.seconduserfullname;
            }
            if (this.stepthtwo.secondusermobileno) {
                fields['ad15'] = this.stepthtwo.secondusermobileno;
            }
            if (this.stepthtwo.seconduserdesignation) {
                fields['ad10'] = this.stepthtwo.seconduserdesignation.value;
                fields['ad11'] = this.stepthtwo.seconduserdesignation.label;
            }
            if (this.stepthtwo.seconduserbranches) {
                fields['ad24'] = this.stepthtwo.seconduserbranches.value;
                fields['ad25'] = this.stepthtwo.seconduserbranches.label;
            }
            if (this.stepthtwo.seconduserrole) {
                fields['ad26'] = this.stepthtwo.seconduserrole.value;
                fields['ad27'] = this.stepthtwo.seconduserrole.label;
            }
            if (!this.v$.stepthtwo.$error) {
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.twoUserEditFkValue = items.data.ad1;
                        this.thirdrowshowstatus = true;
                        this.thirdrowbtnstatus = false;
                        this.fourthrowbtnstatus = true;
                        this.thirduserrequire = true;
                        this.disabledflagtwouser = true;
                        this.submitted = false;
                        this.userTabActiveFlag = 2;
                        this.activeuser = 2;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }

        },
        addMoreThirdRowSubmit() {
            this.submitted = true;
            this.v$.stepthtwo.$validate();
            let fields = {};
            if(this.twoUserEditFkValue){
                fields['ad1'] = this.twoUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthtwo.secondusertitle) {
                fields['ad4'] = this.stepthtwo.secondusertitle.value;
                fields['ad5'] = this.stepthtwo.secondusertitle.label;
            }
            if (this.stepthtwo.seconduserfullname) {
                fields['ad9'] = this.stepthtwo.seconduserfullname;
            }
            if (this.stepthtwo.secondusermobileno) {
                fields['ad15'] = this.stepthtwo.secondusermobileno;
            }
            if (this.stepthtwo.seconduserdesignation) {
                fields['ad10'] = this.stepthtwo.seconduserdesignation.value;
                fields['ad11'] = this.stepthtwo.seconduserdesignation.label;
            }
            if (this.stepthtwo.seconduserbranches) {
                fields['ad24'] = this.stepthtwo.seconduserbranches.value;
                fields['ad25'] = this.stepthtwo.seconduserbranches.label;
            }
            if (this.stepthtwo.seconduserrole) {
                fields['ad26'] = this.stepthtwo.seconduserrole.value;
                fields['ad27'] = this.stepthtwo.seconduserrole.label;
            }
            if (!this.v$.stepthtwo.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.showthirdstepLoader = false;
                        this.active = 3;
                        this.submitted = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreFourthRow() {
            this.submitted = true;
            this.v$.stepththree.$validate();
            let fields = {};
            if(this.threeUserEditFkValue){
                fields['ad1'] = this.threeUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepththree.thirdusertitle) {
                fields['ad4'] = this.stepththree.thirdusertitle.value;
                fields['ad5'] = this.stepththree.thirdusertitle.label;
            }
            if (this.stepththree.thirduserfullname) {
                fields['ad9'] = this.stepththree.thirduserfullname;
            }
            if (this.stepththree.thirdusermobileno) {
                fields['ad15'] = this.stepththree.thirdusermobileno;
            }
            if (this.stepththree.thirduserdesignation) {
                fields['ad10'] = this.stepththree.thirduserdesignation.value;
                fields['ad11'] = this.stepththree.thirduserdesignation.label;
            }
            if (this.stepththree.thirduserbranches) {
                fields['ad24'] = this.stepththree.thirduserbranches.value;
                fields['ad25'] = this.stepththree.thirduserbranches.label;
            }
            if (this.stepththree.thirduserrole) {
                fields['ad26'] = this.stepththree.thirduserrole.value;
                fields['ad27'] = this.stepththree.thirduserrole.label;
            }
            if (!this.v$.stepththree.$error) {
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.threeUserEditFkValue = items.data.ad1;
                        this.fourthrowshowstatus = true;
                        this.fourthrowbtnstatus = false;
                        this.fifthrowbtnstatus = true;
                        this.fourthuserrequire = true;
                        this.disabledflagthreeuser = true;
                        this.submitted = false;
                        this.activeuser = 3;
                        this.userTabActiveFlag = 3;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }

        },
        addMoreFourRowSubmit() {
            this.submitted = true;
            this.v$.stepththree.$validate();
            let fields = {};
            if(this.threeUserEditFkValue){
                fields['ad1'] = this.threeUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepththree.thirdusertitle) {
                fields['ad4'] = this.stepththree.thirdusertitle.value;
                fields['ad5'] = this.stepththree.thirdusertitle.label;
            }
            if (this.stepththree.thirduserfullname) {
                fields['ad9'] = this.stepththree.thirduserfullname;
            }
            if (this.stepththree.thirdusermobileno) {
                fields['ad15'] = this.stepththree.thirdusermobileno;
            }
            if (this.stepththree.thirduserdesignation) {
                fields['ad10'] = this.stepththree.thirduserdesignation.value;
                fields['ad11'] = this.stepththree.thirduserdesignation.label;
            }
            if (this.stepththree.thirduserbranches) {
                fields['ad24'] = this.stepththree.thirduserbranches.value;
                fields['ad25'] = this.stepththree.thirduserbranches.label;
            }
            if (this.stepththree.thirduserrole) {
                fields['ad26'] = this.stepththree.thirduserrole.value;
                fields['ad27'] = this.stepththree.thirduserrole.label;
            }
            if (!this.v$.stepththree.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.active = 3;
                        this.disabledflagthreeuser = true;
                        this.showthirdstepLoader = false;
                        this.submitted = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        addMoreFifthRow() {
            this.submitted = true;
            this.v$.stepthfour.$validate();
            let fields = {};
            if(this.fourUserEditFkValue){
                fields['ad1'] = this.fourUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthfour.fourthusertitle) {
                fields['ad4'] = this.stepthfour.fourthusertitle.value;
                fields['ad5'] = this.stepthfour.fourthusertitle.label;
            }
            if (this.stepthfour.fourthuserfullname) {
                fields['ad9'] = this.stepthfour.fourthuserfullname;
            }
            if (this.stepthfour.fourthusermobileno) {
                fields['ad15'] = this.stepthfour.fourthusermobileno;
            }
            if (this.stepthfour.fourthuserdesignation) {
                fields['ad10'] = this.stepthfour.fourthuserdesignation.value;
                fields['ad11'] = this.stepthfour.fourthuserdesignation.label;
            }
            if (this.stepthfour.fourthuserbranches) {
                fields['ad24'] = this.stepthfour.fourthuserbranches.value;
                fields['ad25'] = this.stepthfour.fourthuserbranches.label;
            }
            if (this.stepthfour.fourthuserrole) {
                fields['ad26'] = this.stepthfour.fourthuserrole.value;
                fields['ad27'] = this.stepthfour.fourthuserrole.label;
            }
            if (!this.v$.stepthfour.$error) {
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.fourUserEditFkValue = items.data.ad1;
                        this.fifthrowshowstatus = true;
                        this.fifthrowbtnstatus = false;
                        this.fifthuserrequire = true;
                        this.disabledflagfouruser = true;
                        this.submitted = false;
                        this.activeuser = 4;
                        this.userTabActiveFlag = 4;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreFifthRowSubmit() {
            this.submitted = true;
            this.v$.stepthfour.$validate();
            let fields = {};
            if(this.fourUserEditFkValue){
                fields['ad1'] = this.fourUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthfour.fourthusertitle) {
                fields['ad4'] = this.stepthfour.fourthusertitle.value;
                fields['ad5'] = this.stepthfour.fourthusertitle.label;
            }
            if (this.stepthfour.fourthuserfullname) {
                fields['ad9'] = this.stepthfour.fourthuserfullname;
            }
            if (this.stepthfour.fourthusermobileno) {
                fields['ad15'] = this.stepthfour.fourthusermobileno;
            }
            if (this.stepthfour.fourthuserdesignation) {
                fields['ad10'] = this.stepthfour.fourthuserdesignation.value;
                fields['ad11'] = this.stepthfour.fourthuserdesignation.label;
            }
            if (this.stepthfour.fourthuserbranches) {
                fields['ad24'] = this.stepthfour.fourthuserbranches.value;
                fields['ad25'] = this.stepthfour.fourthuserbranches.label;
            }
            if (this.stepthfour.fourthuserrole) {
                fields['ad26'] = this.stepthfour.fourthuserrole.value;
                fields['ad27'] = this.stepthfour.fourthuserrole.label;
            }
            if (!this.v$.stepthfour.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.fifthrowshowstatus = true;
                        this.active = 3;
                        this.disabledflagfouruser = true;
                        this.showthirdstepLoader = false;
                        this.submitted = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }

        },
        addMoreSixRow() {
            this.submitted = true;
            this.v$.stepthfive.$validate();
            let fields = {};
            if(this.fiveUserEditFkValue){
                fields['ad1'] = this.fiveUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthfive.fifthusertitle) {
                fields['ad4'] = this.stepthfive.fifthusertitle.value;
                fields['ad5'] = this.stepthfive.fifthusertitle.label;
            }
            if (this.stepthfive.fifthuserfullname) {
                fields['ad9'] = this.stepthfive.fifthuserfullname;
            }
            if (this.stepthfive.fifthusermobileno) {
                fields['ad15'] = this.stepthfive.fifthusermobileno;
            }
            if (this.stepthfive.fifthuserdesignation) {
                fields['ad10'] = this.stepthfive.fifthuserdesignation.value;
                fields['ad11'] = this.stepthfive.fifthuserdesignation.label;
            }
            if (this.stepthfive.fifthuserbranches) {
                fields['ad24'] = this.stepthfive.fifthuserbranches.value;
                fields['ad25'] = this.stepthfive.fifthuserbranches.label;
            }
            if (this.stepthfive.fifthuserrole) {
                fields['ad26'] = this.stepthfive.fifthuserrole.value;
                fields['ad27'] = this.stepthfive.fifthuserrole.label;
            }
            if (!this.v$.stepthfive.$error) {
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.fiveUserEditFkValue = items.data.ad1;
                        this.sixthuserrequire = true;
                        this.submitted = false;
                        this.activeuser = 5;
                        this.userTabActiveFlag = 5;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreSixRowSubmit() {
            this.submitted = true;
            this.v$.stepthfive.$validate();
            let fields = {};
            if(this.fiveUserEditFkValue){
                fields['ad1'] = this.fiveUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthfive.fifthusertitle) {
                fields['ad4'] = this.stepthfive.fifthusertitle.value;
                fields['ad5'] = this.stepthfive.fifthusertitle.label;
            }
            if (this.stepthfive.fifthuserfullname) {
                fields['ad9'] = this.stepthfive.fifthuserfullname;
            }
            if (this.stepthfive.fifthusermobileno) {
                fields['ad15'] = this.stepthfive.fifthusermobileno;
            }
            if (this.stepthfive.fifthuserdesignation) {
                fields['ad10'] = this.stepthfive.fifthuserdesignation.value;
                fields['ad11'] = this.stepthfive.fifthuserdesignation.label;
            }
            if (this.stepthfive.fifthuserbranches) {
                fields['ad24'] = this.stepthfive.fifthuserbranches.value;
                fields['ad25'] = this.stepthfive.fifthuserbranches.label;
            }
            if (this.stepthfive.fifthuserrole) {
                fields['ad26'] = this.stepthfive.fifthuserrole.value;
                fields['ad27'] = this.stepthfive.fifthuserrole.label;
            }
            if (!this.v$.stepthfive.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.showthirdstepLoader = false;
                        this.submitted = false;
                        this.active = 3;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreSevenRow() {
            this.submitted = true;
            this.v$.stepthsix.$validate();
            let fields = {};
            if(this.sixUserEditFkValue){
                fields['ad1'] = this.sixUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthsix.sixthusertitle) {
                fields['ad4'] = this.stepthsix.sixthusertitle.value;
                fields['ad5'] = this.stepthsix.sixthusertitle.label;
            }
            if (this.stepthsix.sixthuserfullname) {
                fields['ad9'] = this.stepthsix.sixthuserfullname;
            }
            if (this.stepthsix.sixthusermobileno) {
                fields['ad15'] = this.stepthsix.sixthusermobileno;
            }
            if (this.stepthsix.sixthuserdesignation) {
                fields['ad10'] = this.stepthsix.sixthuserdesignation.value;
                fields['ad11'] = this.stepthsix.sixthuserdesignation.label;
            }
            if (this.stepthsix.sixthuserbranches) {
                fields['ad24'] = this.stepthsix.sixthuserbranches.value;
                fields['ad25'] = this.stepthsix.sixthuserbranches.label;
            }
            if (this.stepthsix.sixthuserrole) {
                fields['ad26'] = this.stepthsix.sixthuserrole.value;
                fields['ad27'] = this.stepthsix.sixthuserrole.label;
            }
            if (!this.v$.stepthsix.$error) {
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.sixUserEditFkValue = items.data.ad1;
                        this.seventhuserrequire = true;
                        this.submitted = false;
                        this.activeuser = 6;
                        this.userTabActiveFlag = 6;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreSevenRowSubmit() {
            this.submitted = true;
            this.v$.stepthsix.$validate();
            let fields = {};
            if(this.sixUserEditFkValue){
                fields['ad1'] = this.sixUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthsix.sixthusertitle) {
                fields['ad4'] = this.stepthsix.sixthusertitle.value;
                fields['ad5'] = this.stepthsix.sixthusertitle.label;
            }
            if (this.stepthsix.sixthuserfullname) {
                fields['ad9'] = this.stepthsix.sixthuserfullname;
            }
            if (this.stepthsix.sixthusermobileno) {
                fields['ad15'] = this.stepthsix.sixthusermobileno;
            }
            if (this.stepthsix.sixthuserdesignation) {
                fields['ad10'] = this.stepthsix.sixthuserdesignation.value;
                fields['ad11'] = this.stepthsix.sixthuserdesignation.label;
            }
            if (this.stepthsix.sixthuserbranches) {
                fields['ad24'] = this.stepthsix.sixthuserbranches.value;
                fields['ad25'] = this.stepthsix.sixthuserbranches.label;
            }
            if (this.stepthsix.sixthuserrole) {
                fields['ad26'] = this.stepthsix.sixthuserrole.value;
                fields['ad27'] = this.stepthsix.sixthuserrole.label;
            }
            if (!this.v$.stepthsix.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.showthirdstepLoader = false;
                        this.submitted = false;
                        this.active = 3;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreEightRow() {
            this.submitted = true;
            this.v$.stepthseven.$validate();
            let fields = {};
            if(this.sevenUserEditFkValue){
                fields['ad1'] = this.sevenUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthseven.seventhusertitle) {
                fields['ad4'] = this.stepthseven.seventhusertitle.value;
                fields['ad5'] = this.stepthseven.seventhusertitle.label;
            }
            if (this.stepthseven.seventhuserfullname) {
                fields['ad9'] = this.stepthseven.seventhuserfullname;
            }
            if (this.stepthseven.seventhusermobileno) {
                fields['ad15'] = this.stepthseven.seventhusermobileno;
            }
            if (this.stepthseven.seventhuserdesignation) {
                fields['ad10'] = this.stepthseven.seventhuserdesignation.value;
                fields['ad11'] = this.stepthseven.seventhuserdesignation.label;
            }
            if (this.stepthseven.seventhuserbranches) {
                fields['ad24'] = this.stepthseven.seventhuserbranches.value;
                fields['ad25'] = this.stepthseven.seventhuserbranches.label;
            }
            if (this.stepthseven.seventhuserrole) {
                fields['ad26'] = this.stepthseven.seventhuserrole.value;
                fields['ad27'] = this.stepthseven.seventhuserrole.label;
            }
            if (!this.v$.stepthseven.$error) {
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.sevenUserEditFkValue = items.data.ad1;
                        this.eighthuserrequire = true;
                        this.submitted = false;
                        this.activeuser = 7;
                        this.userTabActiveFlag = 7;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreEightRowSubmit() {
            this.submitted = true;
            this.v$.stepthseven.$validate();
            let fields = {};
            if(this.sevenUserEditFkValue){
                fields['ad1'] = this.sevenUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthseven.seventhusertitle) {
                fields['ad4'] = this.stepthseven.seventhusertitle.value;
                fields['ad5'] = this.stepthseven.seventhusertitle.label;
            }
            if (this.stepthseven.seventhuserfullname) {
                fields['ad9'] = this.stepthseven.seventhuserfullname;
            }
            if (this.stepthseven.seventhusermobileno) {
                fields['ad15'] = this.stepthseven.seventhusermobileno;
            }
            if (this.stepthseven.seventhuserdesignation) {
                fields['ad10'] = this.stepthseven.seventhuserdesignation.value;
                fields['ad11'] = this.stepthseven.seventhuserdesignation.label;
            }
            if (this.stepthseven.seventhuserbranches) {
                fields['ad24'] = this.stepthseven.seventhuserbranches.value;
                fields['ad25'] = this.stepthseven.seventhuserbranches.label;
            }
            if (this.stepthseven.seventhuserrole) {
                fields['ad26'] = this.stepthseven.seventhuserrole.value;
                fields['ad27'] = this.stepthseven.seventhuserrole.label;
            }
            if (!this.v$.stepthseven.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.showthirdstepLoader = false;
                        this.submitted = false;
                        this.active = 3;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreNineRow() {
            this.submitted = true;
            this.v$.steptheight.$validate();
            let fields = {};
            if(this.eightUserEditFkValue){
                fields['ad1'] = this.eightUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.steptheight.eighthusertitle) {
                fields['ad4'] = this.steptheight.eighthusertitle.value;
                fields['ad5'] = this.steptheight.eighthusertitle.label;
            }
            if (this.steptheight.eighthuserfullname) {
                fields['ad9'] = this.steptheight.eighthuserfullname;
            }
            if (this.steptheight.eighthusermobileno) {
                fields['ad15'] = this.steptheight.eighthusermobileno;
            }
            if (this.steptheight.eighthuserdesignation) {
                fields['ad10'] = this.steptheight.eighthuserdesignation.value;
                fields['ad11'] = this.steptheight.eighthuserdesignation.label;
            }
            if (this.steptheight.eighthuserbranches) {
                fields['ad24'] = this.steptheight.eighthuserbranches.value;
                fields['ad25'] = this.steptheight.eighthuserbranches.label;
            }
            if (this.steptheight.eighthuserrole) {
                fields['ad26'] = this.steptheight.eighthuserrole.value;
                fields['ad27'] = this.steptheight.eighthuserrole.label;
            }
            if (!this.v$.steptheight.$error) {
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.eightUserEditFkValue = items.data.ad1;
                        this.eighthuserrequire = true;
                        this.submitted = false;
                        this.activeuser = 9;
                        this.userTabActiveFlag = 9;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreNineRowSubmit() {
            this.submitted = true;
            this.v$.steptheight.$validate();
            let fields = {};
            if(this.eightUserEditFkValue){
                fields['ad1'] = this.eightUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.steptheight.eighthusertitle) {
                fields['ad4'] = this.steptheight.eighthusertitle.value;
                fields['ad5'] = this.steptheight.eighthusertitle.label;
            }
            if (this.steptheight.eighthuserfullname) {
                fields['ad9'] = this.steptheight.eighthuserfullname;
            }
            if (this.steptheight.eighthusermobileno) {
                fields['ad15'] = this.steptheight.eighthusermobileno;
            }
            if (this.steptheight.eighthuserdesignation) {
                fields['ad10'] = this.steptheight.eighthuserdesignation.value;
                fields['ad11'] = this.steptheight.eighthuserdesignation.label;
            }
            if (this.steptheight.eighthuserbranches) {
                fields['ad24'] = this.steptheight.eighthuserbranches.value;
                fields['ad25'] = this.steptheight.eighthuserbranches.label;
            }
            if (this.steptheight.eighthuserrole) {
                fields['ad26'] = this.steptheight.eighthuserrole.value;
                fields['ad27'] = this.steptheight.eighthuserrole.label;
            }
            if (!this.v$.steptheight.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.showthirdstepLoader = false;
                        this.submitted = false;
                        this.active = 3;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreTenRow() {
            this.submitted = true;
            this.v$.stepthnine.$validate();
            let fields = {};
            if(this.nineUserEditFkValue){
                fields['ad1'] = this.nineUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthnine.ninethusertitle) {
                fields['ad4'] = this.stepthnine.ninethusertitle.value;
                fields['ad5'] = this.stepthnine.ninethusertitle.label;
            }
            if (this.stepthnine.ninethuserfullname) {
                fields['ad9'] = this.stepthnine.ninethuserfullname;
            }
            if (this.stepthnine.ninethusermobileno) {
                fields['ad15'] = this.stepthnine.ninethusermobileno;
            }
            if (this.stepthnine.ninethuserdesignation) {
                fields['ad10'] = this.stepthnine.ninethuserdesignation.value;
                fields['ad11'] = this.stepthnine.ninethuserdesignation.label;
            }
            if (this.stepthnine.ninethuserbranches) {
                fields['ad24'] = this.stepthnine.ninethuserbranches.value;
                fields['ad25'] = this.stepthnine.ninethuserbranches.label;
            }
            if (this.stepthnine.ninethuserrole) {
                fields['ad26'] = this.stepthnine.ninethuserrole.value;
                fields['ad27'] = this.stepthnine.ninethuserrole.label;
            }
            if (!this.v$.stepthnine.$error) {
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.nineUserEditFkValue = items.data.ad1;
                        this.ninethuserrequire = true;
                        this.submitted = false;
                        this.activeuser = 9;
                        this.userTabActiveFlag = 9;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreTenRowSubmit() {
            this.submitted = true;
            this.v$.stepthnine.$validate();
            let fields = {};
            if(this.nineUserEditFkValue){
                fields['ad1'] = this.nineUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthnine.ninethusertitle) {
                fields['ad4'] = this.stepthnine.ninethusertitle.value;
                fields['ad5'] = this.stepthnine.ninethusertitle.label;
            }
            if (this.stepthnine.ninethuserfullname) {
                fields['ad9'] = this.stepthnine.ninethuserfullname;
            }
            if (this.stepthnine.ninethusermobileno) {
                fields['ad15'] = this.stepthnine.ninethusermobileno;
            }
            if (this.stepthnine.ninethuserdesignation) {
                fields['ad10'] = this.stepthnine.ninethuserdesignation.value;
                fields['ad11'] = this.stepthnine.ninethuserdesignation.label;
            }
            if (this.stepthnine.ninethuserbranches) {
                fields['ad24'] = this.stepthnine.ninethuserbranches.value;
                fields['ad25'] = this.stepthnine.ninethuserbranches.label;
            }
            if (this.stepthnine.ninethuserrole) {
                fields['ad26'] = this.stepthnine.ninethuserrole.value;
                fields['ad27'] = this.stepthnine.ninethuserrole.label;
            }
            if (!this.v$.stepthnine.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.showthirdstepLoader = false;
                        this.submitted = false;
                        this.active = 3;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addMoreElevenRowSubmit() {
            this.submitted = true;
            this.v$.stepthten.$validate();
            let fields = {};
            if(this.tenUserEditFkValue){
                fields['ad1'] = this.tenUserEditFkValue;
            }
            fields['ad2'] = this.hostId;
            fields['ad3'] = this.stepf.entitytype.value;
            if (this.stepthten.tenthusertitle) {
                fields['ad4'] = this.stepthten.tenthusertitle.value;
                fields['ad5'] = this.stepthten.tenthusertitle.label;
            }
            if (this.stepthten.tenthuserfullname) {
                fields['ad9'] = this.stepthten.tenthuserfullname;
            }
            if (this.stepthten.tenthusermobileno) {
                fields['ad15'] = this.stepthten.tenthusermobileno;
            }
            if (this.stepthten.tenthuserdesignation) {
                fields['ad10'] = this.stepthten.tenthuserdesignation.value;
                fields['ad11'] = this.stepthten.tenthuserdesignation.label;
            }
            if (this.stepthten.tenthuserbranches) {
                fields['ad24'] = this.stepthten.tenthuserbranches.value;
                fields['ad25'] = this.stepthten.tenthuserbranches.label;
            }
            if (this.stepthten.tenthuserrole) {
                fields['ad26'] = this.stepthten.tenthuserrole.value;
                fields['ad27'] = this.stepthten.tenthuserrole.label;
            }
            if (!this.v$.stepthten.$error) {
                this.showthirdstepLoader = true;
                this.ApiService.addHostUser(fields).then(items => {
                    if (items.status === 200) {
                        this.tenUserEditFkValue = items.data.ad1;
                        this.showthirdstepLoader = false;
                        this.submitted = false;
                        this.active = 3;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.errormsg = '';
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                    } else {
                        this.showthirdstepLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        getAgeEvents(e) {
            this.eventService.getEvents(e).then((data) => {
                this.branchCountList = data;
            });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileFirstStep($event) {
            this.mobileExistsErrorMsg = '';
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("mobilenoowner");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileFirstUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var firstusermobileno = document.getElementById("firstusermobileno");
            if ((firstusermobileno.value == '' || firstusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileSecondUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var secondusermobileno = document.getElementById("secondusermobileno");
            if ((secondusermobileno.value == '' || secondusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileThirdUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var thirdusermobileno = document.getElementById("thirdusermobileno");
            if ((thirdusermobileno.value == '' || thirdusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileFourthUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var fourthusermobileno = document.getElementById("fourthusermobileno");
            if ((fourthusermobileno.value == '' || fourthusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileFifthUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var fifthusermobileno = document.getElementById("fifthusermobileno");
            if ((fifthusermobileno.value == '' || fifthusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileSixthUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var sixthusermobileno = document.getElementById("sixthusermobileno");
            if ((sixthusermobileno.value == '' || sixthusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileSeventhUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var seventhusermobileno = document.getElementById("seventhusermobileno");
            if ((seventhusermobileno.value == '' || seventhusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileEighthUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var eighthusermobileno = document.getElementById("eighthusermobileno");
            if ((eighthusermobileno.value == '' || eighthusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileNinethUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var ninethusermobileno = document.getElementById("ninethusermobileno");
            if ((ninethusermobileno.value == '' || ninethusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        onlyNumberMobileTenthUser($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var tenthusermobileno = document.getElementById("tenthusermobileno");
            if ((tenthusermobileno.value == '' || tenthusermobileno.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },


        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        pincodeFormatFirstStep($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepfpincode = document.getElementById("stepfpincode");
            if ((stepfpincode.value == '' || stepfpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepOne($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepseoneonepincode = document.getElementById("stepseoneonepincode");
            if ((stepseoneonepincode.value == '' || stepseoneonepincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepTwo($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsetwotwopincode = document.getElementById("stepsetwotwopincode");
            if ((stepsetwotwopincode.value == '' || stepsetwotwopincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepThree($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsethreethreepincode = document.getElementById("stepsethreethreepincode");
            if ((stepsethreethreepincode.value == '' || stepsethreethreepincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepFour($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsefourfourpincode = document.getElementById("stepsefourfourpincode");
            if ((stepsefourfourpincode.value == '' || stepsefourfourpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepFive($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsefivefivepincode = document.getElementById("stepsefivefivepincode");
            if ((stepsefivefivepincode.value == '' || stepsefivefivepincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepSix($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsesixsixpincode = document.getElementById("stepsesixsixpincode");
            if ((stepsesixsixpincode.value == '' || stepsesixsixpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepSeven($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsesevensevenpincode = document.getElementById("stepsesevensevenpincode");
            if ((stepsesevensevenpincode.value == '' || stepsesevensevenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepEight($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepseeighteightpincode = document.getElementById("stepseeighteightpincode");
            if ((stepseeighteightpincode.value == '' || stepseeighteightpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepNine($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsenineninepincode = document.getElementById("stepsenineninepincode");
            if ((stepsenineninepincode.value == '' || stepsenineninepincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepTen($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsetentenpincode = document.getElementById("stepsetentenpincode");
            if ((stepsetentenpincode.value == '' || stepsetentenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepEleven($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepseelevenelevenpincode = document.getElementById("stepseelevenelevenpincode");
            if ((stepseelevenelevenpincode.value == '' || stepseelevenelevenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepTwelve($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsetwelvetwelvepincode = document.getElementById("stepsetwelvetwelvepincode");
            if ((stepsetwelvetwelvepincode.value == '' || stepsetwelvetwelvepincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepThirteen($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsethirteenthirteenpincode = document.getElementById("stepsethirteenthirteenpincode");
            if ((stepsethirteenthirteenpincode.value == '' || stepsethirteenthirteenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepFourteen($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsefourteenfourteenpincode = document.getElementById("stepsefourteenfourteenpincode");
            if ((stepsefourteenfourteenpincode.value == '' || stepsefourteenfourteenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepFifteen($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsefifteenfifteenpincode = document.getElementById("stepsefifteenfifteenpincode");
            if ((stepsefifteenfifteenpincode.value == '' || stepsefifteenfifteenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepSixteen($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsesixteensixteenpincode = document.getElementById("stepsesixteensixteenpincode");
            if ((stepsesixteensixteenpincode.value == '' || stepsesixteensixteenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepSeventeen($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepseseventeenseventeenpincode = document.getElementById("stepseseventeenseventeenpincode");
            if ((stepseseventeenseventeenpincode.value == '' || stepseseventeenseventeenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepEighteen($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepseeighteeneighteenpincode = document.getElementById("stepseeighteeneighteenpincode");
            if ((stepseeighteeneighteenpincode.value == '' || stepseeighteeneighteenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepNineteen($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsenineteennineteenpincode = document.getElementById("stepsenineteennineteenpincode");
            if ((stepsenineteennineteenpincode.value == '' || stepsenineteennineteenpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepTwenty($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsetwentytwentypincode = document.getElementById("stepsetwentytwentypincode");
            if ((stepsetwentytwentypincode.value == '' || stepsetwentytwentypincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepTwentyone($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsetwentyonetwentyonepincode = document.getElementById("stepsetwentyonetwentyonepincode");
            if ((stepsetwentyonetwentyonepincode.value == '' || stepsetwentyonetwentyonepincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepTwentytwo($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsetwentytwotwentytwopincode = document.getElementById("stepsetwentytwotwentytwopincode");
            if ((stepsetwentytwotwentytwopincode.value == '' || stepsetwentytwotwentytwopincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepTwentythree($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsetwentythreetwentythreepincode = document.getElementById("stepsetwentythreetwentythreepincode");
            if ((stepsetwentythreetwentythreepincode.value == '' || stepsetwentythreetwentythreepincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepTwentyfour($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsetwentyfourtwentyfourpincode = document.getElementById("stepsetwentyfourtwentyfourpincode");
            if ((stepsetwentyfourtwentyfourpincode.value == '' || stepsetwentyfourtwentyfourpincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        pincodeFormatSecondStepTwentyfive($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var stepsetwentyfivetwentyfivepincode = document.getElementById("stepsetwentyfivetwentyfivepincode");
            if ((stepsetwentyfivetwentyfivepincode.value == '' || stepsetwentyfivetwentyfivepincode.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        handlePanNumber(e) {
            var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
            let pan_number = e.target.value.toUpperCase();
            if (pan_number && !pan_number.match(regex)) {
                this.errmsg['pannumber'] = 'Please enter valid pan number';
            } else {
                this.errmsg['pannumber'] = '';
            }
        },
        handleGSTNumber(e) {
            var regexGst = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
            let gst_number = e.target.value.toUpperCase();
            if (gst_number && !gst_number.match(regexGst)) {
                this.errmsg['gstnumber'] = 'Please enter valid GST number';
            } else {
                this.errmsg['gstnumber'] = '';
            }
            // this.stepf.pannumber = gst_number.substr(2, 10);
        },
        redirectToLogin() {
            console.log("Redirect To Login Page");
        },
        getGstDetails() {
            if(!this.errmsg.gstnumber) {
                let fields = {};
            fields['ac1'] = this.hostId;
            fields['ac36'] = this.stepf.isgstnumberflag;
            fields["ac4"] = this.stepf.gstnumber;
            if (!this.errmsg.gstnumber) {
                this.showgstdataLoader = true;
                this.ApiService.updateCompanyInfo(fields).then(items => {
                    if (items.success === true) {
                        this.hidegstsubmitbtn = true;
                        this.gstcompanydetails = items.data;
                        this.showgstdataLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.stepf.isgstnumberflag = 2;
                        this.hidegstsubmitbtn = false;
                        this.showgstdataLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Failed', detail: errorMsg, life: 3000 });
                    }
                });
            }
            }
        },
    },
}
</script>
<style scoped>.comman-overflow-scroll-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 525px; */
    padding-right: 1px;
}

.comman-overflow-scroll-outer::-webkit-scrollbar {
    display: block;
}

.comman-overflow-scroll-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.comman-overflow-scroll-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.resendotpclass {
    font-size: smaller;
    padding: 2px;
}
.resendotpclass:hover {
   
}

.header-label {
    color: #607D8B;
}

.custom-border-radius {
    border-radius: 2rem 0rem 0rem 2rem;
}

.scroll-branch-tab {
    overflow-y: auto;
    overflow-x: hidden;
    height: 370px;
    display: grid;
}

.scroll-branch-tab::-webkit-scrollbar {
    display: block;
}

.scroll-branch-tab::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.scroll-branch-tab::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.thank-you-label {
    font-size: 1.5rem;
    font-weight: 700;
    color: #607D8B;
    margin-bottom: 10px;
}

.thank-you-second-label {
    font-size: 1rem;
    color: #607D8B;
    font-family: 'Roboto-Medium';
    margin-bottom: 5px;
}
.thank-you-third-label {
    font-size: 14px;
    color: #607D8B;
    font-family: 'Roboto-Medium';
    margin-bottom: 5px;
}
.thank-you-fourth-label {
    font-size: 14px;
    color: #607D8B;
    font-family: 'Roboto-Regular';
    margin-bottom: 5px;
}

.mobile-app-link-btn {
    display: inline-block;
    border: 1px solid #fff;
}

.mobile-app-link-btn:hover {
    border: 1px solid #607D8B;
    border-radius: 5px;
}

.copyright-label {
    font-family: 'WorkSans-Regular';
    color: #607D8B;
    font-size: 12px;
}

.copyright-linkbtn {
    color: #607D8B;
    font-size: 12px;
    font-family: 'Roboto-Medium';
    text-decoration: none;
}

.copyright-linkbtn:hover {
    color: #2f7da3;
    font-size: 12px;
    font-family: 'Roboto-Medium';
    text-decoration: none;
}

.guideline-label {
    font-size: 1rem;
    font-weight: 700;
    color: #607D8B;
}

.p-card-body:first {
    background-color: #f9f9f9;
}

.guideline-list {
    color: #607D8B;
    font-family: 'Roboto-Regular';
    letter-spacing: 0.2px;
}

.guideline-list li {
    margin-bottom: 10px;
}
.guideline-list-header {
    color: #607D8B;
    font-family: 'Roboto-Medium';
    letter-spacing: 0.2px;
}

.helptext {
    color: #607D8B;
    margin-top: 1px;
}

.custom-info-icon:hover {
    color: #607D8B;
}
.gst-detail-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}
.gst-detail-box-outer .form-gst-label {
    font-family: 'Roboto-Regular';
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.gst-detail-box-outer .from-gst-value {
    font-family: 'OpenSans-SemiBold';
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}
.voter-exit-box-outer {
    padding: 16px 16px 7px;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}
</style>
