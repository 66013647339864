<template>
  <div class="layout-main">
    <div class="layout-content desktop" v-if="!isMobile()">
      <router-view>
        <DesktopPage/>
      </router-view>
    </div>
    <div class="layout-content mob" v-else>
      <router-view>
        <MobilePage/>
      </router-view>
    </div>
  </div>
</template>
<script>
import DesktopPage from './pages/HomePage.vue';
import MobilePage from './pages/HomeMobile.vue';
export default {
  components: { DesktopPage, MobilePage },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    isMobile() {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
 }
  },
}
</script>