import { createRouter, createWebHistory } from 'vue-router';
const routes = [

  {
    path: '/',
    name: 'HomePage',
    exact: true,
    component: () =>
      import('./pages/HomePage.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notfound',
    component: () =>
        import('./pages/NotFound.vue'),
    meta: {
        plainLayout: true,
    },
},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router