<template>
    <div class="notfound-page">
        <div class="flex flex-wrap align-content-top justify-content-center p-py-3" :style="{ height: '100vh' }">
            <Card :style="{ width: '90%'}">
                <template #title>
                    <div class="p-d-flex p-jc-between p-flex-row" style="100%">
                        <div class="p-d-flex p-ai-center" style="min-width: 50px;">
                            <img alt="Goa Electronics" src="/assets/images/goa-electronics-logo.jpg" class="image-header-left"
                                width="150px" />
                        </div>
                        <div class="text-right" style="min-width: 50px;">
                            <img alt="Goa Police" src="/assets//images/police-logo.png" class="image-header-right"
                                width="60px" />
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="card">
                        <Card>
                            <template #content>
                                <div class="p-px-3">
                                        <div class="p-fluid p-formgrid p-grid" style="height: calc(100vh - 262px)">
                                            <div class="p-col-12 p-md-12 p-d-flex p-flex-column p-ai-center justify-content-center">
                                                    <div class="thank-you-label p-text-center">"For a seamless registration experience,</div>
                                                    <div class="thank-you-label p-text-center">Please switch to a Laptop or PC."</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                </Card>
                    </div>
                </template>
                <template #footer>
                    <div class="p-text-center">
                        <div class="copyright-label">It is designed and developed by</div>
                        <div><a href="#" target="_blank"
                                class="copyright-linkbtn">Samruddh Bharat Tech Private Limited</a></div>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>
<style scoped>
.comman-overflow-scroll-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 525px; */
    padding-right: 1px;
}

.comman-overflow-scroll-outer::-webkit-scrollbar {
    display: block;
}

.comman-overflow-scroll-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.comman-overflow-scroll-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.resendotpclass {
    font-size: smaller;
    padding: 2px;
}

.image-header-right {
    border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #607D8B;
}

.header-label {
    color: #607D8B;
}

.custom-border-radius {
    border-radius: 2rem 0rem 0rem 2rem;
}

.scroll-branch-tab {
    overflow-y: auto;
    overflow-x: hidden;
    height: 370px;
    display: grid;
}

.scroll-branch-tab::-webkit-scrollbar {
    display: block;
}

.scroll-branch-tab::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.scroll-branch-tab::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}
.thank-you-label {
    font-size: 1rem;
    font-weight: 700;
    color: #607D8B;
}
.copyright-label {
    font-family: 'WorkSans-Regular';
    color: #607D8B;
    font-size: 12px;
}

.copyright-linkbtn {
    color: #607D8B;
    font-size: 12px;
    font-family: 'Roboto-Medium';
    text-decoration: none;
}

.copyright-linkbtn:hover {
    color: #2f7da3;
    font-size: 12px;
    font-family: 'Roboto-Medium';
    text-decoration: none;
}
</style>
